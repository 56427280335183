import React, { useEffect } from 'react'
import { Card, Button } from 'react-bootstrap'
import { useState } from "react";
import axios from "axios";
import $ from 'jquery';
import { Modal } from 'react-bootstrap';
import Swal from 'sweetalert2';
import moment from "moment";
import { useDispatch } from "react-redux";
import { setLoader } from '../Store/Reducers/UserReducer';
import LoginFun from '../Layout Component/Loginfun';
import './Conditions.css';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';

var UserData;
export default function Conditions() {
    UserData = JSON.parse(localStorage.getItem("UData"));
    const dispatch = useDispatch();
    const url = process.env.REACT_APP_OXAR_API_URL;
    const baseUrl = "https://browser.ihtsdotools.org/snowstorm/snomed-ct";
    const edition = "MAIN";
    const version = "2019-07-31";
    const [conditions, setconditions] = useState([]);
    const [show, setShow] = useState(false);
    const [querydata, setquerydata] = useState([]);
    const [query, setQuery] = useState("")
    const [SnomedName, setSnomedName] = useState("");
    const [SnomedCode, setSnomedCode] = useState();
    const [verificationStatus, setverificationStatus] = useState("");
    const [clinicalStatus, setclinicalStatus] = useState("");
    const [queryName, setQueryName] = useState("");
    const logindata = LoginFun();
    const handleClose = () => setShow(false);

    const handleShow = () => {
        setSnomedCode("");
        setquerydata([]);
        setShow(true)
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        handleClose();
    }

    const getData = (value) => {
        if (value === "Select") {
            setSnomedCode("");
            setSnomedName("");
        }
        var a = querydata.find(x => x['ID'] === value)
        setSnomedCode(a.ID);
        setSnomedName(a.Name);
    }

    useEffect(() => {
        setTimeout(() => {
            getAllconditions();
        }, 1000);
        sessionCheck();
    }, []);

    const sessionCheck = async () => {
        var currentTime = ''
        currentTime = (Math.round(new Date().getTime() / 1000));
        var Expiretime = localStorage.getItem("ExpirretokenTime");
        if (Expiretime < currentTime) {
            sessionStorage.getItem('isLoggedIn', false)
            Swal.fire({
                title: 'Session Expired',
                text: "Your session has been expired! Please login again",
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: 'Okay',
            }).then(result => {
                logindata.logout();
            })
        }
    }

    const getAllconditions = async () => {
        try {
            $('#ConditionTable').DataTable().destroy();
            let MainUserId = localStorage.getItem("UserID");
            dispatch(setLoader(true));
            await axios(`${url}api/Condition/GetConditions?id=${MainUserId}`).then((res) => {
                setconditions(res.data.content);
                //  $("#treatmentCycleTable").DataTable();
                setTimeout(function () {
                    $("#ConditionTable").DataTable();
                }, 200);
            });
            dispatch(setLoader(false));
        } catch {
            dispatch(setLoader(false));
        }
    };
    // for finding query and getting condition to create condition
    const findByQuery = async () => {
        if (query === "") {
            return
        }
        let value = query;
        setQueryName(value)
        try {
            dispatch(setLoader(true));
            const response = await fetch(
                `${baseUrl}/${edition}/${version}/concepts?term=${encodeURIComponent(value)}&activeFilter=true&offset=0&limit=20`
            ).then((response) => response.json());
            var dataforsnomed = response["items"];
            var MainPath = [];
            dataforsnomed.forEach(element => {
                var data = {
                    ID: element.conceptId,
                    Name: element.pt.term
                }
                MainPath.push(data);
            });
            setquerydata(MainPath);
            dispatch(setLoader(false));
        } catch (ex) {
            dispatch(setLoader(false));
        }
    }

    //***********Create condition API on onsubmit*****************//

    const createCondition = async () => {
        let MainUserId = localStorage.getItem("UserID");
        if (SnomedName !== "") {
            let condition = {
                bcrm_name: SnomedName,
                codeText: SnomedName,
                bcrm_code: SnomedCode,
                bcrm_resourcetype: "271400000",
                bcrm_resourcetypeName: "Condition",
                bcrm_clinicalstatus: clinicalStatus,
                bcrm_verificationstatus: verificationStatus,
                bcrm_patient: MainUserId,
                query1: queryName,
            }
            try {
                dispatch(setLoader(true));
                await axios.post(`${url}api/Condition/Create`, condition).then((res) => {
                    if (res.status === 200) {
                        toast.success('Condition created successfully!', {
                            autoClose: 5000,
                            hideProgressBar: true,
                            newestOnTop: false,
                            closeOnClick: true,
                            rtl: false,
                            pauseOnFocusLoss: true,
                            draggable: true,
                            pauseOnHover: true,
                            className: 'toast-message'
                        })
                        getAllconditions();
                        setSnomedName("")
                        setSnomedCode("")
                        setclinicalStatus("")
                        setverificationStatus("")
                        setQueryName("")
                        setShow(false);
                    }
                });
                dispatch(setLoader(false));
            } catch {
                dispatch(setLoader(false));
            }
        } else {
            toast.warn('Please fill mandatory fields', {
                autoClose: 5000,
                hideProgressBar: true,
                newestOnTop: false,
                closeOnClick: true,
                rtl: false,
                pauseOnFocusLoss: true,
                draggable: true,
                pauseOnHover: true,
                className: 'toast-warn'
            })
            return dispatch(setLoader(false));
        }
    }
    const checkSpecial = (e) => { if (/[<>&"]/.test(e.key)) e.preventDefault(); }
    return (
        <div style={{ display: "flex", flexDirection: "column" }}>
            <Card className=' border-0 '>
                <div className='card-header mb-2'>
                    <div className="row">
                        <div className="col">
                            <h5>Conditions  </h5>
                        </div>
                        <div className="col text-right">
                            ( {UserData?.firstName} {UserData?.lastName} , {moment(UserData?.birthday).format("DD-MMM-YYYY")} ({UserData?.age}) ,{UserData?.gendercodeName} )
                        </div>
                    </div>
                </div>
                <Card.Body>
                    <Card.Text>
                        <div className=' col-md-12 '>
                            <div className="row justify-content-between">
                                <div className='col-md-6'>
                                </div>
                                <div className='col-md-6  mb-3  text-end'>
                                    <button className='btn btn-primary' onClick={handleShow}>Add Conditions +</button>
                                </div>
                                <div className="table-responsive">
                                    <table class="table border-0" id="ConditionTable    ">
                                        <thead >
                                            <tr>
                                                <th>Code</th>
                                                <th>Name</th>
                                                <th>Resource Type</th>
                                                <th>Clinical Status</th>
                                                <th>Verification Status</th>
                                                <th>Status</th>
                                                <th>Created On</th>
                                                <th>Modified On</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {conditions?.map((listValue, index) => {
                                                return (
                                                    <tr>
                                                        <td>{listValue.code}</td>
                                                        <td>{listValue.name}</td>
                                                        <td>{listValue.resourceType}</td>
                                                        <td>{listValue.clinicalStatus}</td>
                                                        <td>{listValue.verificationStatus}</td>
                                                        <td>{listValue.statuscode}</td>
                                                        <td>{moment(listValue.createdon).format("DD-MMM-yyyy") ? moment(listValue.createdon).format("DD-MMM-yyyy") : moment(listValue.createdon).format("DD-MMM-yyyy")}</td>
                                                        <td>{moment(listValue.modifiedOn).format("DD-MMM-yyyy") ? moment(listValue.modifiedOn).format("DD-MMM-yyyy") : moment(listValue.modifiedOn).format("DD-MMM-yyyy")}</td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <Modal size="lg" show={show} onHide={handleClose} >
                                <form onSubmit={handleSubmit}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Add Condition</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body >
                                        <div className='ms-3 row'>
                                            <label className='col-sm-3 col-form-label ' >Search Condition : </label>
                                            <div className="col-sm-8 d-flex">
                                                <input className='form-control m-1   border-1' placeholder='Search Condition' name="input1"
                                                    onKeyDown={(e) => checkSpecial(e)} onChange={e => setQuery(e.target.value)} />
                                                <i class="fa-solid fa-magnifying-glass m-2 fs-4" onClick={findByQuery}></i>
                                            </div>

                                            <label className='col-sm-3 col-form-label ' >Condition Name <span className="required"></span> : </label>
                                            <div className="col-sm-8">
                                                <select className="form-select  border-1 m-1" id="floatingSelect" name="title" onChange={e => getData(e.target.value)}  >
                                                    <option value={null}>Select</option>
                                                    {querydata?.map((data, i) => { return (<><option value={data.ID}> {data?.Name}</option></>) })}
                                                </select>
                                            </div>
                                            <label className='col-sm-3 col-form-label'>Code  : </label>
                                            <div className="col-sm-8">
                                                <input className='form-control m-1  border-1' id='code' onKeyDown={(e) => checkSpecial(e)} defaultValue={SnomedCode} />
                                            </div>
                                            <label className='col-sm-3 col-form-label ' >Clinical Status : </label>
                                            <div className="col-sm-8 ">
                                                <select className="form-select  border-1 m-1" id="floatingSelect" name="title" onChange={e => setclinicalStatus(e.target.value)} >
                                                    <option > -- Select --- </option>
                                                    <option value="271400000">Active</option>
                                                </select>
                                            </div>
                                            <label className='col-sm-3 col-form-label ' >Verification  Status  :</label>
                                            <div className="col-sm-8 ">
                                                <select className="form-select   border-1 m-1" id="floatingSelect" name="title" onChange={e => setverificationStatus(e.target.value)}>
                                                    <option >-- Select --- </option>
                                                    <option value="271400000">Unconfirmed</option>
                                                    <option value="271400001">Confirmed</option>
                                                </select>
                                            </div>
                                        </div>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button className='btn btn-primary' onClick={createCondition} >Save</Button>
                                        <Button variant="secondary" className='btn btn-primary' onClick={handleClose}>Close</Button>
                                    </Modal.Footer>
                                </form>
                            </Modal>
                        </div>
                    </Card.Text>
                </Card.Body>
            </Card>
        </div>
    )
}
