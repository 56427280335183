import React, { useEffect } from "react";
import "./Home.css";
import { useNavigate } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { setLoader } from "./Store/Reducers/UserReducer";

export default function Home({ showSidebar }) {
    const { useState, useEffect } = React;
    const { instance, accounts, inProgress } = useMsal();
    const dispatch = useDispatch();
    const [authToken, setAuthToken] = useState([]);
    const [istoken, setistoken] = useState(false);

    const LoginRedirectHandler = async () => {
        var CookiePolicyValue = localStorage.getItem("CookiePolicy");
        if (CookiePolicyValue == "false") {
            Swal.fire({
                title: 'Cookies Policy Issue',
                text: "You haven't enabled third party cookies in your browser,Please enable to continue.",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Okay',
                cancelButtonText: 'Browser settings',
            }).then(result => {
                if (result.isConfirmed) {
                    return
                    //logout();
                } else if (
                    result.dismiss === Swal.DismissReason.cancel
                ) {
                    //this.LogOut();
                    window.open('#/cookiesdetails', '_blank');
                }
            })
        } else {
            try {
                dispatch(setLoader(true));
                const account = instance.getAllAccounts();
                instance.loginRedirect().then(response => {
                    dispatch(setLoader(false));
                }).catch((e) => {
                })
                dispatch(setLoader(false));
            } catch (err) {
            }
        }

    };
    useEffect(() => {
        dispatch(setLoader(false));
    }, []);

    const getToken = async () => {
        if (accounts[0].idTokenClaims.jobTitle == "Consultant") { }
        try {
            dispatch(setLoader(true));
            var username = "test@contoso.com";
            var currentAccount = instance.getAccountByUsername(accounts[0].username);
            var silentRequest = {
                scopes: ["openid", "profile", "https://OXARV2B2C.onmicrosoft.com/9a5055e5-9eda-4589-b3fc-40a73ba0b68f/Write", "https://OXARV2B2C.onmicrosoft.com/9a5055e5-9eda-4589-b3fc-40a73ba0b68f/Read"],
                account: currentAccount,
                forceRefresh: false
            };
            const tokenResponse = await instance.acquireTokenSilent(silentRequest).catch(error => {
            });
            sessionStorage.setItem("AuthToken", tokenResponse.accessToken);
            setAuthToken(tokenResponse);
            if (tokenResponse.accessToken != "") {
                sessionStorage.setItem('TOKEN', true);
                { localStorage.setItem("ISTOKEN", true) }
                setistoken(true);
                dispatch(setLoader(false));
            }
        } catch (e) {
        }
    }
    const navigate = useNavigate();
    const TokenValue = localStorage.getItem("ISTOKEN");

    return (
        <div className="">
            <div className="">
                <img src="assets\img\oxar.png" className="imageforbg " alt="Snow" />
            </div>
            <div className="test">
                <div className="centered">
                    <div className="w-9/12 mx-auto grid py-5 lg:grid-cols-3 md:grid-cols-2 grid-cols-1">
                        <div className="mt-10">
                            <h1 className=" text-white lg:text-5xl md:text-3xl font-bold px-5 py-3 mt-1">
                                OX.assisted reproduction
                            </h1>
                            <div>
                                {
                                    (TokenValue == "false" || TokenValue == null) &&
                                    <p className="mt-10 px-5 py-3  flex text-center">
                                        <button type='button' className="  btn1 m-2" onClick={(e) => { LoginRedirectHandler() }} >Login</button>
                                        <button type='button' className="  btn2 m-2" onClick={() => navigate('/register')}>Register</button>
                                    </p>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}