import React from 'react'
import "./footer.css"
import { useWindowWidthAndHeight } from "./CustomHooks";
import { useNavigate } from "react-router";

export default function Footer() {
  const [width, height] = useWindowWidthAndHeight();
  const navigate = useNavigate();
  return (
    <div>
      {width > 500 ? (

        <div id="container" className='text-light d-flex footer '>
          <nav className='position-sticky footer '>
            <div className='col-3 m-4'>
              <div className='ms-5  mb-2' onClick={() => navigate('/Home')}>
                <img src="assets\img\OX_DIGITAL_HEALTH_REV.png" id="CLinicLogo12" alt="" />
              </div>
            </div>

            <div className='row '>
              <div className=' mt-3'>
                <p className=' fs-6'>CONNECTING CLINICIANS.PATIENTS.DATA</p>
              </div>
              <div className='row d-flex linkfooter '>
                <div className='col-3 text-start'><a href={"https://oxdhblob.blob.core.windows.net/oxdh/Terms and Conditions-converted.pdf"} target="_blank"
                  rel="noreferrer" className="linkfooter text-light " >Terms & Conditions</a></div>

                <div className='col-3 linkfooter ' ><a href={"https://oxdhblob.blob.core.windows.net/oxdh/privacy%20policy-converted.pdf"} target="_blank"
                  rel="noreferrer" className="linkfooter text-light text-light" >Privacy Policy</a></div>

                <div className='col-3 linkfooter  '><a className="linkfooter text-light" target="_blank"
                  rel="noreferrer" href={"https://oxdhblob.blob.core.windows.net/oxdh/Cookie%20Policy-converted.pdf"}>Cookie Policy
                </a></div>

                <div className='col-3 linkfooter '> <a href="#/Help" onClick={() => navigate('/Help')} className="linkfooter text-light" >Help</a></div>
              </div>
            </div>
          </nav>
        </div>
      ) : (
        // samall screen Footer
        <div id="container" className='text-light '>
          <nav >
            <div className='col-md-12  text-center'>
              <div className='row '>
                <div className=' mt-3'>
                  <img src="assets\img\OX_DIGITAL_HEALTH_REV.png" id="CLinicLogo12" alt="" />
                </div>
              </div>
              <div className='row text-center '>
                <ul ><b><span className='fs-6'>CONNECTING CLINICIANS.PATIENTS.DATA</span></b>
                  <li className='text-white'>
                    <a href={"https://oxdhblob.blob.core.windows.net/oxdh/Terms and Conditions-converted.pdf"} target="_blank"
                      rel="noreferrer" className="linkfooter text-light " >Terms & Conditions
                    </a>
                  </li>
                  <li className='text-white'>
                    <a href={"https://oxdhblob.blob.core.windows.net/oxdh/privacy%20policy-converted.pdf"} target="_blank"
                      rel="noreferrer" className="linkfooter text-light text-light" >Privacy Policy</a>
                  </li>
                  <li className='text-white'><a className="linkfooter text-light" target="_blank"
                    rel="noreferrer" href={"https://oxdhblob.blob.core.windows.net/oxdh/Cookie%20Policy-converted.pdf"}>Cookies Policy
                  </a>
                  </li>
                  <li className='text-white'>
                    <a onClick={() => navigate('/Help')} className="linkfooter text-light" >Help</a>
                  </li>
                </ul>
              </div>
            </div>
          </nav >
        </div >
      )
      }

    </div >
  )
}