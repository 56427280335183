import React, { useState, useEffect } from 'react'
import LoginFun from '../../Layout Component/Loginfun';
import { Card, Button } from 'react-bootstrap'
import { setLoader } from '../../Store/Reducers/UserReducer';
import { useDispatch } from "react-redux";
import axios from 'axios';
import $ from "jquery";
import { getProfileData, GetQuestionairebyPatientId, getAllergies, getAllfertilityTreat, getAllprevPregnancy, getAllprevProcedure } from '../../services/ProfileService';
import './ProfilePreview.css'
import moment from 'moment';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
var UserData;
export default function ProfilePreview() {
  UserData = JSON.parse(localStorage.getItem("UData"));
  const url = process.env.REACT_APP_OXAR_API_URL;
  const dispatch = useDispatch();
  const [showBTN, setshowBTN] = useState(false);
  const [accordian, setAccordian] = useState(false);
  const [accordian1, setAccordian1] = useState(false);
  const [accordian2, setAccordian2] = useState(false);
  const [accordian3, setAccordian3] = useState(false);
  const [accordian4, setAccordian4] = useState(false);
  const [accordian5, setAccordian5] = useState(false);
  const [accordian6, setAccordian6] = useState(false);
  const [accordian7, setAccordian7] = useState(false);
  const [accordian8, setAccordian8] = useState(false);
  const [accordian9, setAccordian9] = useState(false);
  const [accordian10, setAccordian10] = useState(false);
  const [accordian11, setAccordian11] = useState(false);
  const [profileData, setprofileDate] = useState();
  const [Questionaire, setQuestionaire] = useState();
  const [PrevPregnancy, setPrevPregnancy] = useState();
  const [PrevProcedure, setPrevProcedure] = useState();
  const [AllfertilityTrea, setAllfertilityTrea] = useState();
  const [AllAllergy, setAllAllergy] = useState();
  const [logopath, setlogopath] = useState(false);
  const [ClinicLogoURL, setClinicLogoURL] = useState(false);
  const uID = localStorage.getItem('UserID');
  const data = LoginFun();
  useEffect(() => {
    var currentTime = ''
    currentTime = (Math.round(new Date().getTime() / 1000));
    var Expiretime = localStorage.getItem("ExpirretokenTime");
    debugger;
    if (Expiretime < currentTime) {
      debugger
      sessionStorage.getItem('isLoggedIn', false)
      Swal.fire({
        title: 'Session Expired',
        text: "Your session has been expired! Please login again",
        icon: 'warning',
        showCancelButton: false,
        confirmButtonText: 'Okay',
      }).then(result => {
        data.logout();
      })
    }

    dispatch(setLoader(true));
    getData();
    dispatch(setLoader(false));
    $('#PrintBtn').hide();
    setlogopath('assets/img/OX_assisted_repro.png');
    let ClinicLogoURL = localStorage.getItem("ClinicLogoURL");
    setClinicLogoURL(ClinicLogoURL);

  }, [])
  const getData = async () => {
    dispatch(setLoader(true));
    const res = await getProfileData(uID);
    if (res !== undefined) {
      if (res.content.birthday != null) {
        res.content.birthday = res.content['birthday'].substring(0, 10);
      }
      setprofileDate(res.content)

    }
    const dataQue = await GetQuestionairebyPatientId(uID)
    if (dataQue !== undefined) {
      setQuestionaire(dataQue.content);
    }
    dispatch(setLoader(false));
  }
  const Expand = () => {
    debugger
    $('#PrintBtn').show();
    dispatch(setLoader(true));
    getAllAlergies()
    femaleMedicalHistory();
    Reproductive();
    getAllfertilityTreats();
    setshowBTN(true)
    setAccordian(true);
    setAccordian1(true);
    setAccordian2(true);
    setAccordian3(true);
    setAccordian4(true);
    setAccordian5(true);
    setAccordian5(true);
    setAccordian6(true);
    setAccordian7(true);
    setAccordian8(true);
    setAccordian9(true);
    setAccordian10(true);
    setAccordian11(true);
    dispatch(setLoader(false));
  }
  const Collapse = () => {
    debugger
    $('#PrintBtn').hide();
    setshowBTN(false)
    setAccordian(false);
    setAccordian1(false);
    setAccordian2(false);
    setAccordian3(false);
    setAccordian4(false);
    setAccordian5(false);
    setAccordian5(false);
    setAccordian6(false);
    setAccordian7(false);
    setAccordian8(false);
    setAccordian9(false);
    setAccordian10(false);
    setAccordian11(false)
  }
  const printPage = () => {
    debugger;
    $("#logoshow").css("display", "block");
    var originalContents = document.body.innerHTML;
    var printprofile = document.getElementById("IdToBeAppent1").innerHTML;
    document.body.innerHTML = printprofile;
    window.print();
    document.body.innerHTML = originalContents;
    window.location.reload(false);
  };

  const Reproductive = async () => {

    if (!PrevPregnancy) {
      dispatch(setLoader(true));
      const res = await getAllprevPregnancy(uID);
      if (res !== undefined) {
        setPrevPregnancy(res.content);
      }
      dispatch(setLoader(false));
    }
  }
  const femaleMedicalHistory = async () => {
    if (!PrevProcedure) {
      dispatch(setLoader(true));
      const res = await getAllprevProcedure(uID);
      if (res !== undefined) {
        setPrevProcedure(res.content);
      }
      dispatch(setLoader(false));
    }
  }
  const getAllfertilityTreats = async () => {
    if (!AllfertilityTrea) {
      dispatch(setLoader(true));
      const res = await getAllfertilityTreat(uID);
      if (res !== undefined) {
        setAllfertilityTrea(res.content);
      }
      dispatch(setLoader(false));
    }
  }
  const getAllAlergies = async () => {
    if (!AllAllergy) {
      dispatch(setLoader(true));
      const res = await getAllergies(uID);
      if (res !== undefined) {
        setAllAllergy(res.content);
      }
      dispatch(setLoader(false));
    }
  }

  const Submit = async (data) => {
    if (data.areYousureYouwanttosubmit === false) {
      data.areYousureYouwanttosubmit = true
      try {
        debugger;
        dispatch(setLoader(true));
        await axios.put(`${url}/api/Profile/Update`, data).then(async (res) => {

          if (res.status === 200) {
            toast.success('Profile submitted successfully', {
              autoClose: 5000,
              hideProgressBar: true,
              newestOnTop: false,
              closeOnClick: true,
              rtl: false,
              pauseOnFocusLoss: true,
              draggable: true,
              pauseOnHover: true,
              className: 'toast-message'
            }
            )
          }

          const datas = await getProfileData(uID);
          setprofileDate(datas.content)
          dispatch(setLoader(false));
        });

      }
      catch {
        dispatch(setLoader(false));
      }
    }

    debugger;

  }

  return (
    <>
      <Card className=' border-0 cardcolor'>
        {/* <Card.Header className=' colorprofile  text-light'><h5>Profile Preview</h5></Card.Header> */}
        <div className='card-header mb-2'>
          <div className="row">
            <div className="col">
              <h5>Profile Preview </h5>
            </div>
            <div className="col text-right">
              ( {UserData?.firstName} {UserData?.lastName} , {moment(UserData?.birthday).format("DD-MMM-YYYY")} ({UserData?.age}) ,{UserData?.gendercodeName} )
            </div>
          </div>
        </div>
        <Card.Body className='bg-white'>
          <Card.Text className='row'>
            {/* step wizard for Medical History */}
            <div className="col-md-1"></div>
            <div className='col-xl-10 col-lg-10 col-md-10 col-sm-6   mt-0'>
              <Card.Body>

                {!showBTN ?
                  <Button type='button' className='btn btn-primary mr-2' onClick={Expand}>Expand/Close</Button>
                  :
                  <Button type='button' className='btn btn-primary mr-2' onClick={Collapse}>Expand/Close</Button>}
                &nbsp;&nbsp;
                <Button type='button' id='PrintBtn' className='btn btn-primary mr-2' onClick={printPage}>Print</Button>

                <div id="IdToBeAppent1">

                  <div id="logoshow">
                    <img src={logopath} style={{ width: "250px" }} />
                    <img src={ClinicLogoURL} style={{ width: "20%" }} />
                  </div>

                  <div className=" mb-2 mt-1 ps-3 pe-3   bgHeader" onClick={() => setAccordian(!accordian)}>
                    <div className='card-header mt-2 d-flex row'>
                      <div className='col-md-6'> <h4 className='fs-6  text-start'>Personal Information</h4></div>
                      <div className='col-md-6 text-end'>{
                        <i className={`fa fa-${accordian ? "minus" : 'plus'}  pointer me-2  fs-4 `} ></i>
                      }

                      </div>
                    </div>
                    {
                      accordian &&
                      <div className="card-header bg-white row">

                        <div className="col-md-6 mt-1">
                          <b>Title: </b>&nbsp;{profileData?.titleName}
                        </div>
                        <div className="col-md-6 mt-1">
                          <b>First Name: </b>&nbsp;{profileData?.firstName}
                        </div>
                        <div className="col-md-6 mt-1">
                          <b>Last Name: </b>&nbsp;{profileData?.lastName}
                        </div>
                        <div className="col-md-6 mt-1">
                          <b>Previous Surname: </b>&nbsp;{profileData?.previousName}
                        </div>
                        <div className="col-md-6 mt-1">
                          <b>Date of Birth: </b>&nbsp;{moment(profileData?.birthday).format("DD-MMM-YYYY")}
                        </div>
                        <div className="col-md-6 mt-1">
                          <b>Occupation: </b>&nbsp;{profileData?.occupation}
                        </div>
                        <div className="col-md-6 mt-1">
                          <b>Passport / ID Number: </b>&nbsp;{profileData?.passportIdNumber}
                        </div>
                        <div className="col-md-6 mt-1">
                          <b>Country of Issue: </b>&nbsp;{profileData?.countryOfIssueName}
                        </div>
                        <div className="col-md-6 mt-1">
                          <b>NHS Number: </b>&nbsp;{profileData?.nhsNumber}
                        </div>
                        {/* <div className="col-md-6 mt-1">
                                <b>Relationship Status: </b>&nbsp;{profileData?.relationShipStatusName}
                              </div> */}
                        <div className="col-md-6 mt-1">
                          <b>Address: </b>&nbsp;{profileData?.address}
                        </div>
                        <div className="col-md-6 mt-1">
                          <b>City: </b>&nbsp;{profileData?.city}
                        </div>
                        <div className="col-md-6 mt-1">
                          <b>ZIP/Postal Code: </b>&nbsp;{profileData?.postalCode}
                        </div>
                        <div className="col-md-6 mt-1">
                          <b>Country: </b>&nbsp;{profileData?.country}
                        </div>
                        <div className="col-md-6 mt-1">
                          <b>Place of Birth: </b>&nbsp;{profileData?.placeOfBirth}
                        </div>
                        <div className="col-md-6 mt-1">
                          <b>Country of Birth: </b>&nbsp;{profileData?.countryOfBirthName}
                        </div>
                        <div className="col-md-6 mt-1">
                          <b>Home Phone: </b>&nbsp;{profileData?.homePhone}
                        </div>
                        <div className="col-md-6 mt-1">
                          <b>Business Phone: </b>&nbsp;{profileData?.businessPhone}
                        </div>
                        <div className="col-md-6 mt-1">
                          <b>Mobile Phone: </b>&nbsp;{profileData?.mobileNumber}
                        </div>
                        <div className="col-md-6 mt-1">
                          <b>Email: </b>&nbsp;{profileData?.email}
                        </div>
                        <div className="col-md-6 ">
                          <b>Ethnic Group: </b>&nbsp;{profileData?.ethnicity}
                        </div>
                        {Questionaire &&
                          <div className="col-md-6 ">
                            <b>Do you have a disability: </b>&nbsp;{profileData?.haveDisability}
                            {Questionaire?.isThePatientDisabled === 271400001 ? "No" :
                              Questionaire?.isThePatientDisabled === 271400000 ? "Yes" :
                                null
                            }
                          </div>
                        }
                        <div className="col-md-6 ">
                          <b>If yes, please give details: </b>&nbsp;{Questionaire?.isThePatientDisabledifYesPleaseGiveDetail}
                        </div>
                        <div className="col-md-6 ">
                          <b>Do you consent to receiving reminders by text message?: </b>&nbsp;{profileData?.doYouconsenttoreceivingremindersbytextmes === true ?
                            "Allow" : "No"}
                        </div>
                      </div>
                    }
                  </div>
                  <div className=" mb-2 ps-3 pe-3   bgHeader" onClick={() => setAccordian1(!accordian1)} >
                    <div className='card-header mt-2 d-flex row'>
                      <div className='col-md-6'> <h4 className='fs-6  text-start'>GP Details</h4></div>
                      <div className='col-md-6 text-end'>{
                        <i className={`fa fa-${accordian1 ? "minus" : 'plus'}  pointer me-2  fs-4  `} ></i>
                      }

                      </div>
                    </div>
                    {
                      accordian1 &&
                      <div className="card-header bg-white row">
                        <div className='col-xl-12 col-lg-12 col-md-4 col-sm-6 text-start'>
                          {/* <img className='w-50 h-100' src={metadata?.imageLink} width="50" height={10} alt="test" /> */}
                        </div>
                        <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 text-start mt-2'>
                          <div className="panel" >
                            <div className="row">
                              <div className="col-md-6">
                                <b > GPs Name: </b>{profileData?.gPsName}
                              </div>
                              <div className="col-md-6">
                                <b > GP Surgery Name: </b>{profileData?.gpSurgeryName}
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-6">
                                <b >GP Address: </b>{profileData?.gpaddress}
                              </div><div className="col-md-6">
                                <b >
                                  GP City: &nbsp;</b>{profileData?.gpCity} </div>
                            </div><div className="row">
                              <div className="col-md-6">
                                <b >
                                  GP ZIP/Postal Code: </b>{profileData?.gpPostalCode}
                              </div>
                              <div className="col-md-6">
                                <b >
                                  GP Telephone Number: </b>&nbsp;{profileData?.gpTelephoneNumber}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    }
                  </div>
                  <div className=" mb-2 ps-3 pe-3   bgHeader" onClick={() => setAccordian2(!accordian2)}>
                    <div className='card-header mt-2 d-flex row'>
                      <div className='col-md-6'> <h4 className='fs-6  text-start'>Emergency Contact</h4></div>
                      <div className='col-md-6 text-end'>{
                        <i className={`fa fa-${accordian2 ? "minus" : 'plus'}  pointer me-2  fs-4 `} ></i>
                      }
                      </div>
                    </div>
                    {
                      accordian2 &&
                      <div className="card-header bg-white row">
                        <div className='col-xl-2 col-lg-2 col-md-4 col-sm-6 text-start'>
                          {/* <img className='w-50 h-100' src={metadata?.imageLink} width="50" height={10} alt="test" /> */}
                        </div>
                        <div className="row">
                          <div className="col-md-6 mt-1">
                            <b>In the event of a medical emergency, please specify emergency contact person: </b>{profileData?.intheEventofamedicalEmergencyPleasespecif === 0 ?
                              "Person with whom you are having treatment" : "Other"}
                          </div>
                          <div className="col-md-6 mt-1">
                            <b>Emergency Contact Full Name: </b>{profileData?.emergencyContactName}
                          </div>
                          <div className="col-md-6 mt-1">
                            <b>Emergency Contact Telephone: </b>{profileData?.emergencyContactTelephone}
                          </div>
                        </div>
                      </div>
                    }
                  </div>
                  <div className=" mb-2 ps-3 pe-3   bgHeader" onClick={() => setAccordian3(!accordian3)}>
                    <div className='card-header mt-2 d-flex row'>
                      <div className='col-md-6'> <h4 className='fs-6 text-start'>Clinical History</h4></div>
                      <div className='col-md-6 text-end'>{
                        <i className={`fa fa-${accordian3 ? "minus" : 'plus'}  pointer me-2  fs-4 `} ></i>
                      }
                      </div>
                    </div>
                    {
                      accordian3 &&
                      <div className="card-header bg-white row">
                        <div className='col-xl-2 col-lg-2 col-md-4 col-sm-6 text-start'>
                          {/* <img className='w-50 h-100' src={metadata?.imageLink} width="50" height={10} alt="test" /> */}
                        </div>
                        <div className="row">
                          <div className="col-md-6 mt-1">
                            <b>Reason for contacting: </b>{Questionaire?.reasonForContacting === 271400000 ? "PGS (PGT-A) Pre Implantation Genetic Screening" :
                              Questionaire?.reasonForContacting === 0 ? "Primary infertility (no pregnancies)" :
                                Questionaire?.reasonForContacting === 1 ? "Secondary infertility (previous pregnancies in current relationship)" :
                                  Questionaire?.reasonForContacting === 2 ? "Secondary infertility (previous pregnancies in a different relationship)" :
                                    Questionaire?.reasonForContacting === 3 ? "Recurrent Miscarriage" :
                                      Questionaire?.reasonForContacting === 4 ? "Single person" :
                                        Questionaire?.reasonForContacting === 5 ? "Same sex couple" :
                                          Questionaire?.reasonForContacting === 6 ? "Fertility preservation due to medical reasons" :
                                            Questionaire?.reasonForContacting === 7 ? "Fertility preservation for social reasons" :
                                              Questionaire?.reasonForContacting === 8 ? "PGD to reduce risk of passing on genetic disorder" :
                                                Questionaire?.reasonForContacting === 9 ? "To be an egg donor" :
                                                  Questionaire?.reasonForContacting === 10 ? "Egg recipient ( to receive donated eggs)" : null
                            }
                          </div>
                          <div className="col-md-6 mt-1">
                            <b>How long have you been trying to get pregnant (if applicable)? </b>{Questionaire?.howLongHaveYouBeenTryingtoGetPregnant}
                          </div>
                        </div>
                      </div>
                    }
                  </div>
                  {
                    profileData?.gender === 2 &&
                    <div className=" mb-2 ps-3 pe-3   bgHeader" onClick={() => setAccordian4(!accordian4)}>
                      <div className='card-header mt-2 d-flex row'>
                        <div className='col-md-6'> <h4 className='fs-6  text-start'>Menstrual History</h4></div>
                        <div className='col-md-6 text-end'>{
                          <i className={`fa fa-${accordian4 ? "minus" : 'plus'}  pointer me-2  fs-4 `} ></i>
                        }
                        </div>
                      </div>
                      {
                        accordian4 &&
                        <div className="card-header bg-white row">
                          <div className='col-xl-2 col-lg-2 col-md-4 col-sm-6 text-start'>
                            {/* <img className='w-50 h-100' src={metadata?.imageLink} width="50" height={10} alt="test" /> */}
                          </div>
                          <div className="row">
                            <div className="col-md-6 mt-1">
                              <b>How old were you when you started periods (Menarche)?: </b>{Questionaire?.howoldwereyouwhenyoustartedperiodsmenarch}
                            </div>
                            <div className="col-md-6 mt-1">
                              <b>What date was your last menstrual period?: </b>{moment(Questionaire?.whatDateWasYourLastMenstrualPeriod).format("DD-MMM-YYYY")}
                            </div>
                            <div className="col-md-6 mt-1">
                              <b>What is the normal length of bleeding days?: </b>{Questionaire?.whatIsTheNormalLengthofBleedingDays}
                            </div>
                            <div className="col-md-6 mt-1">
                              <b>How long are your cycles (days)?: </b>{Questionaire?.howLongAreYourCyclesDays}
                            </div>
                            <div className="col-md-6 mt-1">
                              <b>Do you suffer from painful periods dysmenorrhea?: </b>
                              {Questionaire?.doYouSufferfromPainfulPeriodsDysmenorrhoea === 271400001 ? "No" :
                                Questionaire?.doYouSufferfromPainfulPeriodsDysmenorrhoea === 271400000 ? "Yes" :
                                  null
                              }
                            </div>
                            <div className="col-md-6 mt-1">
                              <b>Do you suffer from heavy periods menorrhagia?: </b>
                              {Questionaire?.doYouSufferfromHeavyPeriodsMenorrhagia === 271400001 ? "No" :
                                Questionaire?.doYouSufferfromHeavyPeriodsMenorrhagia === 271400000 ? "Yes" :
                                  null
                              }
                            </div>
                            <div className="col-md-6 mt-1">
                              <b>Do you have bleeding after intercourse?: </b>
                              {Questionaire?.doYouExperienceBleedingAfterIntercourse === 271400001 ? "No" :
                                Questionaire?.doYouExperienceBleedingAfterIntercourse === 271400000 ? "Yes" :
                                  null
                              }
                            </div>
                            <div className="col-md-6 mt-1">
                              <b>Do you have bleeding between periods: </b>
                              {Questionaire?.doYouHaveBleedingBetweenPeriods === 271400001 ? "No" :
                                Questionaire?.doYouHaveBleedingBetweenPeriods === 271400000 ? "Yes" :
                                  null
                              }
                            </div>
                            <div className="col-md-6 mt-1">
                              <b>Are you currently using any contraceptives: </b>
                              {Questionaire?.areyouCurrentlyUsingAnyContraceptives === 271400001 ? "No" :
                                Questionaire?.areyouCurrentlyUsingAnyContraceptives === 271400000 ? "Yes" :
                                  null
                              }
                            </div>
                            <div className="col-md-6 mt-1">
                              <b>If yes, please give details: </b>{Questionaire?.contraceptivesIfYesPleaseGiveDetails}
                            </div>
                            <div className="col-md-6 mt-1">
                              <b>When was your last smear test?: </b>{Questionaire?.whenWasYourLastSmearTest}
                            </div>
                            <div className="col-md-6 mt-1">
                              <b>What was the smear test result?: </b>
                              {Questionaire?.whatWasYourLastSmearTestResult === 271400001 ? "Abnormal" :
                                Questionaire?.whatWasYourLastSmearTestResult === 271400000 ? "Normal" :
                                  null
                              }
                            </div>
                            <div className="col-md-6 mt-1">
                              <b>Have you ever had an abnormal blood test?: </b>
                              {Questionaire?.haveYouEverhadanAbnormalTest === 271400001 ? "No" :
                                Questionaire?.haveYouEverhadanAbnormalTest === 271400000 ? "Yes" :
                                  null
                              }
                            </div>
                            <div className="col-md-6 mt-1">
                              <b>If yes, please give details: </b>{Questionaire?.hadanAbnormalTestifYesPleaseGiveDetails}
                            </div>
                            <div className="col-md-6 mt-1">
                              <b>Have you ever had treatment to your cervix?: </b>
                              {Questionaire?.haveyouhadanytreatmenttoyourcervix === 271400001 ? "No" :
                                Questionaire?.haveyouhadanytreatmenttoyourcervix === 271400000 ? "Yes" :
                                  null
                              }
                            </div>
                            <div className="col-md-6 mt-1">
                              <b>If yes, please give details: </b>{Questionaire?.cervixTreatmentifYesPleaseGiveDetails}
                            </div>
                            <div className="col-md-6 mt-1">
                              <b>Have you ever had any sexually transmitted diseases?: </b>
                              {Questionaire?.haveYouEverHadAnySexuallyTransmittedDisease === 271400001 ? "No" :
                                Questionaire?.haveYouEverHadAnySexuallyTransmittedDisease === 271400000 ? "Yes" :
                                  null
                              }
                            </div>
                            <div className="col-md-6 mt-1">
                              <b>If yes, please give details: </b>{Questionaire?.sexuallytransmitteddiseaseIfyesgivedetail}
                            </div>
                            <div className="col-md-6 mt-1">
                              <b>Have you ever had any previous pelvic infections?: </b>
                              {Questionaire?.haveYouEverHadAnyPreviousPelvicInfections === 271400001 ? "No" :
                                Questionaire?.haveYouEverHadAnyPreviousPelvicInfections === 271400000 ? "Yes" :
                                  null
                              }
                            </div>
                          </div>
                        </div>
                      }
                    </div>
                  }

                  {
                    profileData?.gender === 2 &&
                    <div className=" mb-2 ps-3 pe-3   bgHeader" onClick={() => setAccordian5(!accordian5)}>
                      <div className='card-header mt-2 d-flex row'>
                        <div className='col-md-6'> <h4 className='fs-6   text-start'>Gynaecological History</h4></div>
                        <div className='col-md-6 text-end'>{
                          <i className={`fa fa-${accordian5 ? "minus" : 'plus'}  pointer me-2  fs-4 `} ></i>
                        }
                        </div>
                      </div>
                      {
                        accordian5 &&
                        <div className="card-header bg-white row">
                          <div className='col-xl-2 col-lg-2 col-md-4 col-sm-6 text-start'>
                            {/* <img className='w-50 h-100' src={metadata?.imageLink} width="50" height={10} alt="test" /> */}
                          </div>
                          <div className="row">
                            <div className="col-md-6 mt-1">
                              <b>Have you ever been diagnosed with a gynaecological condition e.g. fibroids, endometriosis, Cysts?: </b>
                              {Questionaire?.haveYouEverHadAnyGynaecologicalTreatment === 271400001 ? "No" :
                                Questionaire?.haveYouEverHadAnyGynaecologicalTreatment === 271400000 ? "Yes" :
                                  null
                              }
                            </div>
                            <div className="col-md-6 mt-1">
                              <b>If yes, please give details: </b>{Questionaire?.diagnosedwithaGynaecologicalIfYesPleasegive}
                            </div>
                          </div>
                        </div>
                      }
                    </div>
                  }
                  <div className=" mb-2 ps-3 pe-3   bgHeader" onClick={() => { Reproductive(); setAccordian6(!accordian6) }}>
                    <div className='card-header mt-2 d-flex row'>
                      <div className='col-md-6'> <h4 className='fs-6  text-start'>Reproductive History</h4></div>
                      <div className='col-md-6 text-end'>{
                        <i className={`fa fa-${accordian6 ? "minus" : 'plus'}  pointer me-2  fs-4 `} ></i>
                      }

                      </div>
                    </div>
                    {
                      accordian6 &&
                      <div className="card-header bg-white row">
                        <div className='col-xl-2 col-lg-2 col-md-4 col-sm-6 text-start'>
                          {/* <img className='w-50 h-100' src={metadata?.imageLink} width="50" height={10} alt="test" /> */}
                        </div>
                        <div className='table-responsive'>
                          <table className="table table-border ">
                            <thead className="">
                              <tr>
                                <th>Year of Pregnancy</th>
                                <th>Was it with partner you are seeking treatment?</th>
                                <th>Conception Method</th><th>Number of Weeks Gestation</th>
                                <th>Created On</th>
                                <th>Comments</th>
                              </tr>
                            </thead>
                            <tbody>
                              {PrevPregnancy?.map((data, i) => {
                                return (<>
                                  <tr>
                                    <td>{data.yearOfPregnancy}</td>
                                    <td> {data.wasItWithPartner === true ? "Yes" : "No"}</td>
                                    <td>{data.conceptionMethod === 1 ? "Natural" : "Assisted Conception"}</td>
                                    <td>{data.numberOfWeeks}</td>
                                    <td>
                                      {moment(data?.createdon).format('DD-MMM-yyyy')}
                                    </td>
                                    <td>{data.comments}</td>
                                  </tr>
                                </>)
                              })}
                            </tbody>
                          </table>
                        </div>
                        {/* <div className="row">
                          <div className="col-md-6 mt-1">
                              <b>Address: </b>
                            </div>
                            <div className="col-md-6 mt-1">
                              <b>City: </b>
                            </div>
                          </div> */}
                      </div>
                    }
                  </div>
                  <div className=" mb-2 ps-3 pe-3   bgHeader" onClick={() => { femaleMedicalHistory(); setAccordian7(!accordian7) }}>
                    <div className='card-header mt-2 d-flex row'>
                      <div className='col-md-6'> <h4 className='fs-6  text-start'>
                        {profileData?.gender === 1 ?
                          "General Surgical And Medical History Male" : " General Surgical And Medical History Female"
                        }
                      </h4></div>
                      <div className='col-md-6 text-end'>{
                        <i className={`fa fa-${accordian7 ? "minus" : 'plus'}  pointer me-2  fs-4 `} ></i>
                      }

                      </div>
                    </div>
                    {
                      accordian7 &&
                      <div className="card-header bg-white row">
                        <div className='col-xl-2 col-lg-2 col-md-4 col-sm-6 text-start '>
                          {/* <img className='w-50 h-100' src={metadata?.imageLink} width="50" height={10} alt="test" /> */}
                        </div>
                        {
                          profileData?.gender === 2 &&
                          <>
                            <div className='table-responsive'>
                              <table className="table table-border ">
                                <thead className="">
                                  <tr>
                                    <th>Procedure Type</th>
                                    <th>Procedure Date</th>
                                    <th>Anaesthetic Type</th>
                                    <th>Gynaecological?</th>
                                    <th>Abdominal?</th>
                                    <th>Comments</th>
                                    <th>Created On</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {PrevProcedure?.map((data, i) => {
                                    return (<>
                                      <tr>
                                        <td>{data.procedureType}</td>
                                        <td>
                                          {moment(data?.procedureDate).format('DD-MMM-yyyy')}
                                        </td>
                                        <td>{data.anaestheticType}</td>
                                        <td>{data.gyanecological === true ? "Yes" : "No"}</td>
                                        <td>{data.abdominal === true ? "Yes" : "No"}</td>
                                        <td>{data.comments}</td>
                                        <td>{moment(data?.createdOn).format('DD-MMM-yyyy')}</td>
                                      </tr>
                                    </>)
                                  })}
                                </tbody>
                              </table>
                            </div>
                          </>
                        }
                        {
                          profileData?.gender === 1 &&
                          <div className="row">
                            <div className="row">
                              <b>Does your job expose you to any of the following?  :</b>
                            </div>
                            <div className="col-md-6 mt-1">
                              <b>Heat: </b>
                              {Questionaire?.heat === 271400001 ? "No" :
                                Questionaire?.heat === 271400000 ? "Yes" :
                                  null
                              }
                            </div>
                            <div className="col-md-6 mt-1">
                              <b>Chemicals: </b>
                              {Questionaire?.chemicals === 271400001 ? "No" :
                                Questionaire?.chemicals === 271400000 ? "Yes" :
                                  null
                              }
                            </div>
                            <div className="col-md-6 mt-1">
                              <b>Pesticides: </b>
                              {Questionaire?.pesticides === 271400001 ? "No" :
                                Questionaire?.pesticides === 271400000 ? "Yes" :
                                  null
                              }
                            </div>
                            <div className="col-md-6 mt-1">
                              <b>Radiation: </b>
                              {Questionaire?.radiation === 271400001 ? "No" :
                                Questionaire?.radiation === 271400000 ? "Yes" :
                                  null
                              }
                            </div>
                            <div className="col-md-6 mt-1">
                              <b>Have you ever had twisting of a testicle?: </b>
                              {Questionaire?.haveYouEverHadTwistingofaTesticle === 271400001 ? "No" :
                                Questionaire?.haveYouEverHadTwistingofaTesticle === 271400000 ? "Yes" :
                                  null
                              }
                            </div>
                            <div className="col-md-6 mt-1">
                              <b>Have you had mumps?: </b>
                              {Questionaire?.haveYouhadMumps === 271400001 ? "No" :
                                Questionaire?.haveYouhadMumps === 271400000 ? "Yes" :
                                  null
                              }
                            </div>
                            <div className="col-md-6 mt-1">
                              <b>Have you ever had any sexually transmitted diseases?:</b>
                              {Questionaire?.haveYouEverHadAnySexuallyTransmittedDisease === 271400001 ? "No" :
                                Questionaire?.haveYouEverHadAnySexuallyTransmittedDisease === 271400000 ? "Yes" :
                                  null
                              }
                            </div>
                            <div className="col-md-6 mt-1">
                              <b>Do you travel away from home often?: </b>
                              {Questionaire?.doTravelAwayFromHomeOften === 271400001 ? "No" :
                                Questionaire?.doTravelAwayFromHomeOften === 271400000 ? "Yes" :
                                  null
                              }
                            </div>
                            <div className="row">
                              <b>Do you have difficulties / problems with intercourse? :</b>
                            </div>
                            <div className="col-md-6 mt-1">
                              <b>Erection: </b>
                              {Questionaire?.erection === 271400001 ? "No" :
                                Questionaire?.erection === 271400000 ? "Yes" :
                                  null
                              }
                            </div>
                            <div className="col-md-6 mt-1">
                              <b>Ejaculation: </b>
                              {Questionaire?.ejaculation === 271400001 ? "No" :
                                Questionaire?.ejaculation === 271400000 ? "Yes" :
                                  null
                              }
                            </div>
                            <div className="col-md-6 mt-1">
                              <b>Penetration: </b>
                              {Questionaire?.penetration === 271400001 ? "No" :
                                Questionaire?.penetration === 271400000 ? "Yes" :
                                  null
                              }
                            </div>
                            <div className="col-md-6 mt-1">
                              <b>Pain: </b>
                              {Questionaire?.pain === 271400001 ? "No" :
                                Questionaire?.pain === 271400000 ? "Yes" :
                                  null
                              }
                            </div>
                          </div>
                        }
                      </div>
                    }
                  </div>

                  <div className=" mb-2 ps-3 pe-3   bgHeader" onClick={() => { getAllfertilityTreats(); setAccordian8(!accordian8) }}>
                    <div className='card-header mt-2 d-flex row'>
                      <div className='col-md-6'> <h4 className='fs-6  text-start'>Previous Fertility Treatment</h4></div>
                      <div className='col-md-6 text-end'>{
                        <i className={`fa fa-${accordian8 ? "minus" : 'plus'}  pointer me-2  fs-4 `} ></i>
                      }
                      </div>
                    </div>
                    {
                      accordian8 &&
                      <div className="card-header bg-white row">
                        <div className='col-xl-2 col-lg-2 col-md-4 col-sm-6 text-start'>
                          {/* <img className='w-50 h-100' src={metadata?.imageLink} width="50" height={10} alt="test" /> */}
                        </div>
                        <div className="row">
                          <div className="col-md-6 mt-1">
                            <b>Have you had fertility investigations in the past?: </b>
                            {Questionaire?.haveYouHadFertilityInvestigationsinthePast === 271400001 ? "No" :
                              Questionaire?.haveYouHadFertilityInvestigationsinthePast === 271400000 ? "Yes" :
                                null
                            }
                          </div>
                          <div className="col-md-6 mt-1">
                            <b>If yes, please give details: </b>{moment(Questionaire?.fertilityInvesifYesPleaseGiveDetailsDates).format("DD-MMM-YYYY")}
                          </div>
                          <div className="col-md-6 mt-1">
                            <b>Have you ever had assisted conception treatment before? e.g. Ovulation Induction, IUI, IVF, ICSI: </b>
                            {Questionaire?.haveYouEverHadAssistedConceptionTreatment === 271400001 ? "No" :
                              Questionaire?.haveYouEverHadAssistedConceptionTreatment === 271400000 ? "Yes" :
                                null
                            }
                          </div>
                          <div className="col-md-6 mt-1">
                            <b>Was the treatment with the partner you are seeking treatment with?: </b>
                            {Questionaire?.wasTheTreatmentWiththePartnerYouAreSeeking === 271400001 ? "No" :
                              Questionaire?.wasTheTreatmentWiththePartnerYouAreSeeking === 271400000 ? "Yes" :
                                null
                            }
                          </div>
                          <div className="col-md-6 mt-1">
                            <b>Do you have any frozen eggs, sperm or embryos in storage elsewhere?: </b>
                            {Questionaire?.doYouHaveAnyFrozenEggsSpermOrEmbryosin === 271400001 ? "Partner Eggs" :
                              Questionaire?.doYouHaveAnyFrozenEggsSpermOrEmbryosin === 271400000 ? "No" :
                                Questionaire?.doYouHaveAnyFrozenEggsSpermOrEmbryosin === 271400002 ? "Sperm" :
                                  Questionaire?.doYouHaveAnyFrozenEggsSpermOrEmbryosin === 271400003 ? "Donor sperm" :
                                    Questionaire?.doYouHaveAnyFrozenEggsSpermOrEmbryosin === 271400004 ? "Embryos" :
                                      Questionaire?.doYouHaveAnyFrozenEggsSpermOrEmbryosin === 271400005 ? "Own Eggs" : <></>}
                          </div>
                        </div>
                        <div className='table-responsive'>
                          <table className="table table-border">
                            <thead className="">
                              <tr>
                                <th>Year</th>
                                <th>Treatment</th>
                                <th>Center</th>
                                <th>Sperm Source</th>
                                <th>PGS or PGD?</th>
                                <th>Number of Eggs Collected</th>
                                <th>Number of Eggs Fertilised</th>
                                <th>Number of Embryos Frozen</th>
                                <th>Number of Embryos Transferred</th>
                                <th>Outcome</th>
                                <th>Any cycle complications</th>
                                <th>Created On</th>
                              </tr>
                            </thead>
                            <tbody>
                              {AllfertilityTrea?.map((data, i) => {
                                return (<>
                                  <tr>
                                    <td>{data.yearofTreatment}</td>
                                    <td>{data.treatmentTypeName}</td>
                                    <td>{data.centre}</td>
                                    <td>{data.spermSource}</td>
                                    <td>{data.pgdpgd === true ? "Yes" : "No"}</td>
                                    <td>{data.numberofEggsCollected}</td>
                                    <td>{data.numberFertilised}</td>
                                    <td>{data.numberofEmbryosFrozenDayofFreeze}</td>
                                    <td>{data.numberofEmbryosTransferred}</td>
                                    <td>{data.outcome}</td>
                                    <td>{data.anyCycleComplications}</td>
                                    <td>
                                      {moment(data?.createdOn).format('DD-MMM-yyyy')}
                                    </td>
                                  </tr>
                                </>)
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    }
                  </div>
                  <div className=" mb-2 ps-3 pe-3   bgHeader" onClick={() => { getAllAlergies(); setAccordian9(!accordian9) }}>
                    <div className='card-header mt-2 d-flex row'>
                      <div className='col-md-6'> <h4 className='fs-6 text-start'>Allergies</h4></div>
                      <div className='col-md-6 text-end'>{
                        <i className={`fa fa-${accordian9 ? "minus" : 'plus'}  pointer me-2  fs-4 `} ></i>
                      }
                      </div>
                    </div>
                    {
                      accordian9 &&
                      <div className="card-header bg-white row">
                        <div className='col-xl-2 col-lg-2 col-md-4 col-sm-6 text-start'>
                          {/* <img className='w-50 h-100' src={metadata?.imageLink} width="50" height={10} alt="test" /> */}
                        </div>
                        <div className='table-responsive'>
                          <table className="table table-border ">
                            <thead className="thead-light">
                              <tr>
                                <th>Name</th>
                                <th>Description</th>
                                <th>Created On</th>
                              </tr>
                            </thead>
                            <tbody>
                              {AllAllergy?.map((data, i) => {
                                return (<>
                                  <tr>
                                    <td>{data.name}</td>
                                    <td>{data.description}</td>
                                    <td>{moment(data?.createdOn).format('DD-MMM-yyyy')}</td>
                                  </tr>
                                </>)
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    }
                  </div>

                  {
                    profileData?.gender === 2 &&
                    <div className=" mb-2 ps-3 pe-3   bgHeader" onClick={() => setAccordian10(!accordian10)}>
                      <div className='card-header mt-2 d-flex row'>
                        <div className='col-md-6'> <h4 className='fs-6 text-start'>Other Information</h4></div>
                        <div className='col-md-6 text-end'>{
                          <i className={`fa fa-${accordian10 ? "minus" : 'plus'}  pointer me-2  fs-4 `} ></i>
                        }
                        </div>
                      </div>
                      {
                        accordian10 &&
                        <div className="card-header bg-white row">
                          <div className='col-xl-2 col-lg-2 col-md-4 col-sm-6 text-start'>
                            {/* <img className='w-50 h-100' src={metadata?.imageLink} width="50" height={10} alt="test" /> */}
                          </div>
                          <div className="row">
                            <div className="col-md-6 mt-1">
                              <b>Do you have any medical conditions? e.g. Asthma, epilepsy, diabetes, anaemia, colitis etc: </b>
                              {Questionaire?.doYouHaveAnyMedicalConditionsEgAsthmae === 271400001 ? "No" :
                                Questionaire?.doYouHaveAnyMedicalConditionsEgAsthmae === 271400000 ? "Yes" :
                                  null
                              }
                            </div>
                            <div className="col-md-6 mt-1">
                              <b>If yes, please give details: </b>{Questionaire?.medicalconditionsifyespleaseprovidedetail}
                            </div>
                            <div className="col-md-6 mt-1">
                              <b>Do you have a family history of any medical conditions? e.g. cancer, heart disease, genetic disease: </b>
                              {Questionaire?.doyouHaveaFamilyHistoryofMedicalConditions === 271400001 ? "No" :
                                Questionaire?.doyouHaveaFamilyHistoryofMedicalConditions === 271400000 ? "Yes" :
                                  null
                              }
                            </div>
                            <div className="col-md-6 mt-1">
                              <b>If yes, please give details: </b>{Questionaire?.familyHistoryMedicalIfYesPleaseGiveDetail}
                            </div>
                            <div className="col-md-6 mt-1">
                              <b>Are you taking any medications currently?: </b>
                              {Questionaire?.areyoutakinganyMedicationsCurrently === 271400001 ? "No" :
                                Questionaire?.areyoutakinganyMedicationsCurrently === 271400000 ? "Yes" :
                                  null
                              }
                            </div>
                            <div className="col-md-6 mt-1">
                              <b>If yes, please give details: </b>{Questionaire?.takingAnyMedicationsIfYesPleaseGiveDetail}
                            </div>
                            <div className="col-md-6 mt-1">
                              <b>Do you smoke?: </b>
                              {Questionaire?.doYouSmoke === 271400001 ? "No" :
                                Questionaire?.doYouSmoke === 271400000 ? "Yes" :
                                  null
                              }
                            </div>
                            <div className="col-md-6 mt-1">
                              <b>If yes, indicate how many per day: </b>{Questionaire?.smokeIfYesIndicateHowManyPerDay}
                            </div>
                            <div className="col-md-6 mt-1">
                              <b>Do you drink alcohol?: </b>
                              {Questionaire?.doYouDrinkAlcohol === 271400001 ? "No" :
                                Questionaire?.doYouDrinkAlcohol === 271400000 ? "Yes" :
                                  null
                              }
                            </div>
                            <div className="col-md-6 mt-1">
                              <b>If yes, how many unit per week: </b>{Questionaire?.alcoholIfYesHowManyUnitsPerWeek}
                            </div>
                            <div className="col-md-6 mt-1">
                              <b>Do you use recreational drugs?: </b>
                              {Questionaire?.doYouUseReCreationalDrugs === 271400001 ? "No" :
                                Questionaire?.doYouUseReCreationalDrugs === 271400000 ? "Yes" :
                                  null
                              }
                            </div>
                            <div className="col-md-6 mt-1">
                              <b>please give details and frequency of use: </b>{Questionaire?.drugPleaseGiveDetailsandFrequencyofUse}
                            </div>
                            <div className="col-md-6 mt-1">
                              <b>Have you travelled to a Zika or Ebola affected area in the last six months?: </b>
                              {Questionaire?.haveYouTravelledtoaZikaorEbolaAffectedArea === 271400001 ? "No" :
                                Questionaire?.haveYouTravelledtoaZikaorEbolaAffectedArea === 271400000 ? "Yes" :
                                  null
                              }                            
                              </div>
                            <div className="col-md-6 mt-1">
                              <b>If yes, please give details of location and dates: </b>{Questionaire?.zikaIfYesPleaseGiveDetailsofLocationDates}
                            </div>
                            <div className="col-md-6 mt-1">
                              <b>Height in meters: </b>{Questionaire?.heightinMetres}
                            </div>
                            <div className="col-md-6 mt-1">
                              <b>Weight in kilograms: </b>{Questionaire?.weightinKilograms}
                            </div>
                          </div>
                        </div>
                      }
                    </div>
                  }

                  {
                    profileData?.gender === 2 &&
                    <div className=" mb-2 ps-3 pe-3   bgHeader" onClick={() => setAccordian11(!accordian11)}>
                      <div className='card-header mt-2 d-flex row'>
                        <div className='col-md-6'> <h4 className='fs-6  text-start'>Further Information</h4></div>
                        <div className='col-md-6 text-end'>{
                          <i className={`fa fa-${accordian11 ? "minus" : 'plus'}  pointer me-2  fs-4 `} ></i>
                        }

                        </div>
                      </div>
                      {
                        accordian11 &&
                        <div className="card-header bg-white row">
                          <div className='col-xl-2 col-lg-2 col-md-4 col-sm-6 text-start'>
                            {/* <img className='w-50 h-100' src={metadata?.imageLink} width="50" height={10} alt="test" /> */}
                          </div>
                          <div className="row">
                            <div className="col-md-6 mt-1">
                              <b>Do you have any further information you wish to make the clinic aware of?: </b>
                              {Questionaire?.doYouHaveAnyFurtherInformationYouWishtoM === 271400001 ? "No" :
                                Questionaire?.doYouHaveAnyFurtherInformationYouWishtoM === 271400000 ? "Yes" :
                                  null
                              }
                            </div>
                            <div className="col-md-6 mt-1">
                              <b>If yes, please give details: </b>{Questionaire?.furtherInformationIfYesPleaseProvideDetails}
                            </div>
                            <div className="col-md-6 mt-1">
                              <b>How did you hear about us?: </b>{
                                Questionaire?.howDidYouHearAboutUs === 271400000 ? "Medical Referral" :
                                  Questionaire?.howDidYouHearAboutUs === 271400001 ? "Recommendation" :
                                    Questionaire?.howDidYouHearAboutUs === 271400002 ? "Social Media" :
                                      Questionaire?.howDidYouHearAboutUs === 271400003 ? "Radio" :
                                        Questionaire?.howDidYouHearAboutUs === 271400004 ? "Gp" :
                                          Questionaire?.howDidYouHearAboutUs === 271400005 ? "Newspaper or Magazine" :
                                            Questionaire?.howDidYouHearAboutUs === 271400006 ? "Website" :
                                              Questionaire?.howDidYouHearAboutUs === 271400007 ? "Other" : null
                              }
                            </div>

                          </div>
                        </div>
                      }
                    </div>
                  }
                </div>
                <div className="row">
                  {
                    profileData?.areYousureYouwanttosubmit !== true &&
                    <>
                      <div className="col-6">
                        <b>Click to submit and lock your profile</b>    &nbsp;
                        <Button id="" type='submit' className=' btn btn-primary' disabled={profileData?.areYousureYouwanttosubmit === true} onClick={() => Submit(profileData)}>Submit</Button>
                      </div>
                    </>
                  }
                </div>

              </Card.Body>
            </div>
            <div className="col-md-1"></div>
          </Card.Text>
        </Card.Body>
      </Card>
    </>
  )
}
