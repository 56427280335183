import React, { useEffect } from 'react'
import { Card } from 'react-bootstrap'
import { useState } from "react";
import axios from "axios";
import moment from "moment";
import { useDispatch } from "react-redux";
import { setLoader } from "../../Store/Reducers/UserReducer"
import "./DrugReminder.css"
import Swal from 'sweetalert2';
import LoginFun from './../../Layout Component/Loginfun';
import MedicalHistoryNav from "./MedicalHistoryNav";
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';




export default function DrugReminder() {
  const dispatch = useDispatch();
  const url = process.env.REACT_APP_OXAR_API_URL;
  const [data, setdata] = useState([]);
  const logindata = LoginFun();

  const editDrugReminder = (row) => {
    dispatch(setLoader(true));
    let Todaysdate = new Date();
    let Todaysdatetoday = moment(Todaysdate).format('yyyy-MMM-dd');
    row.drugTakingDateTime = moment(data.drugTakingDateTime).format('yyyy-MMM-dd');
    if (row.drugTakingDateTime > Todaysdatetoday) {
      return Swal.fire({ text: "You can't take drug for future." });
    }
    let newdate = new Date();
    row.drugTakenDateTime = newdate;
    if (row?.drugTaken === true) {
      row.drugTaken = false;
    }
    else {
      row.drugTaken = true;
    }
    updateDrugReminder(row);
    dispatch(setLoader(false));
  }



  const updateDrugReminder = async (EditDrugReminder) => {
    try {
      let MainUserId = localStorage.getItem("UserID");
      dispatch(setLoader(true));
      const res = await axios.put(`${url}/api/Prescriptions/UpdateDrugReminder?userId=${MainUserId}`, EditDrugReminder);
      if (res.status === 200) {
        toast.success('Drug Reminder Updated', {
          autoClose: 5000,
          hideProgressBar: true,
          newestOnTop: false,
          closeOnClick: true,
          rtl: false,
          pauseOnFocusLoss: true,
          draggable: true,
          pauseOnHover: true,
          className: 'toast-message'
        }
        )
        getAllDrugReminders();
        dispatch(setLoader(false));
      }
      else {
        toast.error('Error while updating drug reminder', {
          autoClose: 5000,
          hideProgressBar: true,
          newestOnTop: false,
          closeOnClick: true,
          rtl: false,
          pauseOnFocusLoss: true,
          draggable: true,
          pauseOnHover: true,
          theme: "colored"
        })
        dispatch(setLoader(false));
      }
      dispatch(setLoader(false));
    }
    catch (e) {

    }
  }

  useEffect(() => {
    dispatch(setLoader(true));
    setTimeout(() => {
      getAllDrugReminders();
      dispatch(setLoader(false));
    }, 1000);
    sessionCheck();
  }, []);

  const sessionCheck = async () => {
    var currentTime = ''
    currentTime = (Math.round(new Date().getTime() / 1000));
    var Expiretime = localStorage.getItem("ExpirretokenTime");
    if (Expiretime < currentTime) {
      sessionStorage.getItem('isLoggedIn', false)
      Swal.fire({
        title: 'Session Expired',
        text: "Your session has been expired! Please login again",
        icon: 'warning',
        showCancelButton: false,
        confirmButtonText: 'Okay',
      }).then(result => {
        logindata.logout();
      })
    }
  }

  const getAllDrugReminders = async () => {
    try {
      let MainUserId = localStorage.getItem("UserID");
        await axios(`${url}api/Prescriptions/GetDrugReminder?id=${MainUserId}`).then((res) => {
          setdata(res.data.content);
          dispatch(setLoader(false));
        });
    }
    catch {
      dispatch(setLoader(false));
    }
  };

  return (
    <>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <MedicalHistoryNav />
        <Card className="border-0">
          <Card.Body>
            <form>
              <div className="col-md-12 text-left">
                <div className='col-md-6 m-2'>
                </div>
                <div className="table-responsive">
                  <table class="table border-0" id="treatmentCycleTable">
                    <thead >
                      <tr>
                        <th>Drug Name</th>
                        <th>Time</th>
                        <th>Is drug taken? </th>
                      </tr>
                    </thead>
                    <tbody>
                      {data && data.map((listValue, index) => {
                        return (
                          <tr>
                            <td>{listValue.drugName}</td>
                            <td>{moment(listValue.drugTakingDateTime).format("DD-MMM-YYYY")}</td>
                            <td>  <div
                              style={{
                                textAlign: "center",
                                cursor: "pointer",
                                lineHeight: "normal"
                              }}
                            >
                              <label class="switch m-0">
                                <input type="checkbox" id={listValue.drugId} name={listValue.drugId} defaultChecked={listValue.drugTaken === true} onClick={() => editDrugReminder(listValue)} />
                                <div class="slider round">
                                  <span className="on">Yes</span>
                                  <span className="off">No</span>
                                </div>
                              </label>
                            </div></td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              
              </div>
            </form>
          </Card.Body>
        </Card>
      </div>
    </>
  )
}
