

import React, { useLayoutEffect, } from 'react';
import LoginFun from './../../Layout Component/Loginfun';
import Swal from "sweetalert2";
import Allergies from './Allergies';
import $ from "jquery";
import 'react-toastify/dist/ReactToastify.css';

export default function MedicalStep1(props) {
    const regexEmail = /[<>&"]/;
    const { MedicalHistory, setMedicalHistory } = props;
    const logindata = LoginFun();

    useLayoutEffect(() => {
        var currentTime = ''
        currentTime = (Math.round(new Date().getTime() / 1000));
        var Expiretime = localStorage.getItem("ExpirretokenTime");
        if (Expiretime < currentTime) {
            sessionStorage.getItem('isLoggedIn', false)
            Swal.fire({
                title: 'Session Expired',
                text: "Your session has been expired! Please login again",
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: 'Okay',
            }).then(result => {
                logindata.logout();
            })
        }
    }, []);

    const updateMedicalHistory = (datas) => {
        setMedicalHistory(values => ({ ...values, [datas?.target?.name]: datas?.target?.value, 'id': MedicalHistory?.id }));
    }

    return (
        <div className='col-md-12    mt-3  '>
            <div className='  '>
                <div className='row mb-2'>
                    <div className='col-md-6 '>
                        <div className='row '>
                            <div className='col-md-6'>
                                <label> Height in meters <span className="required"></span> </label>
                            </div>
                            <div className='col-md-6'>
                                <input type="number" className="form-control " placeholder="Enter height in meters "
                                    value={MedicalHistory?.heightinMetres}
                                    name='heightinMetres' onChange={(e) => updateMedicalHistory(e)} />
                                {MedicalHistory?.heightinMetres > 3 || MedicalHistory?.heightinMetres < 0 ? <span className='text-danger'> height field in meter should be in 0-3</span> : null
                                }

                            </div>
                        </div>
                    </div>
                    <div className='col-md-6  '>
                        <div className='row '>
                            <div className='col-md-6'>
                                <label>Weight in kilograms <span className="required"></span></label>
                            </div>
                            <div className='col-md-6'>
                                <input type="number" className="form-control "
                                    placeholder="Weight in kilograms"
                                    value={MedicalHistory?.weightinKilograms}
                                    name='weightinKilograms' onChange={(e) => updateMedicalHistory(e)} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row mb-2'>
                    <div className='col-md-6'>
                        <div className='row '>
                            <div className='col-md-6'>
                                <label >Do you have a disability?</label>
                            </div>
                            <div className='col-md-6'>
                                <select className="form-select " id="floatingSelect"
                                    aria-label="Floating label select example"
                                    name='isThePatientDisabled'
                                    defaultValue={MedicalHistory?.isThePatientDisabled}
                                    onChange={(e) => {
                                        if (e.target.value === "271400001") { MedicalHistory.isThePatientDisabledifYesPleaseGiveDetail = "" }
                                        updateMedicalHistory(e)
                                    }}>
                                    {/* <option value="0">--Select--</option> */}
                                    <option>--Select--</option>
                                    <option value='271400001' selected={MedicalHistory?.isThePatientDisabled === 271400001}>No</option>
                                    <option value='271400000' selected={MedicalHistory?.isThePatientDisabled === 271400000}>Yes</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    {(MedicalHistory?.isThePatientDisabled === 271400000 || MedicalHistory?.isThePatientDisabled === '271400000') &&
                        <div className='col-md-6'>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <label >If yes, please give details</label>
                                </div>
                                <div className='col-md-6'>
                                    <input type="text" className="form-control " placeholder="Enter detail" value={MedicalHistory?.isThePatientDisabledifYesPleaseGiveDetail} name='isThePatientDisabledifYesPleaseGiveDetail'
                                        onChange={(e) => { !regexEmail.test(e.target.value) && updateMedicalHistory(e) }} />
                                </div>
                            </div>
                        </div>
                    }
                </div>
                <div className='row mb-2'>
                    <div className='col-md-6'>
                        <div className='row '>
                            <div className='col-md-6'>
                                <label >Have you ever had any sexually transmitted diseases? <span className="required"></span></label>
                            </div>
                            <div className='col-md-6'>
                                <select className="form-select " id="floatingSelect" aria-label="Floating label select example" name='haveYouEverHadAnySexuallyTransmittedDisease' value={MedicalHistory?.haveYouEverHadAnySexuallyTransmittedDisease}
                                    defaultValue={MedicalHistory?.haveYouEverHadAnySexuallyTransmittedDisease} onChange={(e) => {
                                        updateMedicalHistory(e)
                                        if (e.target.value === "271400001") { MedicalHistory.sexuallytransmitteddiseaseIfyesgivedetail = "" }
                                    }}
                                >
                                    <option value={0}>--Select--</option>
                                    <option value="271400001" selected={MedicalHistory?.haveYouEverHadAnySexuallyTransmittedDisease === 271400001}>No</option>
                                    <option value="271400000" selected={MedicalHistory?.haveYouEverHadAnySexuallyTransmittedDisease === 271400000}>Yes</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    {(MedicalHistory?.haveYouEverHadAnySexuallyTransmittedDisease === "271400000" || MedicalHistory?.haveYouEverHadAnySexuallyTransmittedDisease === 271400000) &&
                        <div className='col-md-6'>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <label >If yes, please give details</label>
                                </div>
                                <div className='col-md-6'>
                                    <input type="text" className="form-control " placeholder="Enter detail"
                                        value={MedicalHistory?.sexuallytransmitteddiseaseIfyesgivedetail}
                                        name='sexuallytransmitteddiseaseIfyesgivedetail'
                                        onChange={(e) => { !regexEmail.test(e.target.value) && updateMedicalHistory(e) }} />
                                </div>
                            </div>
                        </div>
                    }
                </div>
                <div className='row mb-2'>
                    <div className='col-md-6'>
                        <div className='row '>
                            <div className='col-md-6'>
                                <label >Do you have any medical conditions? e.g. Asthma, epilepsy, diabetes, anaemia, colitis etc. <span className="required"></span></label>
                            </div>
                            <div className='col-md-6'>
                                <select className="form-select " id="floatingSelect" aria-label="Floating label select example" name='doYouHaveAnyMedicalConditionsEgAsthmae' value={MedicalHistory?.doYouHaveAnyMedicalConditionsEgAsthmae}
                                    defaultValue={MedicalHistory?.doYouHaveAnyMedicalConditionsEgAsthmae} onChange={(e) => {
                                        updateMedicalHistory(e)
                                        if (e.target.value === "271400001") { MedicalHistory.medicalconditionsifyespleaseprovidedetail = "" }
                                    }}>
                                    <option value={0}>--Select--</option>
                                    <option value="271400001" selected={MedicalHistory?.doYouHaveAnyMedicalConditionsEgAsthmae === 271400001}>No</option>
                                    <option value="271400000" selected={MedicalHistory?.doYouHaveAnyMedicalConditionsEgAsthmae === 271400000}>Yes</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    {(MedicalHistory?.doYouHaveAnyMedicalConditionsEgAsthmae === 271400000 || MedicalHistory?.doYouHaveAnyMedicalConditionsEgAsthmae === "271400000") &&
                        <div className='col-md-6'>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <label >If yes, please give details</label>
                                </div>
                                <div className='col-md-6'>
                                    <input type="text" className="form-control " placeholder="Enter detail" name='medicalconditionsifyespleaseprovidedetail'
                                        value={MedicalHistory?.medicalconditionsifyespleaseprovidedetail}
                                        onChange={(e) => { !regexEmail.test(e.target.value) && updateMedicalHistory(e) }} />
                                </div>
                            </div>
                        </div>}
                </div>
                <div className='row mb-2'>
                    <div className='col-md-6'>
                        <div className='row '>
                            <div className='col-md-6'>
                                <label >Do you have a family history of any medical conditions? e.g. cancer, heart disease, genetic disease.</label>
                            </div>
                            <div className='col-md-6'>
                                <select className="form-select " id="floatingSelect" aria-label="Floating label select example" name='doyouHaveaFamilyHistoryofMedicalConditions'
                                    defaultValue={MedicalHistory?.doyouHaveaFamilyHistoryofMedicalConditions}
                                    onChange={(e) => {
                                        updateMedicalHistory(e)
                                        if (e.target.value === "271400001") { MedicalHistory.familyHistoryMedicalIfYesPleaseGiveDetail = "" }
                                    }}>
                                    <option >--Select--</option>
                                    <option value="271400001" selected={MedicalHistory?.doyouHaveaFamilyHistoryofMedicalConditions === 271400001}>No</option>
                                    <option value="271400000" selected={MedicalHistory?.doyouHaveaFamilyHistoryofMedicalConditions === 271400000}>Yes</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    {(MedicalHistory?.doyouHaveaFamilyHistoryofMedicalConditions === 271400000 || MedicalHistory?.doyouHaveaFamilyHistoryofMedicalConditions === "271400000") &&
                        <div className='col-md-6'>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <label >If yes, please give details</label>
                                </div>
                                <div className='col-md-6'>
                                    <input type="text" className="form-control " placeholder="Enter detail"
                                        name='familyHistoryMedicalIfYesPleaseGiveDetail'
                                        value={MedicalHistory?.familyHistoryMedicalIfYesPleaseGiveDetail}
                                        onChange={(e) => { !regexEmail.test(e.target.value) && updateMedicalHistory(e) }} />
                                </div>
                            </div>
                        </div>}
                </div>
                <div className='row mb-2'>
                    <div className='col-md-6'>
                        <div className='row '>
                            <div className='col-md-6'>
                                <label >Are you taking any medications currently? <span className="required"></span></label>
                            </div>
                            <div className='col-md-6'>
                                <select className="form-select " id="floatingSelect" aria-label="Floating label select example"
                                    name='areyoutakinganyMedicationsCurrently' defaultValue={MedicalHistory?.areyoutakinganyMedicationsCurrently}
                                    onChange={(e) => {
                                        updateMedicalHistory(e);
                                        if (e.target.value === "271400001") { MedicalHistory.takingAnyMedicationsIfYesPleaseGiveDetail = "" }
                                    }}>
                                    <option value={0}>--Select--</option>
                                    <option value="271400001" selected={MedicalHistory?.areyoutakinganyMedicationsCurrently === 271400001}>No</option>
                                    <option value="271400000" selected={MedicalHistory?.areyoutakinganyMedicationsCurrently === 271400000}>Yes</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    {(MedicalHistory?.areyoutakinganyMedicationsCurrently === 271400000 || MedicalHistory?.areyoutakinganyMedicationsCurrently === "271400000") &&
                        <div className='col-md-6'>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <label >If yes, please give details</label>
                                </div>
                                <div className='col-md-6'>
                                    <input type="text" className="form-control" placeholder="Enter detail" name='takingAnyMedicationsIfYesPleaseGiveDetail'
                                        value={MedicalHistory?.takingAnyMedicationsIfYesPleaseGiveDetail} onChange={(e) => { !regexEmail.test(e.target.value) && updateMedicalHistory(e) }} />
                                </div>
                            </div>
                        </div>}
                </div>
                <div className='row mb-2'>
                    <div className='col-md-6'>
                        <div className='row '>
                            <div className='col-md-6'>
                                <label >Do you smoke? <span className="required"></span> </label>
                            </div>
                            <div className='col-md-6'>
                                <select className="form-select " id="floatingSelect" name='doYouSmoke'
                                    aria-label="Floating label select example" defaultValue={MedicalHistory?.doYouSmoke}
                                    onChange={(e) => {
                                        updateMedicalHistory(e)
                                        if (e.target.value === "271400001") { MedicalHistory.smokeIfYesIndicateHowManyPerDay = "" }
                                    }}>
                                    <option value={0}>--Select--</option>
                                    <option value="271400001" selected={MedicalHistory?.doYouSmoke === 271400001}>No</option>
                                    <option value="271400000" selected={MedicalHistory?.doYouSmoke === 271400000}>Yes</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    {(MedicalHistory?.doYouSmoke === 271400000 || MedicalHistory?.doYouSmoke === "271400000") &&
                        <div className='col-md-6'>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <label >If yes, indicate how many per day</label>
                                </div>
                                <div className='col-md-6'>
                                    <input type="text" className="form-control "
                                        placeholder="Enter how many per day"
                                        name='smokeIfYesIndicateHowManyPerDay'
                                        value={MedicalHistory?.smokeIfYesIndicateHowManyPerDay}
                                        onChange={(e) => { !regexEmail.test(e.target.value) && updateMedicalHistory(e) }} />
                                </div>
                            </div>
                        </div>}
                </div>
                <div className='row mb-2'>
                    <div className='col-md-6'>
                        <div className='row '>
                            <div className='col-md-6'>
                                <label >Do you drink alcohol? <span className="required"></span> </label>
                            </div>
                            <div className='col-md-6'>
                                <select className="form-select " id="floatingSelect"
                                    aria-label="Floating label select example"
                                    name='doYouDrinkAlcohol'
                                    defaultValue={MedicalHistory?.doYouDrinkAlcohol}
                                    onChange={(e) => {
                                        updateMedicalHistory(e);
                                        if (e.target.value === "271400001") { MedicalHistory.alcoholIfYesHowManyUnitsPerWeek = "" }
                                    }}>
                                    <option value={0}>--Select--</option>
                                    <option value="271400001" selected={MedicalHistory?.doYouDrinkAlcohol === 271400001}>No</option>
                                    <option value="271400000" selected={MedicalHistory?.doYouDrinkAlcohol === 271400000}>Yes</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    {(MedicalHistory?.doYouDrinkAlcohol === 271400000 || MedicalHistory?.doYouDrinkAlcohol === "271400000") &&
                        <div className='col-md-6'>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <label >If yes, how many unit per week</label>
                                </div>
                                <div className='col-md-6'>
                                    <input type="text" className="form-control " placeholder="Enter how many unit per week"
                                        value={MedicalHistory?.alcoholIfYesHowManyUnitsPerWeek}
                                        name='alcoholIfYesHowManyUnitsPerWeek'
                                        onChange={(e) => { !regexEmail.test(e.target.value) && updateMedicalHistory(e) }} />
                                </div>
                            </div>
                        </div>
                    }
                </div>
                <div className='row mb-2'>
                    <div className='col-md-6'>
                        <div className='row '>
                            <div className='col-md-6'>
                                <label >Do you use recreational drugs? <span className="required"></span></label>
                            </div>
                            <div className='col-md-6'>
                                <select className="form-select " id="floatingSelect"
                                    aria-label="Floating label select example"
                                    name='doYouUseReCreationalDrugs' defaultValue={MedicalHistory?.doYouUseReCreationalDrugs}
                                    onChange={(e) => {
                                        updateMedicalHistory(e)
                                        if (e.target.value === "271400001") { MedicalHistory.drugPleaseGiveDetailsandFrequencyofUse = "" }
                                    }}>
                                    <option value={0}>--Select--</option>
                                    <option value="271400001" selected={MedicalHistory?.doYouUseReCreationalDrugs === 271400001}>No</option>
                                    <option value="271400000" selected={MedicalHistory?.doYouUseReCreationalDrugs === 271400000}>Yes</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    {(MedicalHistory?.doYouUseReCreationalDrugs === 271400000 || MedicalHistory?.doYouUseReCreationalDrugs === "271400000") &&
                        <div className='col-md-6'>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <label >Please give details and frequency of use</label>
                                </div>
                                <div className='col-md-6'>
                                    <input type="text" className="form-control"
                                        placeholder="Enter details and frequency of use "
                                        name='drugPleaseGiveDetailsandFrequencyofUse' value={MedicalHistory?.drugPleaseGiveDetailsandFrequencyofUse}
                                        onChange={(e) => { !regexEmail.test(e.target.value) && updateMedicalHistory(e) }} />
                                </div>
                            </div>
                        </div>
                    }
                </div>
                <div className='row mb-2'>
                    <div className='col-md-6'>
                        <div className='row '>
                            <div className='col-md-6'>
                                <label >Have you travelled to a Zika or Ebola affected area in the last six months?</label>
                            </div>
                            <div className='col-md-6'>
                                <select className="form-select " id="floatingSelect"
                                    aria-label="Floating label select example"
                                    name='haveYouTravelledtoaZikaorEbolaAffectedArea'
                                    defaultValue={MedicalHistory?.haveYouTravelledtoaZikaorEbolaAffectedArea}
                                    onChange={(e) => {
                                        updateMedicalHistory(e)
                                        if (e.target.value === "271400001") { MedicalHistory.zikaIfYesPleaseGiveDetailsofLocationDates = "" }
                                    }}>
                                    <option >--Select--</option>
                                    <option value="271400001" selected={MedicalHistory?.haveYouTravelledtoaZikaorEbolaAffectedArea === 271400001}>No</option>
                                    <option value="271400000" selected={MedicalHistory?.haveYouTravelledtoaZikaorEbolaAffectedArea === 271400000}>Yes</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    {(MedicalHistory?.haveYouTravelledtoaZikaorEbolaAffectedArea === 271400000 || MedicalHistory?.haveYouTravelledtoaZikaorEbolaAffectedArea === "271400000") &&
                        <div className='col-md-6'>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <label >If yes, please give details of location and dates</label>
                                </div>
                                <div className='col-md-6'>
                                    <input type="text" className="form-control"
                                        placeholder="Enter details of location and dates"
                                        name='zikaIfYesPleaseGiveDetailsofLocationDates'
                                        value={MedicalHistory?.zikaIfYesPleaseGiveDetailsofLocationDates} onChange={(e) => { !regexEmail.test(e.target.value) && updateMedicalHistory(e) }} />
                                </div>
                            </div>
                        </div>
                    }
                </div>
                <div className='row mb-2'>
                    <div className='col-md-6'>
                        <div className='row '>
                            <div className='col-md-6'>
                                <label >Have you had fertility investigations in the past? <span className="required"></span></label>
                            </div>
                            <div className='col-md-6   '>
                                <select className="form-select " id="floatingSelect" aria-label="Floating label select example"
                                    name='haveYouHadFertilityInvestigationsinthePast'
                                    value={MedicalHistory?.haveYouHadFertilityInvestigationsinthePast}
                                    onChange={(e) => {
                                        updateMedicalHistory(e)
                                        if (e.target.value === "271400001") { MedicalHistory.fertilityInvesifYesPleaseGiveDetailsDates = "" }
                                    }}>
                                    <option value={0}>--Select--</option>
                                    <option value="271400001" selected={MedicalHistory?.haveYouHadFertilityInvestigationsinthePast === 271400001}>No</option>
                                    <option value="271400000" selected={MedicalHistory?.haveYouHadFertilityInvestigationsinthePast === 271400000}>Yes</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    {(MedicalHistory?.haveYouHadFertilityInvestigationsinthePast === 271400000 || MedicalHistory?.haveYouHadFertilityInvestigationsinthePast === "271400000") &&
                        <div className='col-md-6'>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <label >If yes, please give details, dates and results</label>
                                </div>
                                <div className='col-md-6'>
                                    <input type="text" className="form-control"
                                        placeholder="Enter details, dates and results" name='fertilityInvesifYesPleaseGiveDetailsDates'
                                        value={MedicalHistory?.fertilityInvesifYesPleaseGiveDetailsDates}
                                        onChange={(e) => { !regexEmail.test(e.target.value) && updateMedicalHistory(e) }} />
                                </div>
                            </div>
                        </div>
                    }
                </div>
                <div className='row mb-2'>
                    <div className='col-md-6 '>
                        <div className='row '>
                            <div className='col-md-6'>
                                <label >Do you have any further information you wish to make the clinic aware of?</label>
                            </div>
                            <div className='col-md-6'>
                                <select className="form-select " id="floatingSelect"
                                    aria-label="Floating label select example" name='doYouHaveAnyFurtherInformationYouWishtoM'
                                    defaultValue={MedicalHistory?.doYouHaveAnyFurtherInformationYouWishtoM}
                                    onChange={(e) => {
                                        updateMedicalHistory(e)
                                        if (e.target.value === "271400001") { MedicalHistory.furtherInformationIfYesPleaseProvideDetails = "" }
                                    }}>
                                    <option>--Select--</option>
                                    <option value="271400001" selected={MedicalHistory?.doYouHaveAnyFurtherInformationYouWishtoM === 271400001}> No</option>
                                    <option value="271400000" selected={MedicalHistory?.doYouHaveAnyFurtherInformationYouWishtoM === 271400000}>Yes</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    {(MedicalHistory?.doYouHaveAnyFurtherInformationYouWishtoM === 271400000 || MedicalHistory?.doYouHaveAnyFurtherInformationYouWishtoM === '271400000') &&
                        <div className='col-md-6'>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <label >If yes, please give details</label>
                                </div>
                                <div className='col-md-6'>
                                    <input type="text" className="form-control "
                                        placeholder="Enter Detail"
                                        name='furtherInformationIfYesPleaseProvideDetails'
                                        value={MedicalHistory?.furtherInformationIfYesPleaseProvideDetails}
                                        onChange={(e) => { !regexEmail.test(e.target.value) && updateMedicalHistory(e) }} />
                                </div>
                            </div>
                        </div>

                    }
                </div>
                <div className='row mb-2'>
                    <div className='col-md-6'>
                        <div className='row '>
                            <div className='col-md-6'>
                                <label >Have you ever had assisted conception treatment before? e.g. Ovulation Induction, IUI, IVF, ICSI
                                    <span className="required"></span>
                                </label>
                            </div>
                            <div className='col-md-6'>
                                <select className="form-select " id="floatingSelect" name='haveYouEverHadAssistedConceptionTreatment' aria-label="Floating label select example"
                                    defaultValue={MedicalHistory?.haveYouEverHadAssistedConceptionTreatment}
                                    onChange={(e) => updateMedicalHistory(e)}>
                                    <option value={0}>--Select--</option>
                                    <option value="271400001" selected={MedicalHistory?.haveYouEverHadAssistedConceptionTreatment === 271400001} >No</option>
                                    <option value="271400000" selected={MedicalHistory?.haveYouEverHadAssistedConceptionTreatment === 271400000}>Yes</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    {(MedicalHistory?.haveYouEverHadAssistedConceptionTreatment === 271400000 || MedicalHistory?.haveYouEverHadAssistedConceptionTreatment === '271400000') &&
                        <div className='col-md-6'>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <label >Was the treatment with the partner you are seeking treatment with?</label>
                                </div>
                                <div className='col-md-6'>
                                    <select className="form-select " id="floatingSelect"
                                        aria-label="Floating label select example"
                                        name='wasTheTreatmentWiththePartnerYouAreSeeking'
                                        value={MedicalHistory?.wasTheTreatmentWiththePartnerYouAreSeeking}
                                        onChange={(e) => updateMedicalHistory(e)} >
                                        <option >--Select--</option>
                                        <option value="271400001" selected={MedicalHistory?.wasTheTreatmentWiththePartnerYouAreSeeking === 271400001}>No</option>
                                        <option value="271400000" selected={MedicalHistory?.wasTheTreatmentWiththePartnerYouAreSeeking === 271400000}>Yes</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    }
                </div>

                <div className='row mb-2'>
                    <div className='col-md-6'>
                        <div className='row '>
                            <div className='col-md-6'>
                                <label >Do you have any frozen eggs, sperm or embryos in storage elsewhere?</label>
                            </div>
                            <div className='col-md-6'>
                                <select className="form-select " id="floatingSelect" aria-label="Floating label select example"
                                    name='doYouHaveAnyFrozenEggsSpermOrEmbryosin'
                                    defaultValue={MedicalHistory?.doYouHaveAnyFrozenEggsSpermOrEmbryosin}
                                    onChange={(e) => {
                                        if (e.target.value === "271400000" || e.target.value === "271400005") { MedicalHistory.eggsspermorembryosstoredifyespleasegive = "" }
                                        updateMedicalHistory(e);
                                    }}>
                                    <option >--Select--</option>
                                    <option value="271400001" selected={MedicalHistory?.doYouHaveAnyFrozenEggsSpermOrEmbryosin === 271400001}>Partner Eggs</option>
                                    <option value="271400002" selected={MedicalHistory?.doYouHaveAnyFrozenEggsSpermOrEmbryosin === 271400002} >Sperm</option>
                                    <option value="271400003" selected={MedicalHistory?.doYouHaveAnyFrozenEggsSpermOrEmbryosin === 271400003}>Donor sperm</option>
                                    <option value="271400004" selected={MedicalHistory?.doYouHaveAnyFrozenEggsSpermOrEmbryosin === 271400004}>Embryos</option>
                                    <option value="271400000" selected={MedicalHistory?.doYouHaveAnyFrozenEggsSpermOrEmbryosin === 271400000}>No</option>
                                    <option value="271400005" selected={MedicalHistory?.doYouHaveAnyFrozenEggsSpermOrEmbryosin === 271400005}>Own Eggs</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    {(MedicalHistory?.doYouHaveAnyFrozenEggsSpermOrEmbryosin === 271400001 || MedicalHistory?.doYouHaveAnyFrozenEggsSpermOrEmbryosin === 271400002 ||
                        MedicalHistory?.doYouHaveAnyFrozenEggsSpermOrEmbryosin === 271400003 || MedicalHistory?.doYouHaveAnyFrozenEggsSpermOrEmbryosin === 271400004
                        || MedicalHistory?.doYouHaveAnyFrozenEggsSpermOrEmbryosin === "271400001" || MedicalHistory?.doYouHaveAnyFrozenEggsSpermOrEmbryosin === "271400002" ||
                        MedicalHistory?.doYouHaveAnyFrozenEggsSpermOrEmbryosin === "271400003" || MedicalHistory?.doYouHaveAnyFrozenEggsSpermOrEmbryosin === "271400004")
                        &&
                        <div className='col-md-6'>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <label >If yes, please give details</label>
                                </div>
                                <div className='col-md-6'>
                                    <input type="text" className="form-control "
                                        placeholder="Enter Detail"
                                        name='eggsspermorembryosstoredifyespleasegive'
                                        value={MedicalHistory?.eggsspermorembryosstoredifyespleasegive} onChange={(e) => { !regexEmail.test(e.target.value) && updateMedicalHistory(e) }} />
                                </div>
                            </div>
                        </div>
                    }
                </div>
                <div className='row mb-2'>
                    <div className='col-md-6'>
                        <div className='row '>
                            <div className='col-md-6'>
                                <label >Reason for contacting</label>
                            </div>
                            <div className='col-md-6'>
                                <select className="form-select " id="floatingSelect" aria-label="Floating label select example"
                                    name='reasonForContacting'
                                    defaultValue={MedicalHistory?.reasonForContacting}
                                    onChange={(e) => updateMedicalHistory(e)} >
                                    <option >--- Select ---</option>
                                    <option value="271400000" selected={MedicalHistory?.reasonForContacting === 271400000}>PGS (PGT-A) Pre Implantation Genetic Screening</option>
                                    <option value="0" selected={MedicalHistory?.reasonForContacting === 0}>Primary infertility (no pregnancies)</option>
                                    <option value="1" selected={MedicalHistory?.reasonForContacting === 1}>Secondary infertility (previous pregnancies in current relationship)</option>
                                    <option value="2" selected={MedicalHistory?.reasonForContacting === 2}>Secondary infertility (previous pregnancies in a different relationship)</option>
                                    <option value="3" selected={MedicalHistory?.reasonForContacting === 3}>Recurrent Miscarriage</option>
                                    <option value="4" selected={MedicalHistory?.reasonForContacting === 4}>Single person</option>
                                    <option value="5" selected={MedicalHistory?.reasonForContacting === 5}>Same sex couple</option>
                                    <option value="6" selected={MedicalHistory?.reasonForContacting === 6}>Fertility preservation due to medical reasons</option>
                                    <option value="7" selected={MedicalHistory?.reasonForContacting === 7}>Fertility preservation for social reasons</option>
                                    <option value="8" selected={MedicalHistory?.reasonForContacting === 8}>PGD to reduce risk of passing on genetic disorder</option>
                                    <option value="9" selected={MedicalHistory?.reasonForContacting === 9}>To be an egg donor</option>
                                    <option value="10" selected={MedicalHistory?.reasonForContacting === 10}>Egg recipient ( to receive donated eggs)</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className='row'>
                            <div className='col-md-6'>
                                <label >How did you hear about us?</label>
                            </div>
                            <div className='col-md-6'>
                                <select className="form-select " id="floatingSelect" aria-label="Floating label select example"
                                    defaultValue={MedicalHistory?.howDidYouHearAboutUs}
                                    name='howDidYouHearAboutUs'
                                    onChange={(e) => updateMedicalHistory(e)} >
                                    <option >--- Select ---</option>
                                    <option value="271400000" selected={MedicalHistory?.howDidYouHearAboutUs === 271400000}>Medical Referral</option>
                                    <option value="271400001" selected={MedicalHistory?.howDidYouHearAboutUs === 271400001}>Recommendation</option>
                                    <option value="271400002" selected={MedicalHistory?.howDidYouHearAboutUs === 271400002}>Social Media</option>
                                    <option value="271400003" selected={MedicalHistory?.howDidYouHearAboutUs === 271400003}>Radio</option>
                                    <option value='271400004' selected={MedicalHistory?.howDidYouHearAboutUs === 271400004}>GP</option>
                                    <option value='271400005' selected={MedicalHistory?.howDidYouHearAboutUs === 271400005}>Newspaper or Magazine</option>
                                    <option value='271400006' selected={MedicalHistory?.howDidYouHearAboutUs === 271400006}>Website</option>
                                    <option value='271400007' selected={MedicalHistory?.howDidYouHearAboutUs === 271400007}>Other</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row mb-2'>
                    <div className='col-md-6'>
                        <div className='row '>
                            <div className='col-md-6'>
                                <label >Systolic / Diastolic Pressure</label>
                            </div>
                            <div className='col-md-6 d-flex'>
                                <input type="text" className="form-control w-50" placeholder="Systolic"
                                    value={MedicalHistory?.systolic} name='systolic'
                                    onChange={(e) => { !regexEmail.test(e.target.value) && updateMedicalHistory(e) }} />
                                <span className='border p-1 ps-3 pe-3'>/</span>
                                <input type="text" className="form-control w-50"
                                    placeholder="Diastolic" value={MedicalHistory?.diastolic}
                                    name='diastolic'
                                    onChange={(e) => { !regexEmail.test(e.target.value) && updateMedicalHistory(e) }} />
                            </div>
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className='row '>
                            <div className='col-md-6'>
                                <label >Are you allergic to any medication?</label>
                            </div>
                            <div className='col-md-6'>
                                <select className="form-select " id="floatingSelect" name='areYouAllergicToAnyMedication' aria-label="Floating label select example"
                                    defaultValue={MedicalHistory?.areYouAllergicToAnyMedication}
                                    onChange={(e) => { updateMedicalHistory(e); }}>
                                    <option >--Select--</option>
                                    <option value="271400001" selected={MedicalHistory?.areYouAllergicToAnyMedication === 271400001} >No</option>
                                    <option value="271400000" selected={MedicalHistory?.areYouAllergicToAnyMedication === 271400000}>Yes</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                {(MedicalHistory?.areYouAllergicToAnyMedication === 271400000 || MedicalHistory?.areYouAllergicToAnyMedication === "271400000") &&
                    <div className="col-md-12">
                        <Allergies checkTest={1} />
                    </div>}
            </div>
            <br></br>
        </div>
    )
}