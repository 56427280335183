import axios from "axios";
import React, { useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import 'react-phone-number-input/style.css';
import PlacesAutocomplete, {
    geocodeByAddress
} from "react-places-autocomplete";
import "./Register.css";
import { useNavigate } from "react-router";
import $ from "jquery";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { GettheHmToken } from "./services/AllApiCall";
import { ErrorToast, WarnToast } from "./Custom Component/Toast";

export default function Register() {

    const checkSpecial = (e) => { if (/[<>&"]/.test(e.key)) e.preventDefault(); }

    //state for switch button content 
    const [content, setcontent] = useState(false);
    //state for address lookup field
    const [address, setAddress] = React.useState("");
    const [addressGP, setaddress1] = React.useState("");
    const [addressPGP, setaddressPGP] = React.useState("");
    //state for address to,locate at different fileds
    const [street, setStreet] = React.useState("");
    const [houseno, sethouseno] = React.useState("");
    const [country, setcountry] = React.useState("");
    const [city, setcity] = React.useState("");
    const [stat, setstate] = React.useState("");
    const [postal, setpostal] = React.useState("");

    //state for address to,locate at different fileds
    const [street1, setStreet1] = React.useState("");
    const [country1, setcountry1] = React.useState("");
    const [city1, setcity1] = React.useState("");
    const [state1, setstate1] = React.useState("");
    const [postal1, setpostal1] = React.useState("");
    const [county, setcounty] = React.useState("");


    // partner GP address
    const [streetPGP, setStreetPGP] = React.useState("");
    const [countryPGP, setcountryPGP] = React.useState("");
    const [cityPGP, setcityPGP] = React.useState("");
    const [statePGP, setstatePGP] = React.useState("");
    const [postalPGP, setpostalPGP] = React.useState("");


    const [checkstreet, getStreet] = React.useState("");
    const [checkhouseno, gethouseno] = React.useState("");
    const [checkcountry, getcountry] = React.useState("");
    const [checkcity, getcity] = React.useState("");
    const [checkstate, getstate] = React.useState("");
    const [checkpostal, getpostal] = React.useState("");
    const [checkcounty, getcounty] = React.useState("");
    //state for partner GP autofill with patient gp
    // state for form field to submit
    const url = process.env.REACT_APP_API_URL;
    const [mainInterest, setmainInterest] = React.useState("");
    const [tiTitletle, setTitle] = React.useState("");
    const [firstName, setfirstName] = React.useState("");
    const [lastName, setlastName] = React.useState("");
    const [emailaddress1, setemailaddress1] = React.useState("");
    const [gendercode, setgendercode] = React.useState("");
    const [bcrm_whatgenderdoyouidentifiedas, setbcrm_whatgenderdoyouidentifiedas] = React.useState("");
    const [birthdate, setbirthdate] = React.useState("");
    const [mobilephone, setmobilephone] = React.useState("");
    const [homePhone, sethomePhone] = React.useState("");
    const [businessPhone, setbusinessPhone] = React.useState("");
    const [patientFunding, setpatientFunding] = React.useState("");
    const [nhsNumber, setnhsNumber] = React.useState("");
    const [previousTreatment, setpreviousTreatment] = React.useState("");
    const [patientGroups, setpatientGroups] = React.useState("");
    const [GPName, setGPName] = React.useState("");
    const [PatientGPSurgeryName, setPatientGPSurgeryName] = React.useState("");
    const [PName, setPName] = React.useState("");
    const [PLName, setPLName] = React.useState("");
    const [Pdob, setPdob] = React.useState("");
    const [PGender, setPGender] = React.useState("");
    const [PREfered, setPREfered] = React.useState("");
    const [PEmail, setPEmail] = React.useState("");
    const [PartnerAddressSameasPatient] = React.useState("");
    const navigate = useNavigate();
    //mail password authentication
    const [LeadType, setLeadType] = React.useState("");
    const [LeadSource, setLeadSource] = React.useState("");
    const [passwordType, setPasswordType] = useState("password");
    //partner's GP
    const [checkPartnerGPName, getcheckPartnerGPName] = React.useState("");
    const [checkPartnerGPSurgeryName, getcheckPartnerGPSurgeryName] = React.useState("");
    const [PatientFundingData, setPatientFundingData] = useState([]);
    const [patientFundingNew, setpatientFundingNew] = React.useState("");
    const [LeadSourceData, setLeadSourceData] = useState([]);
    const [LeadSourceNew, setLeadSourceNew] = React.useState("");
    const [ox_patient_rx_group, setox_patient_rx_group] = React.useState("");
    const [isLoading, setIsLoading] = useState(false);

    const { useEffect } = React;
    useEffect(() => {
        GettheHmToken();
        GetPatientFunding();
        GetLeadSource();
    }, []);

    const options = [
        { name: "ART", id: 1 },
        { name: "CR Egg Donor", id: 2 },
        { name: "RR Egg Donor", id: 3 },
        { name: "Egg Bank Donor", id: 4 },
        { name: "CR Embryo Donor", id: 5 },
        { name: "RR Embryo Donor", id: 6 },
        { name: "CR Egg Recipient", id: 7 },
        { name: "RR Egg Recipient", id: 8 },
        { name: "Egg Bank Recipient", id: 9 },
        { name: "RR Embryo Recipient", id: 10 },
        { name: "CR Embryo Recipient", id: 11 },
        { name: "CR Sperm Donor", id: 12 },
        { name: "RR Sperm Donor", id: 13 },
        { name: "Sperm Bank Donor", id: 14 },
        { name: "CR Sperm Recipient", id: 15 },
        { name: "RR Sperm Recipient", id: 16 },
        { name: "Sperm Bank Recipient", id: 17 },
        { name: "CP Own Gametes", id: 18 },
        { name: "CP - CR Egg Recipient", id: 19 },
        { name: "CP - RR Egg Recipient", id: 20 },
        { name: "CP - Egg Bank Recipient", id: 22 },
        { name: "CP - CR Sperm Recipient", id: 23 },
        { name: "CP - RR Sperm Recipient", id: 24 },
        { name: "CP - Sperm Bank Recipient", id: 25 },
        { name: "CP - CR Embryo Recipient", id: 26 },
        { name: "CP - RR Embryo Recipient", id: 27 },
        { name: "CP - Multi Source", id: 28 },
        { name: "Surrogate", id: 29 },
        { name: "CR Egg and RR Sperm Recipient", id: 30 },
        { name: "RR Egg and RR Sperm Recipient", id: 31 },
        { name: "Egg Bank and RR Sperm Recipient", id: 32 },
        { name: "CR Egg and Sperm Bank Recipient", id: 33 },
        { name: "RR Egg and Sperm Bank Recipient", id: 34 },
        { name: "Egg Bank and Sperm Bank Recipient", id: 35 },
        { name: "CR Egg and CR Sperm Recipient", id: 36 },
        { name: "RR Egg and CR Sperm Recipient", id: 37 },
        { name: "Egg Bank and CR Sperm Recipient", id: 38 },
        { name: "Egg Sharing", id: 39 },
        { name: "TI", id: 40 },
        { name: "AI", id: 41 },
        { name: "DI", id: 42 },
        { name: "PP Pathology", id: 43 },
        { name: "PP Clinic", id: 44 },
        { name: "PP Ultrasound", id: 45 },
        { name: "PP Genetics", id: 46 },
        { name: "PP Donor", id: 47 },
        { name: "PP Counselling", id: 48 },
        { name: "PP Gynae", id: 49 },
        { name: "Pre-ART", id: 50 },
    ];

    const [items, setItems] = useState([]);

    const setdata = (e) => {
        if (e === true) {
            gethouseno(houseno);
            $("#floatingInputGrid22").val(houseno);
            getStreet(street);
            $("#floatingInputGrid23").val(street);
            getcountry(country);
            $("#country_Region").val(country);
            getcity(city);
            $("#floatingInputGrid24").val(city);
            getstate(stat)
            $("#floatingInputGrid25").val(stat);
            getpostal(postal);
            $("#floatingInputGrid26").val(postal);
            getcounty(county);
            $("#floatingInputGridcounty").val(county);
        } else {
            gethouseno("");
            $("#floatingInputGrid22").val("");
            getStreet("");
            $("#floatingInputGrid23").val("");
            getcountry("");
            $("#country_Region").val("");
            getcity("");
            $("#floatingInputGrid24").val("");
            getstate("")
            $("#floatingInputGrid25").val("");
            getpostal("");
            $("#floatingInputGrid26").val("");
            getcounty("");
            $("#floatingInputGridcounty").val("");
        }
    }
    const setGPData = (e) => {
        if (e === true) {
            setStreetPGP(street1);
            $("#floatingInputGrid30").val(street1);
            setcountryPGP(country1);
            $("#floatingInputGrid33").val(country1);
            setcityPGP(city1);
            $("#floatingInputGrid31").val(city1);
            setstatePGP(state1);
            $("#floatingInputGrid32").val(state1);
            setpostalPGP(postal1);
            $("#floatingInputGrid34").val(postal1);
            setaddressPGP(addressGP);
            getcheckPartnerGPName(GPName)
            $("#floatingInputGrid28").val(GPName);
            getcheckPartnerGPSurgeryName(PatientGPSurgeryName)
            $("#floatingInputGrid29").val(PatientGPSurgeryName);
        } else {
            setStreetPGP("");
            $("#floatingInputGrid30").val("");
            setcountryPGP("");
            $("#floatingInputGrid33").val("");
            setcityPGP("");
            $("#floatingInputGrid31").val("");
            setstatePGP("");
            $("#floatingInputGrid32").val("");
            setpostalPGP("");
            $("#floatingInputGrid34").val("");
            setaddressPGP("");
            getcheckPartnerGPName("");
            $("#floatingInputGrid28").val("");
            getcheckPartnerGPSurgeryName("");
            $("#floatingInputGrid29").val("");
        }
    }

    // First Patient My Address Lookup Lookup //
    const handleSelect = async value => {

        const results = await geocodeByAddress(value);
        const setAddressvalue2 = results[0].address_components;
        var houseno1 = "";
        var Street1 = "";
        var country1 = "";
        var city1 = "";
        var State1 = "";
        var postalCode1 = "";
        setAddressvalue2.map((elm, i) => {
            if (elm.types[0] === "street_number") {
                sethouseno(setAddressvalue2[i].short_name);
                houseno1 = setAddressvalue2[i].short_name;
                $("#floatingInputGrid1").val(houseno1);
            }
            if (houseno1 === undefined || houseno1 === "") {
                sethouseno("");
                $("#floatingInputGrid1").val("");
            }
            if (elm.types[0] === "route") {
                setStreet(setAddressvalue2[i].short_name);
                Street1 = setAddressvalue2[i].short_name;
                $("#floatingInputGrid2").val(Street1);
            }
            if (Street1 === undefined || Street1 === "") {
                setStreet("");
                $("#floatingInputGrid2").val("");
            }
            if (elm.types[0] === "locality") {
                setcity(setAddressvalue2[i].long_name);
                city1 = setAddressvalue2[i].long_name;
                $("#floatingInputGrid3").val(city1);
            }
            if (city1 === undefined || city1 === "") {
                setcity("");
                $("#floatingInputGrid3").val("");
            }
            if (elm.types[0] === "administrative_area_level_1") {
                setstate(setAddressvalue2[i].long_name);
                State1 = setAddressvalue2[i].long_name
                $("#floatingInputGrid5").val(State1);
            }
            if (State1 === undefined || State1 === "") {
                setstate("");
                $("#floatingInputGrid5").val("");
            }
            if (elm.types[0] === "postal_code") {
                setpostal(setAddressvalue2[i].long_name);
                postalCode1 = setAddressvalue2[i].long_name;
                $("#floatingInputGrid6").val(postalCode1);
            }
            if (postalCode1 === undefined || postalCode1 === "") {
                setpostal("");
                $("#floatingInputGrid6").val("");
            }
            if (elm.types[0] === "country") {
                setcountry(setAddressvalue2[i].long_name);
                country1 = setAddressvalue2[i].long_name;
                $("#floatingInputGrid4").val(country1);
            }
            if (country1 === undefined || country1 === "") {
                setcountry("");
                $("#floatingInputGrid4").val("");
            }
            $("#floatingInputGrid7").val("");
            setcounty("");
            return true
        })
        setAddress(value);
    }
    // Second patient My GP information Lokkup//
    const handleSelect1 = async value => {
        const results = await geocodeByAddress(value);
        const setAddressvalue = results[0].address_components;
        var houseno2 = "";
        var Street2 = "";
        var country2 = "";
        var city2 = "";
        var State2 = "";
        var postalCode2 = "";
        setAddressvalue.map((elm, i) => {
            if (elm.types[0] === "street_number") {
                houseno2 = setAddressvalue[i].short_name;
            }
            if (houseno2 === undefined || houseno2 === "") {
                houseno2 = "";
            }
            if (elm.types[0] === "route") {
                Street2 = setAddressvalue[i].short_name
                setStreet1(houseno2 + " " + setAddressvalue[i].short_name);
                $("#floatingInputGrid11").val(houseno2 + " " + Street2);
            }
            if (Street2 === undefined || Street2 === "") {
                setStreet1(houseno2 + "");
                $("#floatingInputGrid11").val(houseno2 + "");
            }
            if (elm.types[0] === "locality") {
                setcity1(setAddressvalue[i].long_name);
                city2 = setAddressvalue[i].long_name;
                $("#floatingInputGrid12").val(city2);
            }
            if (city2 === undefined || city2 === "") {
                setcity1("");
                $("#floatingInputGrid12").val("");
            }
            if (elm.types[0] === "administrative_area_level_1") {
                setstate1(setAddressvalue[i].long_name);
                State2 = setAddressvalue[i].long_name;
                $("#floatingInputGrid13").val(State2);
            }
            if (State2 === undefined || State2 === "") {
                setstate1("");
                $("#floatingInputGrid13").val("");
            }
            if (elm.types[0] === "postal_code") {
                setpostal1(setAddressvalue[i].long_name);
                postalCode2 = setAddressvalue[i].long_name;
                $("#floatingInputGrid15").val(postalCode2);
            }
            if (postalCode2 === undefined || postalCode2 === "") {
                setpostal1("");
                $("#floatingInputGrid15").val("");
            }
            if (elm.types[0] === "country") {
                setcountry1(setAddressvalue[i].long_name);
                country2 = setAddressvalue[i].long_name;
                $("#floatingInputGrid14").val(country2);
            }
            if (country2 === undefined || country2 === "") {
                setcountry1("");
                $("#floatingInputGrid14").val("");
            }
            return true
        })
        setaddress1(value);
    }
    const handleSelectPGP = async value => {
        const results = await geocodeByAddress(value);
        const setAddressvalue = results[0].address_components;
        var houseno3 = "";
        var Street3 = "";
        var country3 = "";
        var city3 = "";
        var State3 = "";
        var postalCode3 = "";
        setAddressvalue.map((elm, i) => {
            if (elm.types[0] === "street_number") {
                houseno3 = setAddressvalue[i].short_name;
            }
            if (houseno3 === undefined || houseno3 === "") {
                houseno3 = "";
            }
            if (elm.types[0] === "route") {
                Street3 = setAddressvalue[i].short_name
                setStreetPGP(houseno3 + " " + setAddressvalue[i].short_name);
                $("#floatingInputGrid30").val(houseno3 + " " + setAddressvalue[i].short_name);
            }
            if (Street3 === undefined || Street3 === "") {
                setStreetPGP(houseno3 + "");
                $("#floatingInputGrid30").val(houseno3 + "");
            }
            if (elm.types[0] === "administrative_area_level_1") {
                setstatePGP(setAddressvalue[i].long_name);
                State3 = setAddressvalue[i].long_name
                $("#floatingInputGrid32").val(State3);
            }
            if (State3 === undefined || State3 === "") {
                setstatePGP("");
                $("#floatingInputGrid32").val("");
            }
            if (elm.types[0] === "locality") {
                setcityPGP(setAddressvalue[i].long_name);
                city3 = setAddressvalue[i].long_name;
                $("#floatingInputGrid31").val(city3);
            }
            if (city3 === undefined || city3 === "") {
                setcityPGP("");
                $("#floatingInputGrid31").val("");
            }
            if (elm.types[0] === "postal_code") {
                setpostalPGP(setAddressvalue[i].long_name);
                postalCode3 = setAddressvalue[i].long_name;
                $("#floatingInputGrid34").val(postalCode3);
            }
            if (postalCode3 === undefined || postalCode3 === "") {
                setpostalPGP("");
                $("#floatingInputGrid34").val("");
            }
            if (elm.types[0] === "country") {
                setcountryPGP(setAddressvalue[i].long_name);
                country3 = setAddressvalue[i].long_name;
                $("#floatingInputGrid33").val(country3);
            }
            if (country3 === undefined || country3 === "") {
                setcountryPGP("");
                $("#floatingInputGrid33").val("");
            }
            return true
        })
        setaddressPGP(value);
    }

    // funxtion call for gender autocomplete 
    const ChangetheValueforgender = (data) => {
        setgendercode(data)
        if (data === "1") {
            setbcrm_whatgenderdoyouidentifiedas('1');
        } else if (data === "2") {
            setbcrm_whatgenderdoyouidentifiedas('2');
        } else {
            setbcrm_whatgenderdoyouidentifiedas('3');
        }
    }
    // -------------------------------------------------------* API section* -------------------------------------------------------------------//
    const handleSubmit = async () => {
        debugger
        console.log(LeadSourceNew, patientFundingNew, '>>>>>M<<<<')
        if (tiTitletle !== "" && firstName !== "" && lastName !== "" && emailaddress1 !== "" && LeadType !== "") {
            setIsLoading(true);
            let UserEmail = emailaddress1;
            let TokenHM = localStorage.getItem("HMTokenForApis");
            axios.get(`${url}/api/Leads/CheckEmailExist?email=${UserEmail}`,
                { headers: { "Authorization": TokenHM } }).then((response) => {
                    if (response.data.statusCode === 200) {
                        CheckPatientExist()
                    }
                }, (error) => {
                    if (error.response.data["errorMessage"] === "False") {
                        ErrorToast('Patient already exist');
                        setIsLoading(false);
                    }
                    if (error.response.data["errorMessage"] === "Token Expire") {
                        GettheHmToken();
                        setIsLoading(false);
                    }
                    if (error.response.data["statusCode"] === 401) {
                        ErrorToast('Something went wrong');
                        setIsLoading(false);
                    }
                    setIsLoading(false);
                });
        } else {
            WarnToast('Please fill mandatory fields');
            return setIsLoading(false);

        }
    };

    const CheckPatientExist = async () => {

        if (tiTitletle !== "" && firstName !== "" && lastName !== "" && emailaddress1 !== "" && LeadType !== "") {
            let postdata = {
                MainInterest: mainInterest,
                Title: tiTitletle,
                Forenames: firstName,
                Surname: lastName,
                EmailAddress: emailaddress1,
                Gender: gendercode,
                whatgenderdoyouidentifiedas: bcrm_whatgenderdoyouidentifiedas,
                DateofBirth: birthdate,
                MobilePhone: mobilephone,
                HomePhone: homePhone,
                BusinessPhone: businessPhone,
                PatientFunding: patientFunding,
                NHSNumber: nhsNumber,
                PreviousTreatment: previousTreatment,
                //PatientGroups: patientGroups,
                ox_patient_rx_group: ox_patient_rx_group,
                PatientGroupId: items,
                ox_patientgroup: patientGroups,
                ox_contacttype: LeadType,
                leadsourcecode: LeadSource,
                HouseNo: houseno,
                Street: street,
                City: city,
                State: stat,
                Country: country,
                ZIP: postal,
                GPName: GPName,
                PatientGPSurgeryName: PatientGPSurgeryName,
                GPStreet: street1,
                GPCity: city1,
                GPState: state1,
                GPCountry: country1,
                GPZIP: postal1,
                county: county,
                // partner data
                PartnerAddressSameasPatient: PartnerAddressSameasPatient,
                PartnerFirstName: PName,
                PartnerSurname: PLName,
                PartnerDateOfBirth: Pdob,
                PartnerEmail: PEmail,
                PartnerGender: PGender,
                ReferralBy: PREfered,
                //partner's  GP
                PartnerGPName: checkPartnerGPName,
                PartnerGPSurgeryName: checkPartnerGPSurgeryName,
                PartnerGPStreet: streetPGP,
                PartnerGPCity: cityPGP,
                PartnerGPState: statePGP,
                PartnerGPCountry: countryPGP,
                PartnerGPZIP: postalPGP,
                Source: "14",
                PartnerHouseNo: checkhouseno,
                PartnerStreet: checkstreet,
                PartnerCity: checkcity,
                PartnerCounty: checkcounty,
                PartnerState: checkstate,
                PartnerCountry: checkcountry,
                PartnerZIP: checkpostal,
                patientfundingNew: patientFundingNew,
                LeadSourceNew: LeadSourceNew,
            }

            setIsLoading(true);
            axios.post(`${url}/api/Leads/Create`, postdata, { headers: { "Authorization": localStorage.getItem("HMTokenForApis") } }).then(
                (response) => {
                    var result = response.data;
                    if (result.statusCode === 200) {
                        toast.success('We will contact you shortly ', {
                            autoClose: 5000,
                            hideProgressBar: true,
                            newestOnTop: false,
                            closeOnClick: true,
                            rtl: false,
                            pauseOnFocusLoss: true,
                            draggable: true,
                            pauseOnHover: true,
                            className: 'toast-message'
                        })
                        setIsLoading(true);
                        navigate('/Home');
                    }
                    setIsLoading(false);
                }, (error) => {
                    setIsLoading(false);
                    if (error.response.data["errorMessage"] === "Token Expire") {
                        GettheHmToken();
                    } else {
                        toast.error('Error in register patient', {
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            autoClose: 3000,
                            hideProgressBar: true,
                            theme: "colored"
                        })
                    }
                    setIsLoading(false);
                }
            );
        } else {
            toast.warn('Please fill mandatory fields', {
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                autoClose: 3000,
                hideProgressBar: true,
                theme: "colored"
            })
            return setIsLoading(false);
        }
    };

    const ClearDataonPartner = async (Event) => {
        if (Event.target.value !== "") {
            setPName("");
            setPLName("");
            setPEmail("");
            setPdob("");
            setPGender("");
            setPREfered("");
            gethouseno("");
            getStreet("");
            getcity("");
            getstate("");
            getcountry("");
            getpostal("");
            getcounty("");
            getcheckPartnerGPName("");
            getcheckPartnerGPSurgeryName("");
            setStreetPGP("");
            setcityPGP("");
            setstatePGP("");
            setcountryPGP("");
            setpostalPGP("");
            setaddressPGP("");
        }
    }

    const GetPatientFunding = async () => {
        setIsLoading(true);
        let TokenHM = localStorage.getItem("HMTokenForApis");
        axios.get(`${url}/api/Leads/GetPatientFunding`,
            { headers: { "Authorization": TokenHM } }).then((response) => {
                if (response.data.statusCode === 200) {
                    setPatientFundingData(response.data.content);
                    setIsLoading(false);
                }
            }, (error) => {
                toast.error('Something went wrong', {
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    autoClose: 3000,
                    hideProgressBar: true,
                    theme: "colored"
                })
                setIsLoading(false);
            });
    };

    const GetLeadSource = async () => {
        setIsLoading(true);
        let TokenHM = localStorage.getItem("HMTokenForApis");
        axios.get(`${url}/api/Leads/GetLeadSource`,
            { headers: { "Authorization": TokenHM } }).then((response) => {
                if (response.data.statusCode === 200) {
                    setLeadSourceData(response.data.content);
                    setIsLoading(false);
                }
            }, (error) => {
                toast.error('Something went wrong', {
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    autoClose: 3000,
                    hideProgressBar: true,
                    theme: "colored"
                })
                setIsLoading(false);
            });
    };

    return (
        <>
            <br></br><br></br><br></br>
            <div>
                {isLoading && (
                    <div className="loader-container">
                        <div className="spinner"></div>
                    </div>
                )}

                <div className='container '>
                    <div className='row mb-3'>
                        <div className='col-7 d-flex fs-5 '>
                            <i style={{ fontSize: "30px", marginTop: "2.0rem" }} className="fa-solid fa-arrow-circle-left" onClick={() => navigate('/Home')}></i>
                            <img className="" src='assets\img\kingston_logo.png' id="logoclinicmains" alt="" style={{ height: "87px", width: "190px", marginTop: "25px" }} />
                        </div>
                        <div className="card">
                            <div className="card-body">
                                <div className="card-text">
                                    <div className='row mt-3 '>
                                        <div className='col-md-6'>
                                            <div className="form-floating">
                                                <textarea className="form-control h-25" placeholder="Main Interest" id="floatingTextarea" value={mainInterest}
                                                    onChange={(e) => setmainInterest(e.target.value)}></textarea>
                                                <label htmlFor="floatingTextarea">Main Interest</label>
                                            </div>
                                            <div className="card-body">
                                                <div className="row g-2 mt-2">
                                                    <div className="col-md-6">
                                                        <div className="form-floating">
                                                            <select className="form-select" id="floatingSelect" aria-label="Floating label select example"
                                                                value={LeadType}
                                                                onChange={(e) => setLeadType(e.target.value)} >
                                                                <option selected>---Select Lead type---</option>
                                                                <option value="22">SPECIALIST</option>
                                                                <option value="23">PRACTICE MANAGER</option>
                                                                <option value="24">MONASH IVF SPECIALIST</option>
                                                                <option value="20">STAFF</option>
                                                                <option value="1">PATIENT</option>
                                                                <option value="2">PARTNER</option>
                                                                <option value="3">EGG DONOR</option>
                                                                <option value="4">SPERM DONOR</option>
                                                                <option value="5">INTENDED MOTHER</option>
                                                                <option value="6">INTENDED FATHER</option>
                                                                <option value="7">SURROGATE</option>
                                                                <option value="8">MITOCHONDRIAL ONLY DONOR</option>
                                                                <option value="9">PNT ONLY SPERM DONOR</option>
                                                                <option value="10">PATIENT AND EGG DONOR</option>
                                                                <option value="11">PARTNER AND EGG DONOR</option>
                                                                <option value="12">PARTNER AND SPERM DONOR</option>
                                                                <option value="13">GP</option>
                                                                <option value="14">GYNECOLOGIST</option>
                                                                <option value="15">WITNESS</option>
                                                                <option value="16">REPRESENTATIVE</option>
                                                                <option value="17">OPERATOR</option>
                                                                <option value="18">NURSE</option>
                                                                <option value="19">CLINICIAN</option>
                                                                <option value="21">Patient Male</option>
                                                                <option value="25">OTHER</option>
                                                                <option value="26">MEDICAL STUDENT</option>
                                                            </select>
                                                            <label htmlFor="floatingTextarea">Lead Type <span className="required"></span></label>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-floating text-dark">
                                                            <select className='form-control form-select'
                                                                onChange={(e) => setLeadSourceNew(e.target.value)} value={LeadSourceNew}>
                                                                <option value="">--Select--</option>
                                                                {LeadSourceData?.map((data, key) => { return <option key={key} value={data.id}>{data.name}</option> })}
                                                            </select>
                                                            <label htmlFor="floatingTextarea">Lead Source</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row g-2 mt-2">
                                                    <div className="col-md-6">
                                                        <div className="form-floating">
                                                            <select className="form-select " id="floatingSelect" aria-label="Floating label select example" value={tiTitletle}
                                                                onChange={(e) => setTitle(e.target.value)}  >
                                                                <option value="">---Select Title---</option>
                                                                <option value="4" >Ms</option>
                                                                <option value="5" >Dr</option>
                                                                <option value="1" >Miss</option>
                                                                <option value="2" >Mrs.</option>
                                                                <option value="3" >Mr</option>
                                                                <option value="9" >Mx</option>
                                                                <option value="6" >Master</option>
                                                                <option value="7" >Prof</option>
                                                                <option value="8" >CPL</option>
                                                                <option value="9" >Mx</option>
                                                                <option value="10" >PTE</option>
                                                                <option value="11" >CPT</option>
                                                                <option value="12" >REV</option>
                                                                <option value="13" >Assoc Prof</option>
                                                                <option value="14" >Sr</option>
                                                                <option value="15" >MAJ</option>
                                                                <option value="16" >LAC</option>
                                                                <option value="17" >LCPL</option>
                                                                <option value="18" >Nr</option>
                                                                <option value="19" >FLTL</option>
                                                                <option value="20" >SGT</option>
                                                                <option value="21" >LT</option>
                                                                <option value="22" >PO</option>
                                                                <option value="23" >LS</option>
                                                                <option value="24" >IVF</option>
                                                                <option value="25" >AB</option>
                                                                <option value="26" >GNR</option>
                                                                <option value="27" >GEN</option>
                                                            </select>
                                                            <label for="floatingSelect" >Title <span className="required"></span></label>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-floating text-dark">
                                                            <input type="text" className="form-control" id="floatingInputGrid" placeholder="Firstname" value={firstName}
                                                                onKeyDown={(e) => checkSpecial(e)} onChange={(e) => setfirstName(e.target.value)} />
                                                            <label htmlFor="floatingInputGrid">First Name <span className="required"></span></label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row g-2 mt-2">
                                                    <div className="col-md-6">
                                                        <div className="form-floating">
                                                            <input type="text" className="form-control " id="floatingInputGrid" placeholder="Last Name" value={lastName}
                                                                onKeyDown={(e) => checkSpecial(e)} onChange={(e) => setlastName(e.target.value)} />
                                                            <label htmlFor="floatingInputGrid">Last Name <span className="required"></span></label>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-floating">
                                                            <input type="email" className="form-control " id="floatingInputGrid" placeholder="text Address " value={emailaddress1}
                                                                onKeyDown={(e) => checkSpecial(e)} onChange={(e) => setemailaddress1(e.target.value)} />
                                                            <label htmlFor="floatingInputGrid">Email Address <span className="required"></span></label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row g-2 mt-2">
                                                    <div className="col-md-6">
                                                        <div className="form-floating">
                                                            <select className="form-select " id="floatingSelect" aria-label="Floating label select example" value={gendercode}
                                                                onChange={(e) => ChangetheValueforgender(e.target.value)}>
                                                                <option value="">--Select--</option>
                                                                <option value="1">Male</option>
                                                                <option value="2">Female</option>
                                                                {/* <option value="3">Other</option> */}
                                                            </select>
                                                            <label htmlFor="floatingInputGrid">Gender at birth</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-floating">
                                                            <select className="form-select " id="floatingSelect" aria-label="Floating label select example" value={bcrm_whatgenderdoyouidentifiedas}
                                                                onChange={(e) => setbcrm_whatgenderdoyouidentifiedas(e.target.value)}>
                                                                <option selected>Select gender identified as</option>
                                                                <option value="1">Male</option>
                                                                <option value="2">Female</option>
                                                                <option value="3">Intersex</option>
                                                            </select>
                                                            <label htmlFor="floatingInputGrid">What gender do you identified as?</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row g-2 mt-2">
                                                    <div className="col-md-6">
                                                        <div className="form-floating">
                                                            <input type="date" className="form-control " id="floatingInputGrid" placeholder="Date of Birth" value={birthdate}
                                                                onChange={(e) => setbirthdate(e.target.value)} />
                                                            <label htmlFor="floatingInputGrid">Date of Birth</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-floating registerPI">
                                                            <div className="form-floating">
                                                                <PhoneInput
                                                                    country={'gb'}
                                                                    className="form-control  "
                                                                    id="floatingInputGrid"
                                                                    value={mobilephone}
                                                                    onChange={setmobilephone}
                                                                />
                                                                <label htmlFor="floatingInputGrid">Mobile Phone</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row g-2 mt-2">
                                                    <div className="col-md-6">
                                                        <div className="form-floating registerPI">
                                                            <PhoneInput
                                                                country={'gb'}
                                                                className="form-control  "
                                                                id="floatingInputGrid "
                                                                value={homePhone}
                                                                onChange={sethomePhone}
                                                            />
                                                            <label htmlFor="floatingInputGrid">Home Phone</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-floating registerPI">
                                                            <PhoneInput
                                                                country={'gb'}
                                                                className="form-control "
                                                                id="floatingInputGrid "
                                                                value={businessPhone}
                                                                onChange={setbusinessPhone}
                                                            />
                                                            <label htmlFor="floatingInputGrid">Business Phone</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row g-2 mt-2">
                                                    <div className="col-md-6">
                                                        <div className="form-floating">
                                                            <select className='form-control form-select' onChange={(e) => setpatientFundingNew(e.target.value)} value={patientFundingNew}>
                                                                <option value="">--Select--</option>
                                                                {PatientFundingData?.map((data, key) => { return <option key={key} value={data.id}>{data.name}</option> })}
                                                            </select>
                                                            <label htmlFor="floatingInputGrid">Patient Funding</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-floating">
                                                            <input type="text" className="form-control  " id="floatingInputGrid" placeholder=" Enter NHS/CHI/HCN/PSEL Number" value={nhsNumber}
                                                                onKeyDown={(e) => checkSpecial(e)} onChange={(e) => setnhsNumber(e.target.value)} />
                                                            <label htmlFor="floatingInputGrid">NHS/CHI/HCN/PSEL Number</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row g-2 mt-2">
                                                    <div className="col-md-6">
                                                        <div className="form-floating">
                                                            <select className="form-select " id="floatingSelect" aria-label="Floating label select example" value={previousTreatment}
                                                                onChange={(e) => setpreviousTreatment(e.target.value)}>
                                                                <option value="" disabled>Select Previous Treatment</option>
                                                                <option value="true">Yes</option>
                                                                <option value="false">No</option>
                                                            </select>
                                                            <label htmlFor="floatingInputGrid">Previous Treatment</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-floating">
                                                            <select className="form-select " id="floatingSelect" aria-label="Floating label select example" value={patientGroups}
                                                                onChange={(e) => setpatientGroups(e.target.value)}>
                                                                <option value="" disabled>Select Patient Groups</option>
                                                                <option value="0">Heterosexual</option>
                                                                <option value="1">Same Sex - Female</option>
                                                                <option value="2">Same Sex - Male.</option>
                                                                <option value="3">Single.</option>
                                                                <option value="4">Transgender</option>
                                                                <option value="5">Unknown</option>
                                                            </select>
                                                            <label htmlFor="floatingInputGrid">Patient Groups</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row g-2 mt-2">
                                                    <div className="col-md-6">
                                                        <div className="form-floating">
                                                            <select className="form-select" id="floatingSelect" aria-label="Floating label select example"
                                                                value={ox_patient_rx_group}
                                                                onChange={(e) => setox_patient_rx_group(e.target.value)} >
                                                                <option selected>---Select---</option>
                                                                <option value="425330000">N/A</option>
                                                                <option value="1">ART</option>
                                                                <option value="2">CR Egg Donor</option>
                                                                <option value="3">RR Egg Donor</option>
                                                                <option value="4">Egg Bank Donor</option>
                                                                <option value="5">CR Embryo Donor</option>
                                                                <option value="6">RR Embryo Donor </option>
                                                                <option value="7">CR Egg Recipient </option>
                                                                <option value="8">RR Egg Recipient</option>
                                                                <option value="9">Egg Bank Recipient</option>
                                                                <option value="10">RR Embryo Recipient</option>
                                                                <option value="11">CR Embryo Recipient</option>
                                                                <option value="12">CR Sperm Donor</option>
                                                                <option value="13">RR Sperm Donor </option>
                                                                <option value="14">Sperm Bank Donor</option>
                                                                <option value="15">CR Sperm Recipient</option>
                                                                <option value="16">RR Sperm Recipient</option>
                                                                <option value="17">Sperm Bank Recipient</option>
                                                                <option value="18">CP Own Gametes</option>
                                                                <option value="19">CP - CR Egg Recipient </option>
                                                                <option value="20">CP - RR Egg Recipient</option>
                                                                <option value="22">CP - Egg Bank Recipient</option>
                                                                <option value="23">CP - CR Sperm Recipien</option>
                                                                <option value="24">CP - RR Sperm Recipient</option>
                                                                <option value="25">CP - Sperm Bank Recipient</option>
                                                                <option value="26">CP - CR Embryo Recipient</option>
                                                                <option value="27">CP - RR Embryo Recipient</option>
                                                                <option value="28">CP - Multi Source</option>
                                                                <option value="29">Surrogate </option>
                                                                <option value="30">CR Egg and RR Sperm Recipient</option>
                                                                <option value="31">RR Egg and RR Sperm Recipient</option>
                                                                <option value="32">Egg Bank and RR Sperm Recipient</option>
                                                                <option value="33">CR Egg and Sperm Bank Recipient</option>
                                                                <option value="34">RR Egg and Sperm Bank Recipient</option>
                                                                <option value="35">Egg Bank and Sperm Bank Recipient</option>
                                                                <option value="36">CR Egg and CR Sperm Recipient</option>
                                                                <option value="37">RR Egg and CR Sperm Recipient</option>
                                                                <option value="38">Egg Bank and CR Sperm Recipient</option>
                                                                <option value="39">Egg Sharing</option>
                                                                <option value="40">TI</option>
                                                                <option value="41">AI</option>
                                                                <option value="42">DI</option>
                                                                <option value="43">PP Pathology</option>
                                                                <option value="44">PP Clinic</option>
                                                                <option value="45">PP Ultrasound</option>
                                                                <option value="46">PP Genetics</option>
                                                                <option value="47">PP Donor</option>
                                                                <option value="48">PP Counselling</option>
                                                                <option value="49">PP Gynae</option>
                                                                <option value="50">Pre-ART</option>
                                                            </select>
                                                            <label htmlFor="floatingInputGrid">Patient Rx Group</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* area of address lookup */}
                                        <div className='col-md-6 d-flex h-75 '>
                                            <div className="card w-100" >
                                                <div className="card-body ">
                                                    <h5 className="card-title">Address Lookup</h5>
                                                    <div className="row g-2 mt-2">
                                                        <div className="col-md-6">
                                                            <div className="form-floating">
                                                                <PlacesAutocomplete
                                                                    value={address}
                                                                    onChange={setAddress}
                                                                    onSelect={handleSelect}
                                                                >
                                                                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                                                        <div className="autocomplete-root">
                                                                            <div className="form-floating">
                                                                                <input  {...getInputProps({
                                                                                    placeholder: "Enter Adress lookup",
                                                                                    className: "form-control", id: "floatingInputGrid", type: "text"
                                                                                })} />
                                                                                <label htmlFor="floatingInputGrid">Enter Address </label>
                                                                            </div>
                                                                            <div className="autocomplete-dropdown-container">
                                                                                {loading ? <div>...loading</div> : null}
                                                                                {suggestions.map(suggestion => {
                                                                                    const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item';
                                                                                    const style = suggestion.active
                                                                                        ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                                                                        : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                                                                    return (
                                                                                        <div {...getSuggestionItemProps(suggestion, { style, className })}>
                                                                                            <sapn>{suggestion.description}</sapn>
                                                                                        </div>
                                                                                    );
                                                                                })}
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </PlacesAutocomplete>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-floating">
                                                                <input type="text" className="form-control" id="floatingInputGrid1" placeholder="House No/Flat No." name="houseforpatient"
                                                                    onKeyDown={(e) => checkSpecial(e)} onChange={(e) => sethouseno(e.target.value)} defaultValue={houseno} />
                                                                <label htmlFor="floatingInputGrid1">House No/Flat No.</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row g-2 mt-2">
                                                        <div className="col-md-6">
                                                            <div className="form-floating">
                                                                <input type="text" className="form-control" id="floatingInputGrid2" placeholder="Street" onKeyDown={(e) => checkSpecial(e)}
                                                                    name="streetforpatient" onChange={(e) => setStreet(e.target.value)} defaultValue={street} />
                                                                <label htmlFor="floatingInputGrid2">Street</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-floating">
                                                                <input type="text" className="form-control" id="floatingInputGrid3" placeholder="City"
                                                                    onKeyDown={(e) => checkSpecial(e)} name="cityforpatient" defaultValue={city} onChange={(e) => setcity(e.target.value)} />
                                                                <label htmlFor="floatingInputGrid3">City</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row g-2 mt-2">
                                                        <div className="col-md-6">
                                                            <div className="form-floating">
                                                                <input type="text" className="form-control" id="floatingInputGrid4" placeholder="Country/Region"
                                                                    onKeyDown={(e) => checkSpecial(e)} name="countryforpatient" defaultValue={country} onChange={(e) => setcountry(e.target.value)} />
                                                                <label htmlFor="floatingInputGrid4">Country/Region</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-floating">
                                                                <input type="text" className="form-control" id="floatingInputGrid5" placeholder="State/Province" name="stateforpatient"
                                                                    onKeyDown={(e) => checkSpecial(e)} onChange={(e) => setstate(e.target.value)} defaultValue={stat} />
                                                                <label htmlFor="floatingInputGrid5">State/Province</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row g-2 mt-2">
                                                        <div className="col-md-6">
                                                            <div className="form-floating">
                                                                <input type="text" className="form-control" id="floatingInputGrid6" placeholder="ZIP/Postal Code" name='postalcodeforpatient'
                                                                    onKeyDown={(e) => checkSpecial(e)} defaultValue={postal} onChange={(e) => setpostal(e.target.value)} />
                                                                <label htmlFor="floatingInputGrid6">ZIP/Postal Code</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-floating">
                                                                <input type="text" className="form-control" id="floatingInputGrid7" placeholder="County" name="county"
                                                                    onKeyDown={(e) => checkSpecial(e)} onChange={(e) => setcounty(e.target.value)} />
                                                                <label htmlFor="floatingInputGrid7">County</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* My gp information */}
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <div className="card w-100 h-100" >
                                                <div className="card-body w-100">
                                                    <h5 className="card-title">GP information </h5>
                                                    <div className="row g-2 mt-2">
                                                        <div className="col-md-6">
                                                            <div className="form-floating">
                                                                <input type="text" className="form-control" id="floatingInputGrid8" placeholder="GPs Name" defaultValue={GPName}
                                                                    onKeyDown={(e) => checkSpecial(e)} onChange={(e) => setGPName(e.target.value)} />
                                                                <label htmlFor="floatingInputGrid8">GPs Name</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-floating">
                                                                <input type="text" className="form-control" id="floatingInputGrid9" placeholder="GP Surgery Name" defaultValue={PatientGPSurgeryName}
                                                                    onKeyDown={(e) => checkSpecial(e)} onChange={(e) => setPatientGPSurgeryName(e.target.value)} />
                                                                <label htmlFor="floatingInputGrid9">GP Surgery Name</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row g-2 mt-2">
                                                        <div className="col-md-6">
                                                            <div className="form-floating">
                                                                <PlacesAutocomplete
                                                                    value={addressGP}
                                                                    onChange={setaddress1}
                                                                    onSelect={handleSelect1} >
                                                                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                                                        <div className="autocomplete-root">
                                                                            <div className="form-floating">
                                                                                <input  {...getInputProps({
                                                                                    placeholder: "Enter Adress lookup",
                                                                                    className: "form-control", id: "floatingInputGrid110", type: "text"
                                                                                })} />
                                                                                <label>Enter Address </label>
                                                                            </div>
                                                                            <div className="autocomplete-dropdown-container">
                                                                                {loading ? <div>...loading</div> : null}
                                                                                {suggestions.map(suggestion => {
                                                                                    const style = suggestion.active
                                                                                        ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                                                                        : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                                                                    return (
                                                                                        <div {...getSuggestionItemProps(suggestion, { style })}>
                                                                                            <sapn>{suggestion.description}</sapn>
                                                                                        </div>
                                                                                    );
                                                                                })}
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </PlacesAutocomplete>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-floating">
                                                                <input type="text" className="form-control" id="floatingInputGrid11" placeholder="GP Street" defaultValue={street1}
                                                                    onKeyDown={(e) => checkSpecial(e)} onChange={(e) => setStreet1(e.target.value)} />
                                                                <label >GP Street</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row g-2 mt-2">
                                                        <div className="col-md-6">
                                                            <div className="form-floating">
                                                                <input type="text" className="form-control" id="floatingInputGrid12" placeholder="GP City" defaultValue={city1}
                                                                    onKeyDown={(e) => checkSpecial(e)} onChange={(e) => setcity1(e.target.value)} />
                                                                <label htmlFor="floatingInputGrid12">GP City</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-floating">
                                                                <input type="text" className="form-control" id="floatingInputGrid13" placeholder="GP State/Province"
                                                                    onKeyDown={(e) => checkSpecial(e)} defaultValue={state1} onChange={(e) => setstate1(e.target.value)} />
                                                                <label htmlFor="floatingInputGrid13">GP State/Province</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row g-2 mt-2">
                                                        <div className="col-md-6">
                                                            <div className="form-floating">
                                                                <input type="text" className="form-control" id="floatingInputGrid14" placeholder="GP Country/Region"
                                                                    onKeyDown={(e) => checkSpecial(e)} onChange={(e) => setcountry1(e.target.value)} defaultValue={country1} />
                                                                <label htmlFor="floatingInputGrid14">GP Country/Region</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-floating">
                                                                <input type="text" className="form-control" id="floatingInputGrid15" placeholder="GPs ZIP/Postal Code"
                                                                    onKeyDown={(e) => checkSpecial(e)} onChange={(e) => setpostal1(e.target.value)} defaultValue={postal1} />
                                                                <label htmlFor="floatingInputGrid15">GPs ZIP/Postal Code</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Switch button  */}
                                    <div className='row mt-3'>
                                        <div className="form-check form-switch  d-flex">
                                            <div className='col-md-6 fs-4'>
                                                <label className="form-check-label " htmlFor="flexSwitchCheckDefault">
                                                    <h3 className="fs-4">Do you have any partner details you would like to enter?</h3></label>
                                            </div>
                                            <div className='col-md-4 ms-3'>
                                                <label className="switch">
                                                    <input className="switch-input" type="checkbox" onClick={(e) => ClearDataonPartner(e, setcontent(!content))} />
                                                    <span className="switch-label" data-on="Yes" data-off="No"  ></span>
                                                    <span className="switch-handle"></span>
                                                </label>
                                            </div>
                                        </div>
                                        {/* content  */}
                                        {content &&
                                            <div className='row d-flex mt-4'>
                                                <div className='col-md-6  me-2 '>
                                                    <div className="card-body w-100">
                                                        <div className="row g-2 mt-2">
                                                            <div className="col-md-6">
                                                                <div className="form-floating">
                                                                    <input type="text" className="form-control" id="floatingInputGrid15" placeholder="Partner First Name" value={PName}
                                                                        onKeyDown={(e) => checkSpecial(e)} onChange={(e) => setPName(e.target.value)} />
                                                                    <label htmlFor="floatingInputGrid15">Partner First Name</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="form-floating">
                                                                    <input type="text" className="form-control" id="floatingInputGrid16" placeholder="Partner Last Name" value={PLName}
                                                                        onKeyDown={(e) => checkSpecial(e)} onChange={(e) => setPLName(e.target.value)} />
                                                                    <label htmlFor="floatingInputGrid16">Partner Last Name</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row g-2 mt-2">
                                                            <div className="col-md-6">
                                                                <div className="form-floating">
                                                                    <input type="email" className="form-control" id="floatingInputGrid17" placeholder="Partner text" value={PEmail}
                                                                        onKeyDown={(e) => checkSpecial(e)} onChange={(e) => setPEmail(e.target.value)} />
                                                                    <label htmlFor="floatingInputGrid17">Partner Email</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="form-floating">
                                                                    <input type="date" className="form-control" id="floatingInputGrid18" placeholder="Partner Date Of Birth" value={Pdob}
                                                                        onChange={(e) => setPdob(e.target.value)} />
                                                                    <label htmlFor="floatingInputGrid18">Partner Date Of Birth</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row g-2 mt-2">
                                                            <div className="col-md-6">
                                                                <div className="form-floating">
                                                                    <select className="patientinputs form-select  " id="floatingSelectGrid19" name="gender" aria-label="Floating label select example" value={PGender}
                                                                        onChange={(e) => setPGender(e.target.value)}>
                                                                        <option value>--Select Gender--</option>
                                                                        <option value="1">Male</option>
                                                                        <option value="2">Female</option>
                                                                        <option value="3">Other</option>
                                                                    </select>
                                                                    <label htmlFor="floatingSelectGrid19">Partner Gender at birth</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="form-floating">
                                                                    <input type="text" className="form-control" id="floatingInputGrid20" placeholder="Referral By" value={PREfered}
                                                                        onKeyDown={(e) => checkSpecial(e)} onChange={(e) => setPREfered(e.target.value)} />
                                                                    <label htmlFor="floatingInputGrid20">Referral By</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row g-2 mt-2 mb-3">
                                                        <div className="col-md-9" id="patientgpsameadd">
                                                            <input className="form-check-input" type="checkbox"
                                                                id="flexCheckChecked21" onClick={(e) => { setdata(e.target.checked) }} value={PartnerAddressSameasPatient} />
                                                            <label className="form-check-label fs-5 " htmlFor="flexCheckChecked21">
                                                                Use same address as patient
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="card w-100  " >
                                                        <div className="card-body w-100">
                                                            <div className="row g-2 mt-2">
                                                                <div className="col-md-6">
                                                                    <div className="form-floating">
                                                                        <input type="text" className="form-control" id="floatingInputGrid22" placeholder="Partner House No/Flat No."
                                                                            onKeyDown={(e) => checkSpecial(e)} defaultValue={checkhouseno} onChange={(e) => gethouseno(e.target.value)} />
                                                                        <label htmlFor="floatingInputGrid22">Partner House No/Flat No.</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <div className="form-floating">
                                                                        <input type="text" className="form-control" id="floatingInputGrid23" placeholder="Partner Street"
                                                                            onKeyDown={(e) => checkSpecial(e)} defaultValue={checkstreet} onChange={(e) => getStreet(e.target.value)} />
                                                                        <label htmlFor="floatingInputGrid23">Partner Street</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row g-2 mt-2">
                                                                <div className="col-md-6">
                                                                    <div className="form-floating">
                                                                        <input type="text" className="form-control" id="floatingInputGrid24" placeholder="Partner City"
                                                                            onKeyDown={(e) => checkSpecial(e)} defaultValue={checkcity} onChange={(e) => getcity(e.target.value)} />
                                                                        <label htmlFor="floatingInputGrid24">Partner City</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <div className="form-floating">
                                                                        <input type="text" className="form-control" id="floatingInputGrid25" placeholder="Partner State/Province"
                                                                            onKeyDown={(e) => checkSpecial(e)} defaultValue={checkstate} onChange={(e) => getstate(e.target.value)} />
                                                                        <label htmlFor="floatingInputGrid25">Partner State/Province</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row g-2 mt-2">
                                                                <div className="col-md-6">
                                                                    <div className="form-floating">
                                                                        <input type="text" className="form-control" id="country_Region" placeholder="Partner Country/Region"
                                                                            onKeyDown={(e) => checkSpecial(e)} defaultValue={checkcountry} onChange={(e) => getcountry(e.target.value)} />
                                                                        <label htmlFor="country_Region">Partner Country/Region</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <div className="form-floating">
                                                                        <input type="text" className="form-control" id="floatingInputGrid26" placeholder="Partner ZIP/Postal Code"
                                                                            onKeyDown={(e) => checkSpecial(e)} defaultValue={checkpostal} onChange={(e) => getpostal(e.target.value)} />
                                                                        <label htmlFor="floatingInputGrid26">Partner ZIP/Postal Code</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row g-2 mt-2">
                                                                <div className="col-md-6">
                                                                    <div className="form-floating">
                                                                        <input type="text" className="form-control" id="floatingInputGridcounty" name='county' placeholder="Partner County"
                                                                            onKeyDown={(e) => checkSpecial(e)} defaultValue={checkcounty} onChange={(e) => getcounty(e.target.value)} />
                                                                        <label htmlFor="floatingInputGridcounty">Partner County</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* partner addres with check boc info */}
                                                <div className="col-md-5 mb-4">
                                                    <div className="row mb-3 g-2 mt-2 ">
                                                        <div className='col-md-9' id="patientgpsameadd">
                                                            <input className="form-check-input" type="checkbox" value=""
                                                                id="flexCheckChecked27" onClick={(e) => { setGPData(e.target.checked) }} />
                                                            <label className="form-check-label fs-5 " htmlFor="flexCheckChecked27">
                                                                Use same address as patient GP
                                                            </label>
                                                        </div>
                                                    </div>
                                                    {/* Partner gp information */}
                                                    <div className="row w-100">
                                                        <div className="card w-100">
                                                            <div className="card-body w-100">
                                                                <div className="row g-2 mt-2">
                                                                    <div className="col-md-6">
                                                                        <div className="form-floating">
                                                                            <input type="text" className="form-control" id="floatingInputGrid28" placeholder="GPs Name" defaultValue={checkPartnerGPName}
                                                                                onKeyDown={(e) => checkSpecial(e)} onChange={(e) => getcheckPartnerGPName(e.target.value)} />
                                                                            <label htmlFor="floatingInputGrid28">GPs Name</label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <div className="form-floating">
                                                                            <input type="text" className="form-control" id="floatingInputGrid29" placeholder="GP Surgery Name" value={checkPartnerGPSurgeryName}
                                                                                onKeyDown={(e) => checkSpecial(e)} onChange={(e) => getcheckPartnerGPSurgeryName(e.target.value)} />
                                                                            <label htmlFor="floatingInputGrid29">GP Surgery Name</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row g-2 mt-2">
                                                                    <div className="col-md-6">
                                                                        <div className="form-floating">
                                                                            <PlacesAutocomplete
                                                                                value={addressPGP}
                                                                                onChange={setaddressPGP}
                                                                                onSelect={handleSelectPGP}
                                                                            >
                                                                                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                                                                    <div className="autocomplete-root">
                                                                                        <div className="form-floating">
                                                                                            <input  {...getInputProps({
                                                                                                placeholder: "Enter Adress lookup",
                                                                                                className: "form-control", id: "floatingInputGrid-1", type: "text"
                                                                                            })} />
                                                                                            <label htmlFor="floatingInputGrid-1">Enter Address </label>
                                                                                        </div>
                                                                                        <div className="autocomplete-dropdown-container">
                                                                                            {loading ? <div>...loading</div> : null}
                                                                                            {suggestions.map(suggestion => {
                                                                                                const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item';
                                                                                                const style = suggestion.active
                                                                                                    ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                                                                                    : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                                                                                return (
                                                                                                    <div {...getSuggestionItemProps(suggestion, { style })}>
                                                                                                        <sapn>{suggestion.description}</sapn>
                                                                                                    </div>
                                                                                                );
                                                                                            })}
                                                                                        </div>
                                                                                    </div>
                                                                                )}
                                                                            </PlacesAutocomplete>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <div className="form-floating">
                                                                            <input type="text" className="form-control" id="floatingInputGrid30" placeholder="GP Street"
                                                                                onKeyDown={(e) => checkSpecial(e)} defaultValue={streetPGP} onChange={(e) => setStreetPGP(e.target.value)} />
                                                                            <label htmlFor="floatingInputGrid30">GP Street</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row g-2 mt-2">
                                                                    <div className="col-md-6">
                                                                        <div className="form-floating">
                                                                            <input type="text" className="form-control" id="floatingInputGrid31" placeholder="GP City"
                                                                                onKeyDown={(e) => checkSpecial(e)} defaultValue={cityPGP} onChange={(e) => setcityPGP(e.target.value)} />
                                                                            <label htmlFor="floatingInputGrid31">GP City</label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <div className="form-floating">
                                                                            <input type="text" className="form-control" id="floatingInputGrid32" placeholder="GP State/Province"
                                                                                onKeyDown={(e) => checkSpecial(e)} defaultValue={statePGP} onChange={(e) => setstatePGP(e.target.value)} />
                                                                            <label htmlFor="floatingInputGrid32">GP State/Province</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row g-2 mt-2">
                                                                    <div className="col-md-6">
                                                                        <div className="form-floating">
                                                                            <input type="text" className="form-control" id="floatingInputGrid33" placeholder="GP Country/Region"
                                                                                onKeyDown={(e) => checkSpecial(e)} defaultValue={countryPGP} onChange={(e) => setcountryPGP(e.target.value)} />
                                                                            <label htmlFor="floatingInputGrid33">GP Country/Region</label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <div className="form-floating">
                                                                            <input type="text" className="form-control" id="floatingInputGrid34" placeholder="GPs ZIP/Postal Code"
                                                                                onKeyDown={(e) => checkSpecial(e)} defaultValue={postalPGP} onChange={(e) => setpostalPGP(e.target.value)} />
                                                                            <label htmlFor="floatingInputGrid34">GPs ZIP/Postal Code</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className='card-footer contactusfont fs-4 fw-b text-center'>
                                <button className='btn btn-lg  colorprofile  text-light' type="button" onClick={handleSubmit}>Submit</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}