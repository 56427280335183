import React, { useEffect, useState } from 'react'
import { WarnToast } from '../Custom Component/Toast';
import axios from 'axios';
import Swal from 'sweetalert2';
import { GettheHmToken } from '../services/AllApiCall';
import { useLocation, useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import { setQuestionairData } from '../Store/Reducers/UserReducer';

export default function Verification() {
    const navigate = useNavigate();
    const [Id, setId] = useState();
    const [Methods, setMethods] = useState(0);
    const [Val, setVal] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const dispatch = useDispatch();

    useEffect(async () => {
        setIsLoading(true);
        await GettheHmToken();
        setId(searchParams.get('Id'));
        localStorage.setItem('PId', searchParams.get('Id'));
        setIsLoading(false);
    }, []);
    const handleSubmit = async () => {
        if (Methods === 1) {
            axios.get(`https://malefertilityapi.azurewebsites.net/api/Profile/GetpatientbyDOB?Dob=${Val}&Id=${Id}`, { headers: { "Authorization": localStorage.getItem("HMTokenForApis") } }).then(
                (response) => {
                    var result = response.data;
                    if (result["content"] != null) {
                        setIsLoading(true);
                        localStorage.setItem("PatientFullName", result["content"].fullName);
                        localStorage.setItem("PatientBirthdate", result["content"].birthday);
                        localStorage.setItem("PatientEmail", result["content"].email);
                        localStorage.setItem("PatientGender", result["content"].gendercodeName);
                        localStorage.setItem("PatientAge", result["content"].age);
                        localStorage.setItem("QuestionaireId", result["content"].bcrm_patientadditionaldetail);
                        GetQuestionaireDetails();
                    } else {
                        Swal.fire({
                            icon: 'Error',
                            text: 'Patient not exist with the given DOB'
                        });
                    }
                }, (error) => {
                    if (error.response.data["errorMessage"] === "Token Expire") {
                        GettheHmToken();
                    } else {
                        Swal.fire({
                            icon: 'Error',
                            text: 'Patient not exist with the given DOB'
                        })
                    }
                }
            );
        } else if (Methods === 2 || Methods === 3) {
            setIsLoading(true);
            axios.get(`https://malefertilityapi.azurewebsites.net/api/Profile/GetpatientbyLasNamePostalcode?LastNamePostal=${Val}&Id=${Id}`,
                { headers: { "Authorization": localStorage.getItem("HMTokenForApis") } }).then((response) => {
                    var result = response.data;
                    if (result["content"] != null) {
                        setIsLoading(true);
                        localStorage.setItem("PatientFullName", result["content"].fullName);
                        localStorage.setItem("PatientBirthdate", result["content"].birthday);
                        localStorage.setItem("PatientEmail", result["content"].email);
                        localStorage.setItem("PatientGender", result["content"].gendercodeName);
                        localStorage.setItem("PatientAge", result["content"].age);
                        localStorage.setItem("QuestionaireId", result["content"].bcrm_patientadditionaldetail);
                        GetQuestionaireDetails();
                    } else {
                        Swal.fire({
                            icon: 'Error',
                            text: 'Patient not exist with the entered data'
                        })
                        setIsLoading(false);
                    }
                }, (error) => {
                    setIsLoading(false);
                    if (error.response.data["errorMessage"] === "Token Expire") {
                        GettheHmToken();
                    } else {
                        Swal.fire({
                            icon: 'Error',
                            text: 'Patient not exist with the entered data'
                        })
                    }
                    setIsLoading(false);
                }
                );
        } else {
            WarnToast('Please choose verification Method');
            return setIsLoading(false);
        }
    };

    const GetQuestionaireDetails = async () => {
        setIsLoading(true);
        await axios(`https://malefertilityapi.azurewebsites.net/api/Profile/GetQuestionaireFemalebyPatientId?id=${Id}`,
            { headers: { "Authorization": localStorage.getItem("HMTokenForApis") } }).then((res) => {
                if (res.data.statusCode === 200) {
                    dispatch(setQuestionairData(res?.data?.content))
                    if(res.data.content.submittedDate != '0001-01-01T00:00:00'){
                    Swal.fire({
                        icon:'Error',
                        text: 'Already completed'
                    })
                }
                    navigate(`/Form`);
                }
                setIsLoading(false);
            }, (error) => {
                if (error.response.data["errorMessage"] === "Token Expire") {
                    GettheHmToken();
                }
                setIsLoading(false);
                return
            });
    };

    return (
        <div> <br></br><br></br><br></br><br /><br />
            {isLoading && (
                <div className="loader-container">
                    <div className="spinner"></div>
                </div>
            )}
            <div className='container '>
                <div className="row mb-3">
                    <div className="card">
                        <div className="card-body">
                            <div className="card-text">
                                <div className="card-body">
                                    <h3>Verification</h3>
                                    <div className="col-md-6 mt-3">
                                        <div className="row">
                                            <div className="col-md-5">
                                                <label><b>Name :</b> </label>
                                            </div>
                                            <div className="col-md-7">
                                                <input type="text" className="form-control" disabled value={searchParams.get('name')} />
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-md-5">
                                                <label><b>Choose verification Method :</b><span className="required"></span></label>
                                            </div>
                                            <div className="col-md-7">
                                                <select className="form-controll" onChange={(e) => {
                                                    setMethods(parseInt(e.target.value));
                                                    setVal('');
                                                }}>
                                                    <option value="">Select verification method</option>
                                                    <option value="1">Date of birth</option>
                                                    <option value="2">Last Name</option>
                                                    <option value="3">Postal code</option>
                                                </select>
                                            </div>
                                        </div>
                                        {
                                            Methods !== 0 &&
                                            <div className="row mt-3">
                                                <div className="col-md-5">
                                                    <label><b>{Methods === 1 ? 'Date of birth' : Methods === 2 ? 'Last Name' : Methods === 3 ? 'Postal code' : ''} :</b> </label>
                                                </div>
                                                <div className="col-md-7">
                                                    <input
                                                        type={Methods === 1 ? 'date' : 'text'}
                                                        placeholder={Methods === 2 ? 'Last Name' : Methods === 3 ? 'Postal Code' : null}
                                                        className="form-control"
                                                        onChange={(e) => setVal(e.target.value)}
                                                        value={Val}
                                                    />
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    <div className="row mt-5">
                                        <div className="col-md-2">
                                        </div>
                                        <div className="col-md-4">
                                            <button
                                                onClick={() => handleSubmit()}
                                                className='btn colorprofile text-light float-right' type="button">Submit</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
