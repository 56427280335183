import { toast } from 'react-toastify';

export const ErrorToast = (Message) => toast.error(Message, {
    autoClose: 5000,
    hideProgressBar: true,
    newestOnTop: false,
    closeOnClick: true,
    rtl: false,
    pauseOnFocusLoss: true,
    draggable: true,
    pauseOnHover: true,
    className: 'colored'
});

export const SuccessToast = (Message) => toast.success(Message, {
    autoClose: 5000,
    hideProgressBar: true,
    newestOnTop: false,
    closeOnClick: true,
    rtl: false,
    pauseOnFocusLoss: true,
    draggable: true,
    pauseOnHover: true,
    className: 'toast-message'
}
)

export const WarnToast = (Message) => toast.warn(Message, {
    autoClose: 5000,
    hideProgressBar: true,
    newestOnTop: false,
    closeOnClick: true,
    rtl: false,
    pauseOnFocusLoss: true,
    draggable: true,
    pauseOnHover: true,
    className: 'toast-warn'
}
)