import React from 'react'
import { Card, Button } from 'react-bootstrap';
import './Documents.css';
import { useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { Modal } from 'react-bootstrap';
import moment from "moment";
import { useDispatch } from "react-redux";
import { setLoader } from '../Store/Reducers/UserReducer';
import LoginFun from '../Layout Component/Loginfun';
import 'react-toastify/dist/ReactToastify.css';
import $ from "jquery";
import { toast } from 'react-toastify';

var UserData;
export default function Documents() {
  const dispatch = useDispatch();
  const url = process.env.REACT_APP_OXAR_API_URL;
  const [DocumentsData, setDocumentsData] = useState([]);
  const [dateSort, setdateSort] = useState(1);
  const [ResotreFolder, setResotreFolder] = useState([]);
  const [CurrentDirectory, setCurrentDirectory] = useState([]);
  const [currentFolderFileList, setcurrentFolderFileList] = useState([]);
  const [query, setQuery] = useState("")
  const [FolderName, setFolderName] = useState();
  const [parentUrl, setparentUrl] = useState();
  const [documentarray, setdocumentarray] = useState([]);
  const [path, setpath] = useState([]);
  const [files, setFiles] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [isFilePresentInDirectory, setisFilePresentInDirectory] = useState();
  const [NewFolder, setNewFolder] = useState([]);
  const [oldObj, setoldObj] = useState([]);
  const inputRef = React.useRef(null);
  const [show, setShow] = useState(false);
  const [data, setData] = useState();
  const [code, setCode] = useState("");
  const [folderIsExist, setfolderIsExist] = useState(Boolean);
  const [folderData, setfolderData] = useState("");
  const logindata = LoginFun();

  useEffect(() => {
    UserData = JSON.parse(localStorage.getItem("UData"));
    getDocuments();
    sessionCheck();

  }, []);
  const sessionCheck = async () => {
    var currentTime = ''
    currentTime = (Math.round(new Date().getTime() / 1000));
    var Expiretime = localStorage.getItem("ExpirretokenTime");

    if (Expiretime < currentTime) {

      sessionStorage.getItem('isLoggedIn', false)
      Swal.fire({
        title: 'Session Expired',
        text: "Your session has been expired! Please login again",
        icon: 'warning',
        showCancelButton: false,
        confirmButtonText: 'Okay',
      }).then(result => {
        logindata.logout();
      })
    }
  }
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setData('')
    setShow(true)
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    CreateNewFolder(code);
    handleClose();
  }
  // ReloadComponent()
  const ReloadComponent = () => {
    window.location.reload(true);
  }

  // Upload docuemnts and files into the CRM //
  const onUpload = (changeEvent) => {

    setcurrentFolderFileList(tableData.children);
    var file = changeEvent?.currentTarget?.files[0];
    let isFilePresentInDirectory = currentFolderFileList?.find(x => x['label'] == file?.name);
    let fileExtension = file?.name.split('.').pop();
    if (isFilePresentInDirectory != undefined) {
      toast.warn('File Already Exist in the Directory Please Change the File Name or Upload Another file', {
        autoClose: 5000,
        hideProgressBar: true,
        newestOnTop: false,
        closeOnClick: true,
        rtl: false,
        pauseOnFocusLoss: true,
        draggable: true,
        pauseOnHover: true,
        theme: "colored"
      }
      )
    } else {
      var filesize = Number(((file.size / 1024) / 1024).toFixed(4)); // MB
      const validImageTypes = [
        'image/gif',
        'image/jpeg',
        'image/png',
        'image/jpg',
        'application/pdf',
        'video/mp4',
        'text/plain',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/vnd.openxmlformats-officedocument.presentationml.presentation',
        'application/vnd.ms-powerpoint',
        'application/vnd.ms-excel',
        'application/msword'
      ];
      if (!validImageTypes.includes(file['type']) || fileExtension == "rtf") {
        $("#fileJsUpload").val("");

        toast.warn('This file type not allowed to upload in sharepoint', {
          autoClose: 5000,
          hideProgressBar: true,
          newestOnTop: false,
          closeOnClick: true,
          rtl: false,
          pauseOnFocusLoss: true,
          draggable: true,
          pauseOnHover: true,
          className: 'toast-warn'

        }
        )
      }
      else {
        if (filesize <= 1) {
          let MainUserId = localStorage.getItem("UserID");
          var payload = new FormData();
          payload.append("file", file);
          dispatch(setLoader(true));
          axios
            .post(`${url}api/SharepointFiles/UploadFile?contactNumber=${MainUserId}&parentUrl=${parentUrl}&currentFolderPath=${CurrentDirectory}&FolderName=${FolderName}&NewFolder=${NewFolder}`, payload)
            .then((res) => {
              $("#fileJsUpload").val("");
              toast.success('Successfully uploaded', {
                autoClose: 5000,
                hideProgressBar: true,
                newestOnTop: false,
                closeOnClick: true,
                rtl: false,
                pauseOnFocusLoss: true,
                draggable: true,
                pauseOnHover: true,
                className: 'toast-message'
              }
              )
              getDocuments();
              dispatch(setLoader(false));


            })
            .catch((err) => toast.error('File upload error ! Please try to upload an another file', {
              autoClose: 5000,
              hideProgressBar: true,
              newestOnTop: false,
              closeOnClick: true,
              rtl: false,
              pauseOnFocusLoss: true,
              draggable: true,
              pauseOnHover: true,
              theme: "colored"
            }
            )
            );
          dispatch(setLoader(false));
        }
        else {
          $('input[type="file"]').val('');
          //toast.warning("The Maximum Size Limit of Document is 1 MB", "");
          toast.warning('"The Maximum Size Limit of Document is 1 MB", ""', {
            autoClose: 5000,
            hideProgressBar: true,
            newestOnTop: false,
            closeOnClick: true,
            rtl: false,
            pauseOnFocusLoss: true,
            draggable: true,
            pauseOnHover: true,
            theme: "colored"
          }
          )
          return
        }
      }
    }
    dispatch(setLoader(false));
  }

  // ******************API FOR GETTING DOCUMENTS DATA*********************** //
  const getDocuments = async () => {

    let MainUserId = localStorage.getItem("UserID");
    try {
      dispatch(setLoader(true));;
      await axios(`${url}api/SharepointFiles/GetFileList?userGuid=${MainUserId}`).then((res) => {
        if (res != null && res.data.content != null) {

          if (res.data.content.children != null || res.data.content.label != null) {
            setfolderIsExist(true);
            setfolderData(res.data.content);
            setResotreFolder(res.data.content);
            setDocumentsData(res.data.content);
            setCurrentDirectory(res.data.content.children[0].relativeUrl);
            setFolderName(res.data.content.children[0].label)
            var p = res.data.content.children[0].relativeUrl.split('/');
            setpath([]);
            let PATH = []
            if (p[0] == "https:") {
              for (var i = 3; i < p.length; i++) {
                if (p[i] == "msemr_procedure") { }
                else {
                  PATH.push(p[i]);
                  setpath(PATH);
                }
              }

            }
            else {
              for (var i = 1; i < p.length; i++) {
                PATH.push(p[i]);
                setpath(PATH);

              }
            }
            setparentUrl(res.data.content.children[0].parentUrl.split(FolderName)[0])
            if (res.data.content['children'][0]) {
              res.data.content['children'][0]['children'].sort((val1, val2) => new Date(val2.date) - new Date(val1.date));
              setdocumentarray(res.data.content['children'][0]['children']);
            } else { }
          }
        }

      });

      dispatch(setLoader(false));;
    }
    catch {
    }
  };

  // ******** Download file from CRM ********** // 
  const ReloadData = (obj) => {

    var localthis;
    setoldObj(tableData);
    setTableData([]);
    if (obj.type == "folder") {
      var newtableData = Object.assign({}, obj);
      setTableData(newtableData);
      setdocumentarray(newtableData.children);
      // localthis.changeDetection.detectChanges();
    }
  }

  const downloadFile = async (ListOfFile) => {

    try {
      let MainUserId = localStorage.getItem("UserID");
      {
        dispatch(setLoader(true));;
        await axios(`${url}api/SharepointFiles/DownloadFile?retrivalURL=${encodeURIComponent(ListOfFile.relativeUrl)}`, { responseType: 'arraybuffer' }).then((res) => {
          if (res.status == 200) {
            var linkElement = document.createElement('a');
            var __blob = new Blob([res.data], { type: "application/octet-stream" });
            var url = window.URL.createObjectURL(__blob);
            linkElement.setAttribute('href', url);
            linkElement.setAttribute("download", ListOfFile.label);
            var clickEvent = new MouseEvent("click", {
              "view": window,
              "bubbles": true,
              "cancelable": false
            });
            linkElement.dispatchEvent(clickEvent);
            toast.success('Successfully downloaded', {
              autoClose: 5000,
              hideProgressBar: true,
              newestOnTop: false,
              closeOnClick: true,
              rtl: false,
              pauseOnFocusLoss: true,
              draggable: true,
              pauseOnHover: true,
              className: 'toast-message'
            }
            )
          }
        });
        dispatch(setLoader(false));;
      }
    }
    catch {
    }
  }
  // **** Download file in local system ****//
  const DownloadIntoLocal = (listoffile) => {

    if (listoffile?.type == "folder") {
      var a = listoffile?.label;
      setFolderName(a);
      setCurrentDirectory(listoffile?.relativeUrl);
      setparentUrl(listoffile?.parentUrl);
      const p = listoffile?.relativeUrl.split('/');;
      let PATH = [];
      if (p[0] == "https:") {
        for (var i = 3; i < p.length; i++) {
          if (p[i] == "PMS") { }
          else {
            PATH.push(p[i]);
            setpath(PATH);
          }
        }
      }
      else {
        for (var i = 1; i < p.length; i++) {
          PATH.push(p[i]);
          setpath(PATH);
        }
      }
      var data = listoffile.parentUrl.toLowerCase()
      // var data = listoffile.parentUrl.toLowerCase().split('/' + FolderName)[0];
      setparentUrl(data);
      ReloadData(listoffile);
    }
    else if (listoffile.relativeUrl) {
      downloadFile(listoffile);
    }
  }

  // DELETEING FILE IN FOLDER
  const deleteFile = (name) => {
    setFiles(current =>
      current.filter(data => {
        return data != name;
      }),
    );
  }

  // API for create new folder //
  const onCreateFolder = async (getFolder) => {

    try {
      let MainUserId = localStorage.getItem("UserID");
      {
        dispatch(setLoader(true));;
        await axios.post(`${url}api/SharepointFiles/UploadFile?contactNumber=${MainUserId}&parentUrl=${parentUrl}&currentFolderPath=${CurrentDirectory}&FolderName=${FolderName}&NewFolder=${getFolder}`).then((res) => {
          if (res.status == 200) {
            toast.success('Folder created!', {
              autoClose: 5000,
              hideProgressBar: true,
              newestOnTop: false,
              closeOnClick: true,
              rtl: false,
              pauseOnFocusLoss: true,
              draggable: true,
              pauseOnHover: true,
              className: 'toast-message'
            }
            )
            getDocuments();
            //ReloadComponent();
            dispatch(setLoader(false));
          }
        });
      }
    }
    catch {
      dispatch(setLoader(false));
      toast.error('Folder upload error', {
        autoClose: 5000,
        hideProgressBar: true,
        newestOnTop: false,
        closeOnClick: true,
        rtl: false,
        pauseOnFocusLoss: true,
        draggable: true,
        pauseOnHover: true,
        theme: "colored"
      }
      )
    }
  }

  // *******CREATE NEW FOLDER ********//
  const CreateNewFolder = (event) => {

    let getFileName = event.target.value;
    if (documentarray && documentarray.length > 0) {
      let Foldercheck = documentarray?.children;
      let FolderExistAlredy;
      for (var i = 0; i < documentarray.length; i++) {
        if (documentarray[i]?.label === getFileName) {
          FolderExistAlredy = documentarray[i].label;
        }
      }
      if (FolderExistAlredy != undefined) {
        toast.warn('Folder Already Exist in the Directory Please Change the Folder Name', {
          autoClose: 5000,
          hideProgressBar: true,
          newestOnTop: false,
          closeOnClick: true,
          rtl: false,
          pauseOnFocusLoss: true,
          draggable: true,
          pauseOnHover: true,
          className: 'toast-warn'
        }
        )
        dispatch(setLoader(false));
        return
      }
    }
    onCreateFolder(getFileName);
  }

  /************** BACK TO HOME DIRECTORY IN  FOLDER */
  const back = (fname) => {

    //var l = tableData;
    if (ResotreFolder.label === "fname") {
      // tableData = ResotreFolder;
      setTableData(ResotreFolder)
      // setdocumentarray(ResotreFolder)
      setCurrentDirectory(ResotreFolder.relativeUrl);
      var p = ResotreFolder.relativeUrl.split("/");;

      setpath([]);
      var PATH = [];
      if (p[0] === "https:") {
        for (var i = 3; i < p.length; i++) {
          if (p.i === "contact") { }
          else {
            PATH.push(p[i]);
            setpath(PATH);

          }
        }
      } else {
        for (var k = 2; k < p.length; k++) {
          PATH.push(p[k]);
          setpath(PATH);
        }
      }
    } else {
      getfolder(fname, ResotreFolder.children);
    }
  }


  /********* GET FOLDER LOCATION */
  const getfolder = (fname, root) => {

    for (var j = 0; j < root.length; j++) {

      if (root[j]?.label.toLowerCase() == fname.toLowerCase()) {
        setparentUrl(root[j].parentUrl);
        setFolderName(fname)
        var m = root[j];;
        setCurrentDirectory(root[j].relativeUrl);
        var p = root[j].relativeUrl.split("/");
        setpath([]);

        let PATH = [];
        if (p[0] == "https:") {

          for (var i = 3; i < p.length; i++) {
            if (p[i] == "PMS") { }
            else {
              PATH.push(p[i]);
              setpath(PATH);
            }
          }
        }
        else {
          for (var i = 1; i < p.length; i++) {
            PATH.push(p[i]);
            setpath(PATH);
          }
        }

        assign(root[j]);
      } else {
        if (root[j].children != null) {
          getfolder(fname, root[j].children);
        }
      }
    }
  }
  const assign = (data) => {

    setTableData(data);
    setdocumentarray(data.children)
  }


  const IndexDate = () => {
    var a;
    if (dateSort === 1) {
      a = [...documentarray].sort((val1, val2) => new Date(val1.date) - new Date(val2.date));
      setdateSort(2);
    } else {
      a = [...documentarray].sort((val1, val2) => new Date(val2.date) - new Date(val1.date));

      setdateSort(1);
    }
    setdocumentarray(a);
  }

  const checkSpecial = (e) => {
    if (!/^[a-zA-Z0-9\s]*$/.test(e.key)) {
      e.preventDefault();
    }
  }
  return (
    <Card className=' borderprofile '>
      <Card.Header className=' colorprofile  text-light'><h5>Documents</h5>
        <div className="col text-right">
          ( {UserData?.firstName} {UserData?.lastName} , {moment(UserData?.birthday).format("DD-MMM-YYYY")} ({UserData?.age}) ,{UserData?.gendercodeName} )
        </div>
      </Card.Header>
      <Card.Body>
        <Card.Text>
          <Card.Header className=' bg-light fs-5 contactusfont text-light'>
            <div className='row'>
              <div className='col-10'>
                <ol className="breadcrumb">
                  {
                    path.length > 0 && path?.map((p, index) => {
                      return (
                        <>
                          <li className="breadcrumb-item active" onClick={() => back(p)}>
                            {index === 0 ?
                              <span className="pointer text-dark ">Home</span> : null}

                            {index != 0 ?
                              <span className="pointer  text-dark">{p}</span> : null}
                          </li>
                        </>
                      )
                    })
                  }
                </ol>
              </div>
              <div className=' col-md-2' id="newfolderbtn">
                <button className='btn btnclass me-2' onClick={handleShow}>Create New Folder +</button>
              </div>
              {/* MODAL FOR CREATE NEW FOLDER  */}
              <Modal size="lg" show={show} onHide={handleClose} >
                <form>
                  <Modal.Header closeButton>
                    <Modal.Title>
                      <h6 >Create Folder</h6>
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body id="Modalbodydocument">
                    <div className='ms-3 row'>
                      <label className='col-sm-4 col-form-label ' >Folder Name <span className='text-danger'>*</span> : </label>
                      <div className="col-sm-7">
                        <input className='form-control m-1  border-info border-1' placeholder='' name="input2" onKeyDown={(e) => checkSpecial(e)} onChange={(e) => setCode(e)} />
                      </div>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button className="btn btnclass" type="button" onClick={handleSubmit} >Create</Button>
                    <Button variant="secondary" onClick={handleClose}>Close</Button>
                  </Modal.Footer>
                </form>
              </Modal>

            </div>
          </Card.Header>
          <Card.Body>
            <>

              <div className="row mt-4 ms-2 me-4 text-start">
                <div className=" col-md-7">
                  <div> <input type="search" id='searchfordoc' placeholder="Global Search For All Documents" style={{ width: "100%" }} onChange={event => setQuery(event.target.value)} />
                  </div><br></br>
                  <div className='ScrollDocumnentdata'>
                    <table className="table" >
                      <thead class="thead-light theadcss">
                        <th id="Namecsstable">Name</th>
                        <th id="Datecsstable" onClick={IndexDate}>Date <i class="fa fa-sort" aria-hidden="true"></i></th>
                      </thead>
                      <tbody>
                        {
                          documentarray?.filter(tdata => {
                            if (query === "") {
                              return tdata;
                            } else if (tdata.label.toLowerCase().includes(query.toLowerCase())) {
                              return tdata;
                            }
                          }).map((tdata, index) => {
                            return (<>
                              <tr>
                                <td onClick={() => DownloadIntoLocal(tdata)}><i className={"fa fa-" + tdata?.type} ></i><a id="colorcss"> {tdata?.label}</a></td>
                                <td>{moment(tdata?.date).format('DD-MMM-yyyy')}</td>
                              </tr>
                            </>)
                          })
                        }
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="col-md-5">
                  <p>Accepted file type only:-<br /> ( .jpeg, .png, .gif, .xlsx, .xls, .word, .pdf, .mp4, .docx, .doc, .txt, .ppt, .pptx)</p>
                  <input type="file" id="fileJsUpload" ref={inputRef} onChange={(e) => onUpload(e)} />
                </div>
              </div>
            </>
          </Card.Body>
        </Card.Text>
      </Card.Body>
    </Card>
  )
}


