import React, { useEffect } from 'react'
import { Card } from 'react-bootstrap'
import { useState } from "react";
import { useNavigate } from 'react-router';
import axios from "axios";
import $ from 'jquery'
import { useDispatch } from "react-redux";
import { setLoader } from "../../Store/Reducers/UserReducer";
import moment from "moment";
import LoginFun from './../../Layout Component/Loginfun';
import Swal from "sweetalert2";
import './Prescription.css';
import MedicalHistoryNav from "./MedicalHistoryNav";

export default function Prescription() {
  const dispatch = useDispatch();
  const url = process.env.REACT_APP_OXAR_API_URL;
  const [prescription, setprescription] = useState([]);
  const navigate = useNavigate();
  const logindata = LoginFun();
  const tocomponentPrescriptionDetails = (Data) => {
    var RowidMain = Data.id
    localStorage.setItem("PrecriptionId", RowidMain);
    navigate('/PrescriptionDetail', { state: { RowidMain } });
  }

  useEffect(() => {
    dispatch(setLoader(true));
    setTimeout(() => {
      getAllPrescription();
    }, 1000);
    sessionCheck();
  }, []);

  const sessionCheck = async () => {
    var currentTime = ''
    currentTime = (Math.round(new Date().getTime() / 1000));
    var Expiretime = localStorage.getItem("ExpirretokenTime");
    if (Expiretime < currentTime) {
      debugger
      sessionStorage.getItem('isLoggedIn', false)
      Swal.fire({
        title: 'Session Expired',
        text: "Your session has been expired! Please login again",
        icon: 'warning',
        showCancelButton: false,
        confirmButtonText: 'Okay',
      }).then(result => {
        logindata.logout();
      })
    }
  }

  const getAllPrescription = async () => {
    try {
      $('#PrevTreatmentCycleTable').DataTable().destroy();
      let MainUserId = localStorage.getItem("UserID");
        await axios(`${url}api/Prescriptions/GetByContactId?id=${MainUserId}`).then((res) => {
          for (var i = 0; i < res.data.content.length; i++) {
            if (res.data.content[i].approvedDate === "0001-01-01T00:00:00") {
              res.data.content[i].approvedDate = "";
            }
            if (res.data.content[i].delivereDdate === "0001-01-01T00:00:00") {
              res.data.content[i].delivereDdate = "";
            }
          }
          setprescription(res.data.content); 
          $("#PrevTreatmentCycleTable").DataTable();
          dispatch(setLoader(false));
        });
    }
    catch {
      dispatch(setLoader(false));
    }
  };

  return (
    <>
      <div style={{ display: "flex", flexDirection: "column" }}>

        <MedicalHistoryNav />
        <Card className='border-0'>
          <Card.Body>
            <form>
              <div className="col-md-12 text-left">
                <div className='col-md-6 m-2'>
                </div>
                <div className="table-responsive">
                  <table class="table border-0" id="PrevTreatmentCycleTable">
                    <thead class="">
                      <tr>
                        <th> Name</th>
                        <th>Treatment Name </th>
                        <th>Drug Bundle</th>
                        <th>Prescription Cycle Name</th>
                        <th> Approved</th>
                        <th>PRESCRIPTION CYCLE</th>
                        <th>Approved Date</th>
                        <th>Created On</th>
                        <th>Delivered Date</th>
                        <th>Details</th>
                      </tr>
                    </thead>
                    <tbody>

                      {prescription && prescription.map((listValue, index) => {
                        return (
                          <tr>
                            <td>{listValue.name}</td>
                            <td>{listValue.treatmentName}</td>
                            <td>{listValue.drugBundle}</td>
                            <td>{listValue.prescriptionCycleName}</td>
                            <td> {listValue.approved === false ? "No" : "Yes"}</td>
                            <td>{listValue.prescriptionCycle}</td>
                           
                            <td>{moment(listValue?.approvedDate).format("DD-MMM-YYYY")}</td>
                            <td>{moment(listValue.createdOn).format("DD-MMM-YYYY")}</td>
                            <td>{moment(listValue.delivereDdate).format("DD-MMM-YYYY")}</td>
                            <td>
                            <a className='pointer' disabled={listValue.envelopeid === ""}  onClick={() => { tocomponentPrescriptionDetails(listValue) }} style={{ color: '#0088ff' }}>Details</a>                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                {/* <BootstrapTable
                  bootstrap4
                  keyField='id'
                  wrapperClasses="table-responsive"
                  pagination={pagination}
                  rowEvents={tableRowEvents}
                  filter={filterFactory()}
                  data={prescription}
                  columns={columns}
                  hover
                /> */}
              </div>
            </form>
          </Card.Body>
        </Card>
      </div>
    </>
  )
}
