import React, { useEffect } from 'react'
import { Card, Button } from 'react-bootstrap'
import { useState } from "react";
import axios from "axios";
import { Modal } from 'react-bootstrap';
import Swal from "sweetalert2";
import moment from "moment";
import $ from "jquery";
import LoginFun from './../../Layout Component/Loginfun';
import { useDispatch } from "react-redux";
import { setLoader } from "../../Store/Reducers/UserReducer";
import './PreviousFertilityTreatment.css';
import MedicalHistoryNav from "./MedicalHistoryNav";
import 'react-toastify/dist/ReactToastify.css';
import { Slide, Flip, Bounce } from 'react-toastify';
import { toast } from 'react-toastify';

export default function PreviousFertilityTreatment() {
    const dispatch = useDispatch();
    const url = process.env.REACT_APP_OXAR_API_URL;
    const [fertilityTreat, setfertilityTreat] = useState([]);
    const [updateallergies, setupdateallergies] = useState([]);
    const [show, setShow] = useState(false);
    const [data, setData] = useState();
    const [saveBtn, setsaveBtn] = useState(false);
    const [updateBtn, setupdateBtn] = useState(false);

    const handleClose = () => setShow(false);
    const checkSpecial = (e) => {
        if (!/^[a-zA-Z0-9\s]*$/.test(e.key)) {
            e.preventDefault();
        }
    }
    const handleShow = (Dataall) => {

        setTreatmentTypeName('');
        setData('');
        setShow(true);
        if (Dataall !== undefined) {
            setTimeout(() => {
                debugger
                if (Dataall.treatmentType === 271400000 || Dataall.treatmentType === 271400001) {
                    $(".numberofEggsCollected").hide();
                    $(".numberFertilised").hide();
                    $(".numberofEggsCollected").val("");
                    $(".numberFertilised").val("");
                }
            }, 1000);
        }
        if (Dataall !== undefined) {
            setupdateallergies(Dataall);
            setData(Dataall);
        }
    };

    const logindata = LoginFun();

    useEffect(() => {
        dispatch(setLoader(true));
        setTimeout(() => {
            getAllfertilityTreat();
        }, 1000);
        sessionCheck();
    }, []);

    const sessionCheck = async () => {
        var currentTime = ''
        currentTime = (Math.round(new Date().getTime() / 1000));
        var Expiretime = localStorage.getItem("ExpirretokenTime");
        if (Expiretime < currentTime) {
            sessionStorage.getItem('isLoggedIn', false)
            Swal.fire({
                title: 'Session Expired',
                text: "Your session has been expired! Please login again",
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: 'Okay',
            }).then(result => {
                logindata.logout();
            })
        }
    }

    const getAllfertilityTreat = async () => {
        dispatch(setLoader(true));
        try {
            let MainUserId = localStorage.getItem("UserID");
            await axios(`${url}api/Treatment/GetFertilityTreatmentByContactId?id=${MainUserId}`).then((res) => {
                setfertilityTreat(res.data.content);
                $("#PrevTreatmentCycleTable").DataTable();
                dispatch(setLoader(false));
            });
        }
        catch {
        }
    };
    const [yearofTreatment, setyearofTreatment] = useState("");
    const [treatmentTypeName, setTreatmentTypeName] = useState("");
    const [centre, setcentre] = useState("");
    const [spermSource, setspermSource] = useState("");
    const [pgdpgd, setpgdpgd] = useState("");
    const [numberofEggsCollected, setnumberofEggsCollected] = useState("");
    const [numberFertilised, setnumberFertilised] = useState("");
    const [numberofEmbryosFrozenDayofFreeze, setnumberofEmbryosFrozenDayofFreeze] = useState("");
    const [numberofEmbryosTransferred, setnumberofEmbryosTransferred] = useState("");
    const [outcome, setoutcome] = useState("");
    const [anyCycleComplications, setanyCycleComplications] = useState("");

    const handleSubmit = async () => {
        dispatch(setLoader(true));
        if (treatmentTypeName !== "") {
            let postdata = {
                yearofTreatment: yearofTreatment,
                treatmentType: treatmentTypeName,
                centre: centre,
                spermSource: spermSource,
                pgdpgd: pgdpgd,
                numberofEggsCollected: numberofEggsCollected,
                numberFertilised: numberFertilised,
                numberofEmbryosFrozenDayofFreeze: numberofEmbryosFrozenDayofFreeze,
                numberofEmbryosTransferred: numberofEmbryosTransferred,
                outcome: outcome,
                anyCycleComplications: anyCycleComplications,
            }

            if (postdata.treatmentType === "271400000" || postdata.treatmentType === "271400001") {
                postdata.numberFertilised = "";
                postdata.numberofEggsCollected = "";
            }

            try {
                let MainUserId = localStorage.getItem("UserID");
                const res = await axios.post(`${url}api/Treatment/CreateFertilityTreatment?userId=${MainUserId}`, postdata);
                if (res.status === 200) {
                    toast.success('Previous fertility created successfully!', {
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        autoClose: 3000,
                        hideProgressBar: true,
                        transition: Bounce,
                        className: 'toast-message'
                    }
                    )
                    setTreatmentTypeName("");
                    setyearofTreatment("");
                    setcentre("");
                    setspermSource("");
                    setpgdpgd("");
                    setnumberofEggsCollected("");
                    setnumberFertilised("");
                    setnumberofEmbryosTransferred("");
                    setoutcome("");
                    setanyCycleComplications("");
                    setnumberofEmbryosFrozenDayofFreeze("");
                    setShow(false);
                    getAllfertilityTreat();
                    dispatch(setLoader(false));
                }
            }
            catch (e) {
                dispatch(setLoader(false));
            }
        }
        else {
            toast.warn('Please fill mandatory fields', {
                autoClose: 5000,
                hideProgressBar: true,
                newestOnTop: false,
                closeOnClick: true,
                rtl: false,
                pauseOnFocusLoss: true,
                draggable: true,
                pauseOnHover: true,
                className: 'toast-warn'
            }
            )
            dispatch(setLoader(false));
            return
        }

    }

    const updateAllergies = async (updatedvalue) => {
        var name = updatedvalue.target.name;
        // var value = updatedvalue.target.value;
        if (name === "pgdpgd") {
            var value1 = updatedvalue.target.checked;
            value = value1
            setpgdpgd(value1);
        }
        else {
            var value = updatedvalue.target.value;
        }
        if (name === "treatmentType") {
            if (value === "271400000" || value === "271400001") {
                setnumberFertilised('');
                setnumberofEggsCollected('');
                $(".numberofEggsCollected").hide();
                $(".numberFertilised").hide();
            }
            else {
                $(".numberofEggsCollected").show();
                $(".numberFertilised").show();
            }
        }
        setupdateallergies(values => ({ ...values, [name]: value, 'id': data?.id }));
    }

    // Update procedure Data API
    const updateAllergiesData = async (e, data) => {
        sessionCheck();
        dispatch(setLoader(true));
        e.preventDefault();
        let MainUserId = localStorage.getItem("UserID");
        if (updateallergies.treatmentType === "271400000" || updateallergies.treatmentType === "271400001") {
            updateallergies.numberFertilised = "";
            updateallergies.numberofEggsCollected = "";
        }
        try {
            const res = await axios.put(`${url}api/Treatment/UpdateFertilityTreatment?userId=${MainUserId}`, updateallergies);

            if (res.status === 200) {
                toast.success('Previous fertility updated successfully!', {
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    autoClose: 3000,
                    hideProgressBar: true,
                    transition: Slide,
                    className: 'toast-message'
                }
                )
                setTreatmentTypeName("");
                setyearofTreatment("");
                setcentre("");
                setspermSource("");
                setpgdpgd("");
                setnumberofEggsCollected("");
                setnumberFertilised("");
                setnumberofEmbryosTransferred("");
                setoutcome("");
                setanyCycleComplications("");
                setnumberofEmbryosFrozenDayofFreeze("");
                setShow(false);
                getAllfertilityTreat();
                dispatch(setLoader(false));
            }
        }
        catch (e) {
        }
    }

    // Delete fertility treatment Data API
    const deleteData = async (item) => {

        try {
            Swal.fire({
                title: 'Are you sure?',
                text: 'Do you want to delete ?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if (result.isConfirmed) {

                    dispatch(setLoader(true));
                    axios.delete(`${url}api/Treatment/DeleteFertilityTreatment?id=${item}`).then((res => {
                        if (res.status === 200) {
                            getAllfertilityTreat();
                            toast.success('Your record has been deleted', {
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                autoClose: 3000,
                                hideProgressBar: true,
                                transition: Flip,
                                className: 'toast-message'
                            }
                            )
                            dispatch(setLoader(false));
                        }
                        else {
                            toast.error('error while deleteing previous fertility', {
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                autoClose: 3000,
                                hideProgressBar: true,
                                transition: Flip,
                                theme: "colored"
                            }
                            )
                        }
                    }))

                }
            })
        }
        catch (e) {
        }
    }

    return (
        <div style={{ display: "flex", flexDirection: "column" }}>
            <MedicalHistoryNav />
            <Card className="cardcolor">
                <Card.Body>
                    <Card.Text>
                        <div className="col text-left   ">
                            <div className='d-flex m-2 '>
                                <div className='col-md-6'>
                                </div>
                                <div className='col-md-6 d-grid gap-2 d-md-flex justify-content-md-end'>
                                    <button className='btn btn-primary ' onClick={() => handleShow(setsaveBtn(true), setupdateBtn(false))}>Add Fertility Treatment +</button>
                                </div>
                            </div>
                            <div className="table-responsive">
                                <table class="table border-0" id="PrevTreatmentCycleTable">
                                    <thead class="">
                                        <tr>
                                            <th>Year</th>
                                            <th>Treatment </th>
                                            <th>Center</th>
                                            <th>Created On</th>
                                            <th>Sperm Source </th>
                                            <th>PGS or PGD</th>
                                            <th>Number of Eggs collected</th>
                                            <th>Number of Eggs Fertilised </th>
                                            <th>Number of Embryos Frozen'</th>
                                            <th>Number of Embryos Transferred</th>
                                            <th>Delete/Edit</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {fertilityTreat && fertilityTreat.map((listValue, index) => {
                                            return (
                                                <tr>
                                                    <td>{listValue.yearofTreatment}</td>
                                                    <td>{listValue.treatmentTypeName}</td>
                                                    <td>{listValue.centre}</td>
                                                    <td>
                                                        {moment(listValue.createdOn).format("DD-MMM-yyyy") ? moment(listValue.createdOn).format("DD-MMM-yyyy") : moment(listValue.createdOn).format("DD-MMM-yyyy")}</td>
                                                    <td>{listValue.spermSource}</td>
                                                    <td>{listValue.pgdpgd == false ? "No" : "Yes"}</td>
                                                    <td>{listValue.numberofEggsCollected}</td>
                                                    <td>{listValue.numberFertilised}</td>
                                                    <td>{listValue.numberofEmbryosFrozenDayofFreeze}</td>
                                                    <td>{listValue.numberofEmbryosTransferred}</td>
                                                    <td>
                                                        <i className="fa-solid fa-trash" onClick={() => deleteData(listValue.id)}></i><span>{" "}{" "}</span><span>{" "}{" "}</span>
                                                        <i className="fa-solid fa-pencil" disabled={listValue.envelopeid === ""} onClick={() => handleShow(listValue, setupdateBtn(true), setsaveBtn(false))}></i>
                                                    </td>

                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <Modal size="lg" show={show} onHide={handleClose} >
                            <form>
                                <Modal.Header closeButton>
                                    {saveBtn ?
                                        <h6> Add Previous Fertility Treatment</h6>
                                        :
                                        <h6> Edit Previous Fertility Treatment</h6>
                                    }
                                </Modal.Header>
                                <Modal.Body >
                                    <div class='row'>
                                        <label className='col-sm-4 col-form-label ' >Treatment <span className="required"></span> : </label>
                                        <div className="col-sm-7 ">
                                            <select className="form-select  m-1  border-1" id="floatingSelect" aria-label="Floating label select example" name="treatmentType"
                                                defaultValue={data?.treatmentType} onChange={(e) => updateAllergies(e, setTreatmentTypeName(e.target.value))}>
                                                <option value="">Select</option>
                                                <option value="87600016">IVF</option>
                                                <option value="271400000">IUI</option>
                                                <option value="271400001">Ovulation Induction</option>
                                                <option value="338482017">ICSI</option>
                                                <option value="236896006">DI</option>
                                                <option value="236894009">FET</option>
                                                <option value="441369004">Thawing for screening</option>
                                                <option value="440645004">Egg freezing</option>
                                                <option value="236912008">GIFT</option>
                                                <option value="430882007">Egg donation only</option>
                                                <option value="1">MST</option>
                                                <option value="2">PNT</option>
                                            </select>
                                        </div>
                                        <label className='col-sm-4' >Year : </label>
                                        <div className="col-sm-7">
                                            <input type='number' placeholder='Year' className='form-control m-1    border-1' defaultValue={data?.yearofTreatment} name="yearofTreatment" onChange={(e) => updateAllergies(e, setyearofTreatment(e.target.value))} />
                                        </div>
                                        <label className='col-sm-4  ' >Center  : </label>
                                        <div className="col-sm-7">
                                            <input className='form-control m-1   border-1' name="centre" defaultValue={data?.centre} onKeyDown={(e) => checkSpecial(e)}
                                                onChange={(e) => updateAllergies(e, setcentre(e.target.value))} />
                                        </div>
                                        <label className='col-sm-4' >Sperm Source  : </label>
                                        <div className="col-sm-7">
                                            <input className='form-control m-1   border-1' name="spermSource" defaultValue={data?.spermSource} onKeyDown={(e) => checkSpecial(e)}
                                                onChange={(e) => updateAllergies(e, setspermSource(e.target.value))} />
                                        </div>

                                        <label className='col-sm-4 ' >Number of Embryos Frozen  : </label>
                                        <div className="col-sm-7">
                                            <input className='form-control m-1   border-1' name="numberofEmbryosFrozenDayofFreeze"
                                                defaultValue={data?.numberofEmbryosFrozenDayofFreeze} onKeyDown={(e) => checkSpecial(e)}
                                                onChange={(e) => updateAllergies(e, setnumberofEmbryosFrozenDayofFreeze(e.target.value))} />
                                        </div>
                                        <label className='col-sm-4 ' >Number of Embryos Transferred: </label>
                                        <div className="col-sm-7">
                                            <input className='form-control m-1 border-1' name="numberofEmbryosTransferred"
                                                defaultValue={data?.numberofEmbryosTransferred} onKeyDown={(e) => checkSpecial(e)}
                                                onChange={(e) => updateAllergies(e, setnumberofEmbryosTransferred(e.target.value))} />
                                        </div>
                                        <label className='col-sm-4 ' >Any cycle complications:  : </label>
                                        <div className="col-sm-7">
                                            <input className='form-control m-1 border-1' name="anyCycleComplications" defaultValue={data?.anyCycleComplications} onKeyDown={(e) => checkSpecial(e)}
                                                onChange={(e) => updateAllergies(e, setanyCycleComplications(e.target.value))} />
                                        </div>
                                        <label className='col-sm-4 ' >PGS or PGD </label>
                                        <div className="col-sm-7 ">
                                            <div className=" form-check form-switch p-0 mt-1">
                                                <label class="switch">
                                                    <input type="checkbox" name='pgdpgd'
                                                        defaultChecked={data?.pgdpgd === true}
                                                        onChange={(e) => updateAllergies(e, setpgdpgd(e.target.value))} />
                                                    <div class="slider round">
                                                        <span className="on">Yes</span>
                                                        <span className="off">No</span>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>

                                        <label className='col-sm-4 col-form-label ' >Outcome : </label>
                                        <div className="col-sm-7">
                                            <textarea className='form-control m-1 border-1' name="outcome" defaultValue={data?.outcome}
                                                onKeyDown={(e) => checkSpecial(e)} onChange={(e) => updateAllergies(e, setoutcome(e.target.value))} />
                                        </div>

                                        <label className='col-sm-4  numberofEggsCollected' id='numberofEggsCollected' >Number of Eggs Collected  : </label>
                                        <div className="col-sm-7 numberofEggsCollected">
                                            <input className='form-control m-1   border-1 numberofEggsCollected' id='numberofEggsCollected' onKeyDown={(e) => checkSpecial(e)}
                                                name="numberofEggsCollected" defaultValue={data?.numberofEggsCollected} onChange={(e) => updateAllergies(e, setnumberofEggsCollected(e.target.value))} />
                                        </div>

                                        <label className='col-sm-4 numberFertilised' id="numberFertilised" >Number of Eggs Fertilised  : </label>
                                        <div className="col-sm-7 numberFertilised">
                                            <input className='form-control m-1  numberFertilised border-1' name="numberFertilised" defaultValue={data?.numberFertilised}
                                                onChange={(e) => updateAllergies(e, setnumberFertilised(e.target.value))} onKeyDown={(e) => checkSpecial(e)} />
                                        </div>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    {updateBtn && <Button type="button" className='btn btn-primary me-2' onClick={(e) => updateAllergiesData(e,)} >Update</Button>}
                                    {saveBtn && <Button type="button" onClick={handleSubmit} className=' btn btn-primary  me-2'  >Save</Button>}
                                    <Button className=' btn btn-primary ' onClick={handleClose}>Close</Button>
                                </Modal.Footer>
                            </form>
                        </Modal>
                    </Card.Text>
                </Card.Body>
            </Card>
        </div>
    )
}
