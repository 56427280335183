import React, { useEffect } from 'react'
import { Card } from 'react-bootstrap'
import { useState } from "react";
import { useNavigate } from 'react-router';
import axios from "axios";
import $ from 'jquery';
import moment from "moment";
import { useDispatch } from "react-redux";
import { setLoader } from '../Store/Reducers/UserReducer';
import LoginFun from '../Layout Component/Loginfun';
import Swal from "sweetalert2";
import './Checklist.css';
var UserData;
export default function Checklist() {
    UserData = JSON.parse(localStorage.getItem("UData"));
    const dispatch = useDispatch();
    const url = process.env.REACT_APP_OXAR_API_URL;
    const navigate = useNavigate();
    const [checklist, setchecklist] = useState([]);
    const logindata = LoginFun();
    var rowId = "";
    const toComponentchecklistDetails = (data) => {
        var CheckListId = data.id;
        localStorage.setItem("CheckListID", CheckListId);
        navigate('/CheckListDetails', { state: { rowId } });
    }

    useEffect(() => {
        dispatch(setLoader(true));
        setTimeout(() => {
            getInvoices();
        }, 1000);
        sessionCheck()
    }, []);

    const sessionCheck = async () => {
        var currentTime = ''
        currentTime = (Math.round(new Date().getTime() / 1000));
        var Expiretime = localStorage.getItem("ExpirretokenTime");
        if (Expiretime < currentTime) {
            sessionStorage.getItem('isLoggedIn', false)
            Swal.fire({
                title: 'Session Expired',
                text: "Your session has been expired! Please login again",
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: 'Okay',
            }).then(result => {
                logindata.logout();
            })
        }
    }

    const getInvoices = async () => {
        try {
            dispatch(setLoader(true));
            let MainUserId = localStorage.getItem("UserID");
            await axios(`${url}api/Profile/GetChecklistbyPatientID?id=${MainUserId}`).then((res) => {
                setchecklist(res.data.content);
                $("#treatmentCycleTable").dataTable();
                dispatch(setLoader(false));
            });
        } catch {
            dispatch(setLoader(false));
        }
    };

    return (
        <div style={{ display: "flex", flexDirection: "column" }}>
            <Card className=' border-0 cardcolor'>
                <div className='card-header mb-2'>
                    <div className="row">
                        <div className="col">
                            <h5>Checklist </h5>
                        </div>
                        <div className="col text-right">
                            ( {UserData?.firstName} {UserData?.lastName} , {moment(UserData?.birthday).format("DD-MMM-YYYY")} ({UserData?.age}) ,{UserData?.gendercodeName} )
                        </div>
                    </div>
                </div>                    <Card.Body>
                    <Card.Text>
                        {/* step wizard for Medical History */}
                        <div className='col-md-12 '>
                            <Card.Body>
                                <div className="col-md-12 text-left ">
                                    <div className="table-responsive">
                                        <table class="table border-0" id="treatmentCycleTable">
                                            <thead >
                                                <tr>
                                                    <th>Name</th>
                                                    <th>Checklist Bundle</th>
                                                    <th>Created On</th>
                                                    <th>Details</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {checklist && checklist.map((listValue, index) => {
                                                    return (
                                                        <tr>
                                                            <td>{listValue.name}</td>
                                                            <td>{listValue.checklistbundleName}</td>
                                                            <td>{moment(listValue.createdon).format("DD-MMM-yyyy") ? moment(listValue.createdon).format("DD-MMM-yyyy") : moment(listValue.createdon).format("DD-MMM-yyyy")}</td>
                                                            <td><a className='pointer' disabled={listValue.envelopeid === ""} onClick={() => { toComponentchecklistDetails(listValue) }} style={{ color: '#0dcaf0' }}>Details</a></td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </Card.Body>
                        </div>
                    </Card.Text>
                </Card.Body>
            </Card>
        </div>
    )
}
