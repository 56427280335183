import React from 'react'

export default function Thank() {
    return (
        <div> <br></br><br></br><br></br><br /><br />
            <div className='container '>
                <div className="row mb-3">
                    <div className="card">
                        <div className="card-body">
                            <div className="card-text">
                                <div className="card-body text-center">
                                    <h4>Thank You For Submission</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
