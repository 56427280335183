import { createSlice } from "@reduxjs/toolkit";

export const UserReducer = createSlice({
  name: "user",
  initialState: {
    QuestionairData: {},
    userData: {
      name: "",
      IsLoading :false
    },
  },
  reducers: {
    setUser: (state, action) => {
      state.userData = action.payload;
    },
    setQuestionairData: (state, action) => {
      debugger;
      state.QuestionairData = action.payload;
  },
    setLoader: (state, action) => {
      state.IsLoading = action.payload;
    },
    resetUser: (state) => {
      state.userData = {
        name: ""
      }
    }
  },
});
export const { setUser, resetUser,setLoader,setQuestionairData } = UserReducer.actions;

export const setUserData = (data) => (dispatch) => {
  dispatch(setUser(data));
};

export const getUser = (state) => state.user.userData;
export const getLoader = (state)=>state.user.IsLoading;

export default UserReducer.reducer;