import axios from "axios";
import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { useSelector } from "react-redux";
import { getUser } from "../../Store/Reducers/UserReducer";
import Body from "./MedicalStep1";
import MedicalStepFoot from "./MedicalStepFoot";
import MedicalStepHead from "./MedicalStepHead";
import MedicalHistoryNav from "./MedicalHistoryNav";
import Swal from "sweetalert2";
import "react-pro-sidebar/dist/css/styles.css";
import "../Sidebar.css";
import LoginFun from "../../Layout Component/Loginfun";
import { useDispatch } from "react-redux";
import { setLoader } from "../../Store/Reducers/UserReducer"
import { toast } from 'react-toastify';

export default function MedicalHistory() {
  const dispatch = useDispatch();
  const url = process.env.REACT_APP_OXAR_API_URL;
  const [MedicalHistory, setMedicalHistory] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const uData = useSelector(getUser);

  const [stat, setStat] = useState({
    name: "React",
    comp: () => <Body />,
  });

  const [index, setIndex] = useState(0);
  const [steps, setSteps] = useState([
    import("./MedicalStep1"),
    import("./MedicalStep2"),
  ]);
  const logindata = LoginFun();
  const next = () => {
    let ndx = index;

    if(MedicalHistory?.heightinMetres != "" && MedicalHistory?.heightinMetres != null  && MedicalHistory.weightinKilograms !="" && MedicalHistory?.weightinKilograms != null && MedicalHistory.haveYouEverHadAnySexuallyTransmittedDisease != 0
    && MedicalHistory.doYouHaveAnyMedicalConditionsEgAsthmae != 0 && MedicalHistory.areyoutakinganyMedicationsCurrently !=0 && MedicalHistory.doYouSmoke != 0
    && MedicalHistory.doYouDrinkAlcohol != 0 && MedicalHistory.doYouUseReCreationalDrugs != 0 && MedicalHistory.haveYouHadFertilityInvestigationsinthePast !=0
    && MedicalHistory.haveYouEverHadAssistedConceptionTreatment !=0
    ){

      UpdateMedicalData(MedicalHistory)
      ndx++;
      setIndex(ndx);
      loadComponent(ndx);
    }
    else if (MedicalHistory?.heightinMetres > 3 || MedicalHistory?.heightinMetres < 0) {
      toast.warn('Height field in meter should be in 0-3', {
        autoClose: 5000,
        hideProgressBar: true,
        newestOnTop: false,
        closeOnClick: true,
        rtl: false,
        pauseOnFocusLoss: true,
        draggable: true,
        pauseOnHover: true,
        className: 'toast-warn'
      }
      )
    } else {
      // UpdateMedicalData(MedicalHistory)
      // ndx++;
      // setIndex(ndx);
      // loadComponent(ndx);
      toast.warn('Please fill mandatory fields', {
        autoClose: 5000,
        hideProgressBar: true,
        newestOnTop: false,
        closeOnClick: true,
        rtl: false,
        pauseOnFocusLoss: true,
        draggable: true,
        pauseOnHover: true,
        className: 'toast-warn'
      }
      )
      return
    }
    
  };

  const previous = () => {
    
    let ndx = index;
    ndx--;
    setIndex(ndx);
    loadComponent(ndx);
  };

  const loadComponent = (ndx) => {
    
    let component = steps[ndx];
    component.then((comp) => {
      setStat({ comp: comp.default });
    });
  };

  const navigateTo = (ndx) => {
    
    UpdateMedicalData(MedicalHistory)
    window.scrollTo(0, 0);
    setIndex(ndx);
    loadComponent(ndx);
  };

  useEffect(() => {
    dispatch(setLoader(true));
    setTimeout(() => {
      getMedicalHistory();
    }, 1000);
    loadComponent(0)
    sessionCheck();
  }, []);

  const sessionCheck = async () => {
    var currentTime = ''
    currentTime = (Math.round(new Date().getTime() / 1000));
    var Expiretime = localStorage.getItem("ExpirretokenTime");
    if (Expiretime < currentTime) {
      sessionStorage.getItem('isLoggedIn', false)
      Swal.fire({
        title: 'Session Expired',
        text: "Your session has been expired! Please login again",
        icon: 'warning',
        showCancelButton: false,
        confirmButtonText: 'Okay',
      }).then(result => {
        logindata.logout();
      })
    }
  }
  //************************************ Getting Medical History Data******************************************************

  const getMedicalHistory = async () => {
    
    try {

      let MainUserId = localStorage.getItem("UserID");
        await axios(
          `${url}api/Profile/GetQuestionairebyPatientId?id=${MainUserId}`).then((res) => {
            
            res.data.content.whatDateWasYourLastMenstrualPeriod = res.data.content.whatDateWasYourLastMenstrualPeriod.substring(0, 10);
            res.data.content.whenWasYourLastSmearTest = res.data.content.whenWasYourLastSmearTest.substring(0, 10);
            if (res.data.content.whenWasYourLastSmearTest === "0001-01-01") {
              res.data.content.whenWasYourLastSmearTest = "";
            }
            if (res.data.content.whatDateWasYourLastMenstrualPeriod === "0001-01-01") {
              res.data.content.whatDateWasYourLastMenstrualPeriod = "";
            }
            setMedicalHistory(res.data.content);
          });
        dispatch(setLoader(false));
    } catch {
    }
  };


  const UpdateMedicalData = (DTO) => {
    sessionCheck();
    
    let MainUserId = localStorage.getItem("UserID");
    axios.put(`${url}/api/Profile/UpdateQuestionaire?userId=${MainUserId}`, DTO).then((response) => {
      if (response.data.statusCode === 200) {
        //   Swal.fire({
        //     icon:'success',
        //     text: 'Details updated successfully'
        // })
      }
      setIsLoading(false);

    }, (error) => {


      setIsLoading(false);
      return

    });
  }


  return (
    <div style={{ display: "flex", flexDirection: "column" }}>

      <MedicalHistoryNav />
      <Card className="border-0  ">
    
          <Card.Body>
            <div className="step-progress">
              <div className="justify-content-center">
                <MedicalStepHead
                  uData={uData}
                  navigateTo={navigateTo.bind(this)}
                  stepCount={steps.length}
                  step={index}
                />
              </div>

              <div className="stepsPlaceHolder justfiy-content-center  ">
                <stat.comp
                  next={next.bind(this)}
                  setIsLoading={setIsLoading}
                  isLoading={isLoading}
                  MedicalHistory={MedicalHistory}
                  setMedicalHistory={setMedicalHistory}

                />
              </div>
              <MedicalStepFoot
                uData={uData}
                step={index}
                stepCount={steps.length}
                MedicalHistory={MedicalHistory}
                next={next.bind(this)}
                previous={previous.bind(this)}
              />
            </div>
          </Card.Body>
    
      </Card>
    </div>
  );
}
