import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { setQuestionairData } from '../Store/Reducers/UserReducer';
import Multiselect from 'multiselect-react-dropdown';
import axios from 'axios';
import { GettheHmToken } from '../services/AllApiCall';
import { useNavigate } from 'react-router';
import ReactSignatureCanvas from 'react-signature-canvas';
import { toast } from 'react-toastify';
import moment from "moment";

export default function PatientForm() {
    const padRef = useRef(null);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const { QuestionairData } = useSelector((state) => state.user);
    const [Submitted, setSubmitted] = useState(false);

    const [PatientName, setPatientName] = React.useState("");
    const [Age, setAge] = React.useState("");
    const [Email, setEmail] = React.useState("");
    const [Gender, setGender] = React.useState("");
    const [birthdateMain, setbirthdateMain] = React.useState("");
    const [birthdate, setbirthdate] = React.useState("");

    useEffect(() => {
        var BirthDate = localStorage.getItem("PatientBirthdate");
        var FullName =   localStorage.getItem("PatientFullName");
        var Age =   localStorage.getItem("PatientAge");
        var Email =   localStorage.getItem("PatientEmail");
        var Gender =   localStorage.getItem("PatientGender");
           setAge(Age)
           setEmail(Email)
           setGender(Gender)
           setPatientName(FullName)
           if(BirthDate != ""){
               setbirthdate(BirthDate.substring(0, 10))
               var dateall =`${moment(BirthDate).format("DD-MM-YYYY") ? moment(BirthDate).format("DD-MM-YYYY") : moment(BirthDate).format("DD-MM-YYYY")}`
               setbirthdateMain(dateall)
           }
        if (QuestionairData?.submittedDate !== '0001-01-01T00:00:00') {
            setSubmitted(true);
        }
    }, [])

    const SubmitForm = async () => {
        setIsLoading(true)
        await axios.put(`https://malefertilityapi.azurewebsites.net/api/Profile/UpdateQuestionaireFemale?userId=${localStorage.getItem('PId')}`, {
            ...QuestionairData, 'submittedDate': new Date(), 'Patientsignature': padRef?.current?.toDataURL()
        }, { headers: { "Authorization": localStorage.getItem("HMTokenForApis") } }).then((res) => {
            if (res.data.statusCode === 200) {
                toast.success('Submitted successfully')
                navigate('/Thank')
            }
            setIsLoading(false);
        }, (error) => {
            if (error.response.data["errorMessage"] === "Token Expire") { GettheHmToken(); }
            return setIsLoading(false);
        });
    }

    const InputText = (named, label, format, Val, place) => {
        return <div className="col-md-6 pt-2">
            <div className="row">
                <div className="col-md-5">{label}</div>
                <div className="col-md-7">
                    {format === 'textArea' ?
                        <textarea name={named} disabled={Submitted}
                            onChange={(e) => dispatch(setQuestionairData({ ...QuestionairData, [e.target.name]: e.target.value }))}
                            className='form-control TextAreaHeight' value={Val}
                            rows="3"></textarea>
                        :
                        <input className='form-control' name={named} disabled={Submitted}
                            onChange={(e) => dispatch(setQuestionairData({ ...QuestionairData, [e.target.name]: e.target.value }))}
                            value={format === 'date' ? Val === '0001-01-01T00:00:00' ? '' : Val.slice(0, 10) : Val} type={format}
                            placeholder={format === 'number' ? 'Provide a number' : place}
                        />
                    }
                </div>
            </div>
        </div>
    }

    const InputSelect = (label, names, val, options) => {
        return <div className="col-md-6 pt-2">
            <div className="row">
                <div className="col-md-5">
                    {label}
                </div>
                <div className="col-md-7">
                    <select className="form-select"
                        name={names} value={val} disabled={Submitted}
                        onChange={(e) => dispatch(setQuestionairData({ ...QuestionairData, [e.target.name]: e.target.value }))}>
                        <option >Please select</option>
                        {options?.map(option => (<option key={option.value} value={option.value}>{option.label}</option>))}
                    </select>
                </div>
            </div>
        </div>
    }

    return (
        <div> <br></br><br></br><br></br><br />
            {isLoading && (<div className="loader-container"><div className="spinner"></div></div>)}

            <div className="card mx-5 p-2">
                <div className='container mb-3'>
                <div><h4>{PatientName}, {birthdateMain}, {Gender}</h4></div>
                    <div className="card p-2 bg-white">
                        <h4>Fertility History</h4>
                        <div className="row mx-1">
                            {/* {InputText('anyofthefollowingfertilitytreatmentsbeforValue', 'Any of the following fertility treatments before?', 'text', QuestionairData?.anyofthefollowingfertilitytreatmentsbeforValue)} */}
                            <div className="col-md-6 pt-2">
                                <div className="row">
                                    <div className="col-md-5">
                                        Any of the following fertility treatments before?
                                    </div>
                                    <div className="col-md-7">
                                        <Multiselect disabled={Submitted}
                                            options={[
                                                { id: 1, name: "IVF" },
                                                { id: 2, name: " ICSI / Microinjection" },
                                                { id: 3, name: " Artificial insemination with partner" },
                                                { id: 4, name: " Donor Insemination" },
                                                { id: 5, name: " Other" }
                                            ]}
                                            placeholder="fertility treatments" name="anyofthefollowingfertilitytreatmentsbeforValue"
                                            selectedValues={QuestionairData?.anyofthefollowingfertilitytreatmentsbeforValue}
                                            onSelect={(data) => dispatch(setQuestionairData({ ...QuestionairData, 'anyofthefollowingfertilitytreatmentsbeforValue': data }))}
                                            onRemove={(data) => dispatch(setQuestionairData({ ...QuestionairData, 'anyofthefollowingfertilitytreatmentsbeforValue': data }))}
                                            displayValue="name" />
                                    </div>
                                </div>
                            </div>

                            {InputText('years', 'Year', 'text', QuestionairData?.years, 'Year')}
                            {InputText('numberofcycles', 'Number of Cycles', 'number', QuestionairData?.numberofcycles, 'Number of Cycles')}
                            {InputText('clinicname', 'Clinic Name', 'text', QuestionairData?.clinicname, 'Clinic Name')}
                            {InputText('specialist', 'Specialist', 'text', QuestionairData?.specialist, 'Specialist')}
                            {InputText('commentsonoutcomes', 'Comments on Outcomes', 'textArea', QuestionairData?.commentsonoutcomes, 'Comment here')}
                        </div>
                    </div>
                </div>
                <div className='container mb-3'>
                    <div className="card p-2 bg-white">
                        <h4>Fertility History for Current Relationship</h4>
                        <div className="row mx-1">
                            {InputText('monthyearpregnancyfirstattempted', 'Month/Year pregnancy first attempted', 'date', QuestionairData?.monthyearpregnancyfirstattempted)}
                            {InputText('numberofmiscarriagescurrentrelationships', 'Number of miscarriages', 'number', QuestionairData?.numberofmiscarriagescurrentrelationships)}
                            {InputText('numberofpregnanciescurrentrelationships', 'Number of pregnancies', 'number', QuestionairData?.numberofpregnanciescurrentrelationships)}
                            {InputText('noofectopicpregnanciescurrentrelationship', 'Number of ectopic pregnancies', 'number', QuestionairData?.noofectopicpregnanciescurrentrelationship)}
                            {InputText('yearinfertilitydiagnosed', 'Year infertility diagnosed', 'text', QuestionairData?.yearinfertilitydiagnosed, 'Year infertility diagnosed')}
                            {InputText('numberoflivebirthscurrentrelationships', 'Number of live births', 'number', QuestionairData?.numberoflivebirthscurrentrelationships)}
                            {InputText('numberofstillbirthscurrentrelationships', 'Number of still births', 'number', QuestionairData?.numberofstillbirthscurrentrelationships)}
                            {InputText('numberofdeliveriescurrentrelationships', 'Number of deliveries', 'number', QuestionairData?.numberofdeliveriescurrentrelationships)}
                            {InputText('othercurrentrelationships', 'Other', 'text', QuestionairData?.othercurrentrelationships, 'Other')}
                        </div>
                    </div>
                </div>
                <div className='container mb-3'>
                    <div className="card p-2 bg-white">
                        <h4>Fertility history for previous relationships not including current partner</h4>
                        <div className="row mx-1">
                            {InputText('noofpregnanciespreviousrelationshipsfemal', 'Number of pregnancies', 'number', QuestionairData?.noofpregnanciespreviousrelationshipsfemal)}
                            {InputText('noofectopicpregnanciespreviousrelationshi', 'Number of ectopic pregnancies', 'number', QuestionairData?.noofectopicpregnanciespreviousrelationshi)}
                            {InputText('noofpregnanciespreviousrelationshipsmale', 'Number of pregnancies', 'number', QuestionairData?.noofpregnanciespreviousrelationshipsmale)}
                            {InputText('nooflivebirthspreviousrelationships', 'Number of live births', 'number', QuestionairData?.nooflivebirthspreviousrelationships)}
                            {InputText('noofstillbirthspreviousrelationships', 'Number of still births', 'number', QuestionairData?.noofstillbirthspreviousrelationships)}
                            {InputText('noofdeliveriespreviousrelationships', 'Number of deliveries', 'number', QuestionairData?.noofdeliveriespreviousrelationships)}
                            {InputText('noofmiscarriagespreviousrelationships', 'Number of miscarriages', 'number', QuestionairData?.noofmiscarriagespreviousrelationships)}
                            {InputText('otherpreviousrelationships1', 'Other', 'text', QuestionairData?.otherpreviousrelationships1, 'Other')}
                            {InputText('otherpreviousrelationships2', 'Other', 'text', QuestionairData?.otherpreviousrelationships2, 'Other')}
                        </div>
                    </div>
                </div>
                <div className='container mb-3'>
                    <div className="card p-2 bg-white">
                        <h4>Female</h4>
                        <div className="row mx-1">
                            {InputText('howoldwereyouwhenyoustartedperiodsmenarch', 'How old were you when you started periods (Menarche)?', 'number', QuestionairData?.howoldwereyouwhenyoustartedperiodsmenarch, 'How old were you')}
                            {InputSelect('Do you have bleeding between periods?', 'doYouHaveBleedingBetweenPeriods', QuestionairData?.doYouHaveBleedingBetweenPeriods, [
                                { value: 271400000, label: 'Yes' },
                                { value: 271400001, label: 'No' }])}
                            {InputText('whatIsTheNormalLengthofBleedingDays', 'What is the normal length of bleeding (days)?', 'text', QuestionairData?.whatIsTheNormalLengthofBleedingDays, 'Length of bleeding (days)')}
                            {InputText('whatDateWasYourLastMenstrualPeriod', 'What date was your last menstrual period?', 'date', QuestionairData?.whatDateWasYourLastMenstrualPeriod)}
                            {InputSelect('Do you suffer from painful periods (Dysmenorrhoea)', 'doYouSufferfromPainfulPeriodsDysmenorrhoea',
                                QuestionairData?.doYouSufferfromPainfulPeriodsDysmenorrhoea, [
                                { value: 271400000, label: 'Yes' },
                                { value: 271400001, label: 'No' }])}
                            {InputSelect('Do you suffer from heavy periods (Menorrhagia)?', 'doYouSufferfromHeavyPeriodsMenorrhagia', QuestionairData?.doYouSufferfromHeavyPeriodsMenorrhagia, [
                                { value: 271400000, label: 'Yes' },
                                { value: 271400001, label: 'No' }])}
                            {InputSelect('Do you experience bleeding after intercourse?', 'doYouExperienceBleedingAfterIntercourse', QuestionairData?.doYouExperienceBleedingAfterIntercourse, [
                                { value: 271400000, label: 'Yes' },
                                { value: 271400001, label: 'No' }])}
                            {InputSelect('Have you ever had any previous pelvic infections?', 'haveYouEverHadAnyPreviousPelvicInfections', QuestionairData?.haveYouEverHadAnyPreviousPelvicInfections, [
                                { value: 271400000, label: 'Yes' },
                                { value: 271400001, label: 'No' }])}
                            {InputText('whenWasYourLastSmearTest', 'When was your last smear test?', 'date', QuestionairData?.whenWasYourLastSmearTest)}
                            {InputSelect('What was the smear test result?', 'whatWasYourLastSmearTestResult', QuestionairData?.whatWasYourLastSmearTestResult, [
                                { value: 271400000, label: 'Normal' },
                                { value: 271400001, label: 'Abnormal' }])}

                             {InputSelect('Are you currently using any contraceptives?', 'areyouCurrentlyUsingAnyContraceptives', QuestionairData?.areyouCurrentlyUsingAnyContraceptives, [
                                { value: 271400000, label: 'Yes' },
                                { value: 271400001, label: 'No' }])}
                                {
                                (QuestionairData?.areyouCurrentlyUsingAnyContraceptives === 271400000 || QuestionairData?.areyouCurrentlyUsingAnyContraceptives === "271400000")&&
                                InputText('contraceptivesIfYesPleaseGiveDetails', 'Contraceptives? If yes, please give details', 'textArea', QuestionairData?.contraceptivesIfYesPleaseGiveDetails, 'Please give details')                     
                                }
                            
                            
                            {InputSelect('Have you ever had an abnormal test?', 'haveYouEverhadanAbnormalTest', QuestionairData?.haveYouEverhadanAbnormalTest, [
                                { value: 271400000, label: 'Yes' },
                                { value: 271400001, label: 'No' }])}
                            {
                             (QuestionairData?.haveYouEverhadanAbnormalTest === 271400000 || QuestionairData?.haveYouEverhadanAbnormalTest === "271400000")&&
                               InputText('hadanAbnormalTestifYesPleaseGiveDetails', 'had an abnormal test? If yes, please give details', 'textArea', QuestionairData?.hadanAbnormalTestifYesPleaseGiveDetails, 'Please give details')
                            }

                            {InputSelect('Have you had any treatment to your cervix?', 'haveyouhadanytreatmenttoyourcervix', QuestionairData?.haveyouhadanytreatmenttoyourcervix, [
                                { value: 271400000, label: 'Yes' },
                                { value: 271400001, label: 'No' }])}

                            {
                             (QuestionairData?.haveyouhadanytreatmenttoyourcervix === 271400000 || QuestionairData?.haveyouhadanytreatmenttoyourcervix === "271400000")&&
                              
                               InputText('cervixTreatmentifYesPleaseGiveDetails', 'Cervix treatment? If yes, please give details', 'textArea', QuestionairData?.cervixTreatmentifYesPleaseGiveDetails, 'Please give details')
                            }


                            
                            {InputSelect('Have you ever had any gynaecological treatment?', 'haveYouEverHadAnyGynaecologicalTreatment', QuestionairData?.haveYouEverHadAnyGynaecologicalTreatment, [
                                { value: 271400000, label: 'Yes' },
                                { value: 271400001, label: 'No' }])}

{
                             (QuestionairData?.haveYouEverHadAnyGynaecologicalTreatment === 271400000 || QuestionairData?.haveYouEverHadAnyGynaecologicalTreatment === "271400000")&&
                              
                             InputText('diagnosedwithaGynaecologicalIfYesPleasegive', 'diagnosed with a gynaecological If yes please give', 'textArea', QuestionairData?.diagnosedwithaGynaecologicalIfYesPleasegive, 'Please give details')
                            }

                        
                            {InputText('howlonghaveyoubeentryingtogetpregnant', 'How long have you been trying to get pregnant', 'text', QuestionairData?.howlonghaveyoubeentryingtogetpregnant, 'How long have you been trying')}
                            {InputText('howLongAreYourCyclesDays', 'How long are your cycles (days)?', 'text', QuestionairData?.howLongAreYourCyclesDays, 'How long are your cycles')}
                            {InputText('lengthoftimewithyourcurrentpartner', 'Length of time with your current partner', 'text', QuestionairData?.lengthoftimewithyourcurrentpartner, 'Length of time')}
                            {InputSelect('Family History of FGM (vulval cutting/surgery)', 'familyhistoryoffgmvulvalcuttingsurgery', QuestionairData?.familyhistoryoffgmvulvalcuttingsurgery, [
                                { value: 271400000, label: 'Yes' },
                                { value: 271400001, label: 'No' }])}
                            {InputSelect('Regularity of cycle', 'regularityofcycle', QuestionairData?.regularityofcycle, [
                                { value: 0, label: 'Regular' },
                                { value: 1, label: 'Irregular' }])}
                            {InputSelect('Vaginal discharge (PV Discharge)?', 'vaginaldischargepvdischarge', QuestionairData?.vaginaldischargepvdischarge, [
                                { value: 271400000, label: 'Yes' },
                                { value: 271400001, label: 'No' }])}

{
                             (QuestionairData?.vaginaldischargepvdischarge === 271400000 || QuestionairData?.vaginaldischargepvdischarge === "271400000")&&
                              
                             InputText('pvdischargeifyespleasegivedetails', 'PV Discharge? If yes please give details', 'textArea', QuestionairData?.pvdischargeifyespleasegivedetails, 'Please give details')
                            }

                            {InputSelect('Are the couple related / consanguineous', 'arethecouplerelatedconsanguineous', QuestionairData?.arethecouplerelatedconsanguineous, [
                                { value: 271400000, label: 'Yes' },
                                { value: 271400001, label: 'No' }])}
                            {InputSelect('Is intercourse painful (Dyspareunia)?', 'isintercoursepainfuldyspareunia', QuestionairData?.isintercoursepainfuldyspareunia, [
                                { value: 271400000, label: 'Yes' },
                                { value: 271400001, label: 'No' }])}

{
                             (QuestionairData?.isintercoursepainfuldyspareunia === 271400000 || QuestionairData?.isintercoursepainfuldyspareunia === "271400000")&&
                              
                             InputText('dyspareuniaifyespleasegivedetails', 'Dyspareunia? If yes, please give details', 'textArea', QuestionairData?.dyspareuniaifyespleasegivedetails, 'Please give details')
                            }

                            {InputText('anymentalhealthissues', 'Any Mental health issues', 'textArea', QuestionairData?.anymentalhealthissues, 'Any Mental health issues')}
                            {InputSelect('Have you had a Large loop excision(LLETZ)?', 'haveyouhadalargeloopexcisionlletz', QuestionairData?.haveyouhadalargeloopexcisionlletz, [
                                { value: 271400000, label: 'Yes' },
                                { value: 271400001, label: 'No' }])}
                                {
                             (QuestionairData?.haveyouhadalargeloopexcisionlletz === 271400000 || QuestionairData?.haveyouhadalargeloopexcisionlletz === "271400000")&&
                              
                             InputText('largeloopexcisiongivedetails', 'LLETZ? If yes please give details', 'textArea', QuestionairData?.largeloopexcisiongivedetails, 'Please give details')
                            }
                            {InputText('originofparentspaternal', 'Origin of Parents (Father)', 'text', QuestionairData?.originofparentspaternal, 'Origin of Parents (Father)')}
                            {InputText('originofgrandparentspaternal', 'Origin of Grandparents, Paternal', 'text', QuestionairData?.originofgrandparentspaternal, 'Origin of Grandparents, Paternal')}
                            {InputText('originofparentsmaternal', 'Origin of Parents (Mother)', 'text', QuestionairData?.originofparentsmaternal, 'Origin of Parents (Mother)')}
                            {InputText('familyhistoryrelevantgeneticdisorders', 'Family History (relevant genetic disorders)', 'textArea', QuestionairData?.familyhistoryrelevantgeneticdisorders, 'Family History (relevant genetic disorders)')}
                            {InputText('originofgrandparentsmaternal', 'Origin of Grandparents, Maternal', 'text', QuestionairData?.originofgrandparentsmaternal, 'Origin of Grandparents, Maternal')}
                            {InputText('familyhistoryofdeepveinthrombosis', 'Family History of deep vein thrombosis', 'textArea', QuestionairData?.familyhistoryofdeepveinthrombosis, 'Family History of deep vein thrombosis')}
                        </div>
                    </div>
                </div>
                {!Submitted &&
                    <div className='container mb-3'>
                        <div className="card p-2 bg-white">
                            <div className="col">
                                <ReactSignatureCanvas
                                    id="SignaturePAD"
                                    penColor='green'
                                    canvasProps={{ className: 'sigCanvas border bg-white', width: 300, height: 200 }}
                                    ref={padRef}
                                />
                                <h6>Signature</h6>
                            </div>
                            <div className="col">
                                <button
                                    onClick={() => {
                                        dispatch(setQuestionairData({ ...QuestionairData, 'submittedDate': new Date() }))
                                        SubmitForm()
                                    }}
                                    className='btn btn-primary float-right'>
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                }
            </div>
            <br />
        </div >
    )
}
