import React, {  useEffect } from 'react'
import '../../Profile Component/stepHead.css';
import {  useSelector } from "react-redux";
import { getUser } from "../../Store/Reducers/UserReducer";
var steps =[];

export default function MedicalStepHead(props) {
    const uData = useSelector(getUser);
    useEffect(() => {
        
     let   UserData = JSON.parse(localStorage.getItem("UData"));
     let MainGender = localStorage.getItem("PatientGender");
      steps=[
        { key: 'firstStep', label: 'Medical Social History' },
        // {key: 'ThirdStep', label: 'Male Information' },
        // { key: 'SecondStep', label: `${UserData?.gender === 1 ? 'Male' : 'Female'} Information` }
        { key: 'SecondStep', label: `${MainGender == 1 ? 'Male' : 'Female'} Information` }
    ]
    }, [uData]);

    const goOnPage = (index) => {
        
      //  props.navigateTo(index)
}
    
    return (
        <>
            <div className="wrapper ">
                <div className="arrow-steps clearfix pb-1 pt-2 pe-3 m-2  ">
                    {
                        steps.map((step, i) => {
                            return (
                                <div key={i} onClick={() => goOnPage(i)}
                                 className={`step ${props.step > i ? 'current' : (props.step === i) ? 'done' : props.PP ? 'current' : ''}  pointer`}>
                                     <span>{step.label}</span> </div>
                            )
                        })
                    }
                </div>
            </div>
        </>
    )
}
