import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Card, Modal } from "react-bootstrap";
import Swal from "sweetalert2";
import moment from "moment";
import { useDispatch } from "react-redux";
import { setLoader } from "../../Store/Reducers/UserReducer"
import LoginFun from './../../Layout Component/Loginfun';
import './TreatmentWithUs.css';
import MedicalHistoryNav from './MedicalHistoryNav';
import $ from 'jquery';
import './Allergy.css';
import 'react-toastify/dist/ReactToastify.css';
import { Slide } from 'react-toastify';
import { toast } from 'react-toastify';

export default function Allergies(props) {
    const baseUrl = "https://browser.ihtsdotools.org/snowstorm/snomed-ct";
    const edition = "MAIN";
    const version = "2019-07-31";
    const dispatch = useDispatch();
    const url = process.env.REACT_APP_OXAR_API_URL;
    const [allergies, setallergies] = useState([]);
    const [updateallergies, setupdateallergies] = useState([]);
    const [show, setShow] = useState(false);
    const [saveBtn, setsaveBtn] = useState(false);
    const [updateBtn, setupdateBtn] = useState(false);
    const [data, setData] = useState();
    const handleClose = () => setShow(false);
    const [ChekSelectsnomed, setChekSelectsnomed] = useState(false);
    const regexEmail = /[<>&"]/;
    const handleShow = (dataall) => {
        setChekSelectsnomed(false);
        if (dataall !== undefined) {
            setData(dataall)
            var datapush = [];
            var data1 = {
                ID: dataall.code,
                Name: dataall.name,
            }
            datapush.push(data1);
            setquerydata(datapush);
            setupdateallergies(dataall);
            setSnomedCode(dataall.code);
            setSnomedName(dataall.name);
            setVerificationStatus(dataall.verificationStatus);
            setDescription(dataall.description);
        } else {
            setChekSelectsnomed(true)
            setSnomedCode("");
            setVerificationStatus("");
            setDescription("");
        }
        setShow(true);
    };
    const [verificationStatus, setVerificationStatus] = useState("");
    const [description, setDescription] = useState("");
    // const for snomed
    const [querydata, setquerydata] = useState([]);
    const [query, setQuery] = useState("");
    const [SnomedName, setSnomedName] = useState("");
    const [SnomedCode, setSnomedCode] = useState();
    const [queryName, setQueryName] = useState("");
    const logindata = LoginFun();

    const handleSubmit = async () => {
        if (SnomedName !== undefined && verificationStatus !== "") {
            let postdata = {
                name: SnomedName,
                code: SnomedCode,
                verificationStatus: verificationStatus,
                description: description,
                query1: queryName,
                codeText: SnomedName,
            }
            try {
                dispatch(setLoader(true));
                let MainUserId = localStorage.getItem("UserID");
                const res = await axios.post(`${url}api/Allergy/CreateAllergy?userId=${MainUserId}`, postdata);
                if (res.status === 200) {
                    toast.success('Allergy created successfully!', {
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        newestOnTop: false,
                        autoClose: 3000,
                        hideProgressBar: true,
                        transition: Slide,
                        className: 'toast-message'
                    })
                    getAllergies();
                    setShow(false);
                    dispatch(setLoader(false));
                }
                dispatch(setLoader(false));
            } catch (e) {
            }
        } else {
            toast.warn('Please fill mandatory fields', {
                autoClose: 5000,
                hideProgressBar: true,
                newestOnTop: false,
                closeOnClick: true,
                rtl: false,
                pauseOnFocusLoss: true,
                draggable: true,
                pauseOnHover: true,
                className: 'toast-warn',
            })
            return dispatch(setLoader(false));
        }
    }

    const updateAllergies = async (updatedvalue) => {
        
        var name = updatedvalue.target.name;
        var value = updatedvalue.target.value;
        setupdateallergies(values => ({ ...values, [name]: value, 'id': data?.id }));
    }

    useEffect(() => {
        dispatch(setLoader(true));
        setTimeout(() => {
            getAllergies();
        }, 1000);
        sessionCheck();
    }, []);

    const sessionCheck = async () => {
        var currentTime = ''
        currentTime = (Math.round(new Date().getTime() / 1000));
        var Expiretime = localStorage.getItem("ExpirretokenTime");
        if (Expiretime < currentTime) {
            sessionStorage.getItem('isLoggedIn', false)
            Swal.fire({
                title: 'Session Expired',
                text: "Your session has been expired! Please login again",
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: 'Okay',
            }).then(result => {
                logindata.logout();
            })
        }
    }

    const getAllergies = async () => {
        dispatch(setLoader(true));
        try {
            $('#AllergyTable').DataTable().destroy();
            let MainUserId = localStorage.getItem("UserID");
            await axios(`${url}api/Allergy/GetByContactId?id=${MainUserId}`).then((res) => {
                setallergies(res.data.content);

                setTimeout(function () {
                    $("#AllergyTable").DataTable();
                }, 200);

            });
            dispatch(setLoader(false));
        }
        catch {
            dispatch(setLoader(false));
        }
    };

    //UPdate allergies data API
    const updateAllergiesData = async (e) => {
        dispatch(setLoader(true));
        sessionCheck();
        e.preventDefault();
        let MainUserId = localStorage.getItem("UserID");
        try {
            if (updateallergies) {
                updateallergies.name = SnomedName;
                updateallergies.code = SnomedCode;
                updateallergies.verificationStatus = verificationStatus;
            }
            const res = await axios.put(`${url}api/Allergy/Update?userId=${MainUserId}`, updateallergies);
            if (res.status === 200) {
                toast.success('Allergy updated successfully!', {
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    newestOnTop: false,
                    autoClose: 5000,
                    hideProgressBar: true,
                    transition: Slide,
                    className: 'toast-message',
                }
                )
                setSnomedName("")
                setSnomedCode("");
                setVerificationStatus("");
                setDescription("")
                setQueryName("")
                setShow(false);
                getAllergies();
                dispatch(setLoader(false));
            }
            dispatch(setLoader(false));
        }
        catch (e) {
        }
    }
    const getData = (value) => {
        if (value === "Select") {
            setSnomedCode("");
            setSnomedName("");
        }
        var a = querydata.find(x => x['ID'] === value)
        setSnomedCode(a.ID);
        setSnomedName(a.Name);
    }
    // for finding query and getting condition to create condition
    const findByQuery = async () => {
        if (query === "") {
            return
        }
        let value = query;
        setQueryName(value)
        try {
            dispatch(setLoader(true));
            const response = await fetch(
                `${baseUrl}/${edition}/${version}/concepts?term=${encodeURIComponent(value)}&activeFilter=true&offset=0&limit=20`
            ).then((response) => response.json());
            var dataforsnomed = response["items"];
            var MainPath = [];
            dataforsnomed.forEach(element => {
                var data = {
                    ID: element.conceptId,
                    Name: element.pt.term
                }
                MainPath.push(data);
            });
            setquerydata(MainPath);
            dispatch(setLoader(false));
        } catch (ex) {
            dispatch(setLoader(false));
        }
    }

    const ValueofStatus = (value) => { setVerificationStatus(value); }

    const checkSpecial = (e) => {
        if (!/^[a-zA-Z0-9\s]*$/.test(e.key)) {
            e.preventDefault();
        }
    }
    return (
        <>
            <div style={{ display: "flex", flexDirection: "column" }}>
                {props.checkTest !== 1 ? <>
                    <MedicalHistoryNav />
                    <div className='col-md-12   '>
                        <Card>
                            <Card.Body>
                                <div className='d-flex '>
                                    <div className='col-md-12 mb-1   text-end'  >
                                        <Button className='btn btn-primary' onClick={() => handleShow(setsaveBtn(true), setupdateBtn(false))}>Add Allergy +</Button>
                                    </div>
                                </div>
                                <div className="table-responsive">
                                    <table class="table border-0" id="AllergyTable">
                                        <thead class="">
                                            <tr>
                                                <th> Name</th>
                                                <th>Description </th>
                                                <th>Created On</th>
                                                <th>Edit</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {allergies && allergies.map((listValue, index) => {
                                                return (
                                                    <tr>
                                                        <td>{listValue.name}</td>
                                                        <td>{listValue.description}</td>
                                                        <td>{moment(listValue.createdOn).format("DD-MMM-YYYY")}</td>
                                                        <td>
                                                            <i class="fa-solid fa-pencil" role="button" disabled={listValue.envelopeid === ""} onClick={() => handleShow(listValue, setupdateBtn(true), setsaveBtn(false))}></i>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </Card.Body>
                        </Card>
                    </div>
                </> :
                    <div className="col-md-12 text-left   ">
                        <div className='d-flex m-2 '>
                            <div className='col-md-6'>
                            </div>
                            <div className='col-md-6 d-grid gap-2 d-md-flex justify-content-md-end'  >
                                <Button className='btn btn-primary' onClick={() => handleShow(setsaveBtn(true), setupdateBtn(false))}>Add Allergy +</Button>
                            </div>
                        </div>
                        <div className="table-responsive">
                            <table class="table border-0" id="AllergyTable">
                                <thead class="">
                                    <tr>
                                        <th> Name</th>
                                        <th>Description </th>
                                        <th>Created On</th>
                                        <th>Edit</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {allergies && allergies.map((listValue, index) => {
                                        return (
                                            <tr>
                                                <td>{listValue.name}</td>
                                                <td>{listValue.description}</td>
                                                <td>{moment(listValue.createdOn).format("DD-MMM-YYYY")}</td>
                                                <td><i class="fa-solid fa-pencil" disabled={listValue.envelopeid === ""} onClick={() => handleShow(listValue, setupdateBtn(true), setsaveBtn(false))}></i>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                }
            </div>
            <Modal size="lg" show={show} onHide={handleClose} >
                <form>
                    <Modal.Header closeButton>
                        <Modal.Title>{saveBtn ?
                            <h6 >Add Allergy</h6>
                            :
                            <h6>Edit Allergy</h6>
                        }
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body >
                        <div className='ms-3 row'>
                            <label className='col-sm-3 col-form-label ' >Search Allergy : </label>
                            <div className="col-sm-8 d-flex">
                                <input className='form-control m-1   border-1' placeholder='Search Allergy' name="input1"
                                    onChange={(e) => setQuery(e.target.value)} onKeyDown={(e) => checkSpecial(e)} />
                                <i className="fa-solid fa-magnifying-glass m-2 fs-4" onClick={findByQuery}></i>
                            </div>
                            <label className='col-sm-3 col-form-label ' >Allergy Name <span className="required"></span> : </label>
                            <div className="col-sm-8">
                                <select className="form-select  border-1 m-1" id="floatingSelect" name="title" onChange={e => getData(e.target.value)}  >
                                    {ChekSelectsnomed && <option value={null}>Select</option>}
                                    {querydata?.map((data, i) => { return (<><option value={data.ID}> {data?.Name}</option></>) })}
                                </select>
                            </div>
                            <label className='col-sm-3 col-form-label ' >Code  : </label>
                            <div className="col-sm-8">
                                <input className='form-control m-1  border-1' name="code" value={SnomedCode} onKeyDown={(e) => checkSpecial(e)}
                                    onChange={(e) => { !regexEmail.test(e.target.value) && updateAllergies(e) }} />
                            </div>
                            <label className='col-sm-3 col-form-label ' >Verification Status <span className="required"></span>  : </label>
                            <div className="col-sm-8 d-flex m-2">
                                <div className="form-check me-2 p-0 ">
                                    <input type="radio" name="verificationStatus" defaultChecked={verificationStatus === 271400000} value={271400000} onChange={(e) => ValueofStatus(e.target.value)} /> <span className='me-3'>Unconfirmed</span>
                                    <input type="radio" name="verificationStatus" defaultChecked={verificationStatus === 271400001} value={271400001} onChange={(e) => ValueofStatus(e.target.value)} /> Confirmed
                                </div>
                            </div>
                            <label className='col-sm-3 col-form-label ' >Description   : </label>
                            <div className="col-sm-8">
                                <textarea className='form-control m-1  border-1' placeholder="Description" name="description" defaultValue={description} onKeyDown={(e) => checkSpecial(e)}
                                    onChange={(e) => { !regexEmail.test(e.target.value) && updateAllergies(e, setDescription(e.target.value)) }} />
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        {updateBtn && <Button className=" btn btn-primary" type="button" onClick={updateAllergiesData} >Update</Button>}
                        {saveBtn && <Button type="button" onClick={handleSubmit} className='btn btn-primary '  >Save</Button>}
                        <Button variant="secondary" className="btn btn-primary" onClick={handleClose}>Close</Button>
                    </Modal.Footer>
                </form>
            </Modal>
            {/* <ToastContainer  /> */}
        </>
    )
}
